import React from "react";
import Router from "./routes";
import "./index.css"
import './fonts/nasalization/nasalization-rg.ttf';
import './fonts/greycliff/GreycliffCF-Regular.ttf';
import './fonts/greycliff/GreycliffCF-Bold.ttf';
import './fonts/greycliff/GreycliffCF-DemiBold.ttf';
import './fonts/greycliff/GreycliffCF-Medium.ttf';
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "./components/scroll-to-top/ScrollToTop";
import ThemeProvider from './theme';

const App = () => {

  return (
    <BrowserRouter>
      <ThemeProvider>
        <ScrollToTop />
        <Router />
      </ThemeProvider>
    </BrowserRouter>
  );
};
export default App;
