import React, { useState } from 'react';
import { Card, Box,CardHeader, FormControlLabel, Switch } from '@mui/material';
import { ZoomOutMap, ZoomInMap } from '@mui/icons-material';
import LineChart from '../../components/chart/LineChart';
import BarChart from '../../components/chart/BarChart';
import { returnData, barChartOptions, lineGraphOptions, calculateSpecificSessionData } from '../../components/chart/chartData';

export default function SessionGraphCards ({ handleCardClick, index, subheader, analyzedSession, color, plotKey, isSelected }) {
  const [switchChecked, setSwitchChecked] = useState(false);
  const { metric_id, display_name, units } = plotKey;

  const handleSwitchChange = (event) => {
    setSwitchChecked(event.target.checked);
  };
  
  let max
  if(metric_id.includes("intensity_zone")){
    max = 10
  }

  const cardTitle = units ? `${display_name} (${units})` : `${display_name}`

  const seriesData = calculateSpecificSessionData(analyzedSession, switchChecked, metric_id);

  const area = analyzedSession.length > 10

  const xAxisLabel = switchChecked ? "Time (minutes)" : "Reps"
  const xAxisTooltipTitle = switchChecked ? "Time:" : "Rep: "

  const ChartComponent = area ? (
    <LineChart
      key={JSON.stringify(seriesData)}
      chartData={returnData(seriesData, display_name)}
      chartOptions={lineGraphOptions(color, seriesData, 4, false, xAxisLabel, xAxisTooltipTitle, max, units)}
    />
  ) : (
    <BarChart
      key={JSON.stringify(seriesData)}
      chartData={returnData(seriesData.map((session) => session.y), display_name)}
      chartOptions={barChartOptions(color, seriesData.map((e) => e.x), 3, xAxisLabel, xAxisTooltipTitle,  max, units)}
    />
  );

  return (
    <Card>
      <CardHeader
        title={cardTitle}
        subheader={subheader}
        action={
          area ? (
           <>
            <FormControlLabel
              control={
                <Switch 
                  checked={switchChecked} 
                  onChange={handleSwitchChange} 
                />
              }
            />
            <FormControlLabel
              control={
                isSelected ? (
                  <ZoomInMap sx={{color: 'grey.500'}}  onClick={() => handleCardClick(index)} />
                ) : (
                  <ZoomOutMap sx={{color: 'grey.500'}}  onClick={() => handleCardClick(index)} />
                )
              }
            />
           </>
          ) : (
            isSelected ? (
              <ZoomInMap sx={{color: 'grey.500'}}  onClick={() => handleCardClick(index)} />
            ) : (
              <ZoomOutMap sx={{color: 'grey.500'}}  onClick={() => handleCardClick(index)} />
            )
          )
        }
      />
        <Box sx={{ height: "80%", pr: 1, pb: 1 }}>
          {ChartComponent}
        </Box>
    </Card>
  );
};