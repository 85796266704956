
const PRIMARY = {
  light: "#FFCD71",
  main: "#FFC04D",
  dark: "#E5AD45",
};

const SUCCESS = {
  light: "#4BAF50",
  main: "#2F7C32",
  dark: "#1B5E20",
};

const ERROR = {
  light: "#EF5450",
  main: "#D42F30",
  dark: "#C62829",
};

const INFO = {
  light: "#03A9F4",
  main: "#0288D1",
  dark: "#01579B",
}

const WARNING = {
  light: "#FF9800",
  main: "#EC6C01",
  dark: "#E65100",
}

const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
};

const palette = {
  common: { black: '#000', white: '#fff' },
  primary: PRIMARY,
  info: INFO,
  success: SUCCESS,
  warning: WARNING,
  error: ERROR,
  text: {
    primary: "#212021",
    secondary: "#666666",
    disabled: "#9E9E9E",
  },
  grey: GREY,
  background: {
    paper: '#fff',
    default: "#FFFFFF",
  },
};

export default palette;
