import React from "react";
import {
  Typography,
  Stack,
  Grid,
  MenuItem,
  TextField,
  Button,
  InputLabel,
  Select,
} from "@mui/material";
import {
  months,
  years,
  days,
  heights,
  weights,
  cms,
  kgs,
  countries,
  isWithinCharacterLimit,
} from "../../../utils/authData";
import {
  accountTextFieldStyleTwo,
  whiteButtonStyle,
} from "../../../utils/styles";
import { LoadingButton } from "@mui/lab";

const AccountInputs = (props) => {
  const {
    formFields,
    disabled,
    setMessage,
    dateOfBirthError,
    handleHeightMetricChange,
    handleWeightMetricChange,
    message,
    loading,
    setDisabled,
    handleSubmit,
    handleFormValueChange,
  } = props;
  const {
    firstName,
    lastName,
    country,
    gender,
    weight,
    weightMetric,
    heightMetric,
    height,
    dominantArm,
    dominantLeg,
    day,
    year,
    month,
  } = formFields;
  return (
    <Grid item xs={12} sm={9} md={9}>
      <Grid container rowSpacing={3} columnSpacing={2}>
        <Grid item xs={6} sm={3}>
          <InputLabel id="first" sx={{ fontSize: 10 }}>
            First Name
          </InputLabel>
          <TextField
            id="first"
            labelId="first"
            sx={{ ...accountTextFieldStyleTwo, width: "100%" }}
            disabled={disabled}
            value={firstName}
            variant="standard"
            onChange={(event) => {
              const value = event.target.value;
              if (isWithinCharacterLimit(value)) {
                handleFormValueChange("firstName", value);
                setMessage("");
              } else {
                setMessage("You have reached the maximum character length.");
              }
            }}
          />
        </Grid>

        <Grid item xs={6} sm={3}>
          <InputLabel id="last" sx={{ fontSize: 10 }}>
            Last Name
          </InputLabel>
          <TextField
            id="standard-basic"
            sx={{ ...accountTextFieldStyleTwo, width: "100%" }}
            disabled={disabled}
            value={lastName}
            labelId="last"
            variant="standard"
            onChange={(event) => {
              const value = event.target.value;
              if (isWithinCharacterLimit(value)) {
                handleFormValueChange("lastName", value);
                setMessage("");
              } else {
                setMessage("You have reached the maximum character length.");
              }
            }}
          />
        </Grid>

        <Grid item xs={4} sm={2} md={2}>
          <InputLabel id="mm" sx={{ fontSize: 10 }}>
            MM
          </InputLabel>
          <Select
            value={month}
            disabled={disabled}
            variant="standard"
            fullWidth
            error={dateOfBirthError}
            labelId="mm"
            label="MM"
            onChange={(e) => {
              handleFormValueChange("month", e.target.value);
            }}
          >
            {months.map((month, index) => (
              <MenuItem key={index} value={month}>
                {month}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item xs={4} sm={2} md={2}>
          <InputLabel id="dd" sx={{ fontSize: 10 }}>
            DD
          </InputLabel>
          <Select
            value={day}
            disabled={disabled}
            error={dateOfBirthError}
            fullWidth
            label="DD"
            labelId="dd"
            variant="standard"
            onChange={(e) => {
              handleFormValueChange("day", e.target.value);
            }}
          >
            {days.map((day, index) => (
              <MenuItem key={index} value={day}>
                {day}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item xs={4} sm={2} md={2}>
          <InputLabel id="yy" sx={{ fontSize: 10 }}>
            YY
          </InputLabel>
          <Select
            value={year}
            disabled={disabled}
            error={dateOfBirthError}
            fullWidth
            labelId="yy"
            label="YY"
            variant="standard"
            onChange={(e) => {
              handleFormValueChange("year", e.target.value);
            }}
          >
            {years.map((year, index) => (
              <MenuItem key={index} value={year} justifyContent="center">
                {year}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={6}>
          <InputLabel id="gender" sx={{ fontSize: 10 }}>
            Gender
          </InputLabel>
          <Select
            value={gender}
            disabled={disabled}
            fullWidth
            labelId="gender"
            variant="standard"
            onChange={(e) => {
              handleFormValueChange("gender", e.target.value);
            }}
          >
            <MenuItem disableRipple key={0} value={"female"}>
              Female
            </MenuItem>
            <MenuItem disableRipple key={1} value={"male"}>
              Male
            </MenuItem>
            <MenuItem disableRipple key={2} value={"Prefer Not to Say"}>
              Prefer Not To Say
            </MenuItem>
          </Select>
        </Grid>

        <Grid item xs={6}>
          <InputLabel id="country" sx={{ fontSize: 10 }}>
            Country
          </InputLabel>
          <Select
            value={country}
            disabled={disabled}
            fullWidth
            error={country === null ? true : false}
            labelId="country"
            variant="standard"
            onChange={(e) => {
              handleFormValueChange("country", e.target.value);
            }}
          >
            {countries.map((country, index) => (
              <MenuItem
                key={index}
                value={country}
                disabled={country === "---------------------------------"}
              >
                {country}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item xs={6}>
          <InputLabel id="height" sx={{ fontSize: 10 }}>
            Height
          </InputLabel>
          <Select
            value={height}
            disabled={disabled}
            fullWidth
            labelId="height"
            label="Height"
            variant="standard"
            onChange={(e) => {
              handleFormValueChange("height", e.target.value);
            }}
          >
            {heightMetric === "in"
              ? heights.map((height, index) => {
                  return (
                    <MenuItem disableRipple key={index} value={height}>
                      {" "}
                      {height}{" "}
                    </MenuItem>
                  );
                })
              : cms.map((height, index) => {
                  return (
                    <MenuItem disableRipple key={index} value={height}>
                      {" "}
                      {height}{" "}
                    </MenuItem>
                  );
                })}
          </Select>
        </Grid>

        <Grid item xs={6}>
          <InputLabel id="heightunit" sx={{ fontSize: 10 }}>
            Unit
          </InputLabel>
          <Select
            value={heightMetric}
            disabled={disabled}
            fullWidth
            labelId="heightunit"
            variant="standard"
            onChange={(e) => handleHeightMetricChange(e.target.value)}
          >
            <MenuItem disableRipple key={0} value={"in"}>
              ft
            </MenuItem>
            <MenuItem disableRipple key={1} value={"cm"}>
              cm
            </MenuItem>
          </Select>
        </Grid>

        <Grid item xs={6}>
          <InputLabel id="weight" sx={{ fontSize: 10 }}>
            Weight
          </InputLabel>
          <Select
            value={weight}
            disabled={disabled}
            fullWidth
            labelId="weight"
            label="Weight"
            variant="standard"
            onChange={(e) => {
              handleFormValueChange("weight", e.target.value);
            }}
          >
            {weightMetric === "lb"
              ? weights.map((weight, index) => {
                  return (
                    <MenuItem disableRipple key={index} value={weight}>
                      {" "}
                      {weight}{" "}
                    </MenuItem>
                  );
                })
              : kgs.map((weight, index) => {
                  return (
                    <MenuItem disableRipple key={index} value={weight}>
                      {" "}
                      {weight}{" "}
                    </MenuItem>
                  );
                })}
          </Select>
        </Grid>

        <Grid item xs={6}>
          <InputLabel id="weightunit" sx={{ fontSize: 10 }}>
            Unit
          </InputLabel>
          <Select
            value={weightMetric}
            disabled={disabled}
            fullWidth
            labelId="weightunit"
            variant="standard"
            onChange={(e) => {
              handleWeightMetricChange(e.target.value);
            }}
          >
            <MenuItem disableRipple key={0} value={"lb"}>
              lb
            </MenuItem>
            <MenuItem disableRipple key={1} value={"kg"}>
              kg
            </MenuItem>
          </Select>
        </Grid>

        <Grid item xs={6}>
          <InputLabel id="arm" sx={{ fontSize: 10 }}>
            Arm Dominance
          </InputLabel>
          <Select
            value={dominantArm.toLowerCase()}
            disabled={disabled}
            fullWidth
            labelId="arm"
            variant="standard"
            onChange={(e) => {
              handleFormValueChange("dominantArm", e.target.value);
            }}
          >
            <MenuItem disableRipple key={0} value={"right"}>
              Right
            </MenuItem>
            <MenuItem disableRipple key={1} value={"left"}>
              Left
            </MenuItem>
          </Select>
        </Grid>

        <Grid item xs={6}>
          <InputLabel id="leg" sx={{ fontSize: 10 }}>
            Leg Dominance
          </InputLabel>
          <Select
            value={dominantLeg.toLowerCase()}
            disabled={disabled}
            fullWidth
            label="Dominant Leg"
            variant="standard"
            onChange={(e) => {
              handleFormValueChange("dominantLeg", e.target.value);
            }}
          >
            <MenuItem disableRipple key={0} value={"right"}>
              Right
            </MenuItem>
            <MenuItem disableRipple key={1} value={"left"}>
              Left
            </MenuItem>
          </Select>
        </Grid>

        <Grid item xs={12}>
          {!disabled && (
            <>
              <Typography variant="body1" color={"error"}>
                {message}
              </Typography>

              <Stack direction="row" gap={2} mt={3}>
                <LoadingButton
                  size="medium"
                  loading={loading}
                  onClick={() => handleSubmit()}
                  disableRipple
                  variant="contained"
                >
                  Save
                </LoadingButton>

                <Button
                  variant="contained"
                  size="medium"
                  onClick={() => setDisabled(true)}
                  disableRipple
                  sx={{ ...whiteButtonStyle }}
                >
                  Cancel
                </Button>
              </Stack>
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AccountInputs;
