import React, { useState, useEffect } from "react";
// @mui
import {
  Toolbar,
  Box,
  Stack,
  Typography,
  Tooltip,
  TextField,
  Menu,
  Tabs,
  Tab,
} from "@mui/material/";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
// @mui-icons
import {
  FileDownloadOutlined,
  FileDownloadOffOutlined,
} from "@mui/icons-material";
// Utils
import { createCoachData, createAthleteData } from "../../utils/tableFunctions";
// ----------------------------------------------------------------------
import DownloadSelect from "../selects/DownloadSelect";

export default function GenericToolBar({
  numSelected,
  selected,
  orgId,
  userId,
  allTestSession,
  someTestSession,
  setTabValue,
  tabValue,
  allValidSession,
  setCount,
  sessions,
  setSessionCards,
  admin,
  activities,
  handleButtonClick,
  setTotalSessions,
  page,
  setPage,
  loading,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [previousSearchTerm, setPreviousSearchTerm] = useState("");
  const open = Boolean(anchorEl);

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleSearchTermChange = (event, data) => {
    if (page !== 0) setPage(0);
    setPreviousSearchTerm(searchTerm);
    setSearchTerm(event);

    if (previousSearchTerm && event === "") {
      setCount((prevCount) => prevCount + 1);
    }

    const filteredSessions = data?.filter((session) => {
      return (
        session.activity_list[0].activity.display_name
          .toLowerCase()
          .includes(event.toLowerCase()) ||
        session.session_number.toString().includes(event) ||
        session.session_metrics.reps.toString().includes(event) ||
        session.session_metrics.duration.toString().includes(event) ||
        session.date.includes(event) ||
        session.name.toLowerCase().includes(event.toLowerCase())
      );
    });

    const newSessions = filteredSessions?.map((session, index) =>
      admin
        ? createCoachData(session, activities, index, handleButtonClick)
        : createAthleteData(session, activities, index, handleButtonClick)
    );

    setSessionCards(newSessions);
    setTotalSessions(newSessions?.length);
  };

  async function downloadFile(event) {
    handleClick(event);
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    if (searchTerm && !loading) handleSearchTermChange(searchTerm, sessions);
  }, [sessions, loading]);

  return (
    <>
      <Toolbar
        disableGutters
        justifyContent="space-between"
        sx={{ mb: 2, mt: -2 }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            // mb: 2.5,
          }}
        >
          <Tabs
            value={tabValue}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab
              value={1}
              label={
                <span
                  style={{
                    fontSize: "0.9rem",
                    color: tabValue === 1 ? "#FFA500" : "black",
                  }}
                >
                  SESSIONS
                </span>
              }
            />
            <Tab
              value={0}
              label={
                <span
                  style={{
                    fontSize: "0.9rem",
                    color: tabValue === 0 ? "#FFA500" : "black",
                  }}
                >
                  TEST SESSIONS
                </span>
              }
            />
          </Tabs>
        </Box>
        <Stack direction="row">
          <TextField
            type="text"
            sx={{ width: "15rem" }}
            label="Search"
            mb={1}
            onChange={(event) => {
              if (loading) return;
              setSearchTerm(event.target.value);
              handleSearchTermChange(event.target.value, sessions);
            }}
            value={searchTerm}
          />
        </Stack>
      </Toolbar>
      {numSelected > 0 ? (
        <Stack sx={{ width: "100%" }}>
          <Box
            bgcolor={allValidSession ? "primary.light" : "#bdbdbd"}
            mt={1}
            sx={{
              display: "flex",
              flexDirection: "row",
              height: "3.2rem",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography pl={1} color="inherit" variant="body1">
              {numSelected} items selected
            </Typography>

            <span style={!allValidSession ? { cursor: "not-allowed" } : {}}>
              <Tooltip
                title={
                  allValidSession
                    ? "Download Data"
                    : "Data can't be downloaded for an invalid session"
                }
                placement="top"
              >
                <Stack direction="row">
                  {allValidSession ? (
                    <FileDownloadOutlined onClick={handleClick} />
                  ) : (
                    <FileDownloadOffOutlined />
                  )}
                  <Typography
                    sx={{ pr: 1, pl: 0.5, cursor: "pointer" }}
                    onClick={allValidSession ? downloadFile : null}
                  >
                    <span
                      style={!allValidSession ? { cursor: "not-allowed" } : {}}
                    >
                      {numSelected > 1 ? (
                        <Typography>Download Sessions</Typography>
                      ) : (
                        <Typography>Download Session</Typography>
                      )}
                    </span>
                  </Typography>
                </Stack>
              </Tooltip>
            </span>
          </Box>
        </Stack>
      ) : null}
      <Menu id="long-menu" open={open} anchorEl={anchorEl}>
        <ClickAwayListener onClickAway={handleClose}>
          <div role="presentation">
            <DownloadSelect
              onClose={handleClose}
              ids={selected.map((item) => item.id)?.join(",")}
              userId={userId}
              orgId={orgId}
              allTestSession={allTestSession}
              someTestSession={someTestSession}
              allValidSession={allValidSession}
            />
          </div>
        </ClickAwayListener>
      </Menu>
    </>
  );
}
