import Card from './Card';
import Tooltip from './Tooltip';
import Switch from './Switch';
import ToggleButtonGroup from './TogggleButtonGroup';

export default function ComponentsOverrides(theme) {
    return Object.assign(
      Card(theme),
      Tooltip(theme),
      Switch(theme),
      ToggleButtonGroup(theme),
    );
  }
  