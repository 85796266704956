import React from 'react';
import {  Menu, MenuItem } from '@mui/material/';

const GenericMenu = ({ options, anchorEl, open, onClose, onClick  }) => {

    return (
        <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={onClose}
        >
        {options.map((option) => ( 
        <MenuItem onClick={onClick} key={option} selected={option === 'Pyxis'} >
            {option}
        </MenuItem>
        ))}
        </Menu>
    )
}
export default GenericMenu;