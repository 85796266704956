import api from "../utils/apiCall";

// Fetch User Metadata
export const fetchUserMetaData = async (
  userId,
  datadogMsg,
  params,
  admin = false,
  funcName = "fetchUserMetaData"
) => {
  return await api({
    path: `/user/usermetadata/${userId}`,
    params,
    datadogMsg,
    admin,
    funcName,
  }).then((response) => response);
};

// Fetch User Org Metadata
export const fetchUserOrgMetadata = async (
  userId,
  orgId,
  datadogMsg,
  params,
  admin = false,
  funcName = "fetchUserOrgMetadata"
) => {
  return await api({
    path: `/user/userorgmetadata/${userId}/${orgId}`,
    params,
    datadogMsg,
    admin,
    funcName,
  }).then((response) => response);
};

// Update User's Admin Settings
export const updateUserAdminSettings = async (
  userAdminSettings,
  datadogMsg,
  params,
  admin = false,
  funcName = "updateUserAdminSettings"
) => {
  return await api({
    path: `/user/updateuseradminsettings`,
    method: "PATCH",
    body: userAdminSettings,
    params,
    datadogMsg,
    admin,
    funcName,
  }).then((response) => response);
};

//Invite Athlete
export const inviteAthleteToOrg = async (
  orgId,
  email,
  datadogMsg,
  params,
  admin = false,
  funcName = "inviteAthleteToOrg"
) => {
  return await api({
    path: `/user/userinvite/${orgId}/${email}`,
    params,
    datadogMsg,
    admin,
    funcName,
  }).then((response) => response);
};

//flag-unflag user sessions
export const flagUnflagSession = async (
  datadogMsg,
  params,
  admin = false,
  funcName = "flagUnflagSession"
) => {
  return await api({
    path: `/user/usersessionflag/${params.athleteId}/${params.orgId}/${
      params.flagged ? "0" : "1"
    }/${params.sessionId}`,
    method: "POST",
    params,
    datadogMsg,
    admin,
    funcName,
  }).then((response) => response);
};
