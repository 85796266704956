import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// @mui
import {
  Container,
  Typography,
  Stack,
  Avatar,
  Grid,
  Box,
  Button,
  Snackbar,
  IconButton,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
// @Mui-icons
import {
  AccountCircleOutlined,
  LogoutOutlined,
  Close,
} from "@mui/icons-material";
// APIS
import { updateUserMetadata } from "../../../api/authRoutes";
import { fetchUserMetaData } from "../../../api/userRoutes";
// Utils
import { whiteButtonStyle } from "../../../utils/styles";
import {
  toISOLocal,
  feetConversion,
  validateEmail,
} from "../../../utils/utilityFunctions";
// import { withTimingAndLogging } from '../../utils/logFunctions/logs';
import {
  convertWeight,
  convertHeight,
  isStringLongEnough,
} from "../../../utils/authData";
// ----------------------------------------------------------------------
import AccountInputs from "./AccountInputs";

export default function AccountPage({ userId, setName }) {
  const [loading, setLoading] = useState(false);
  const [formFields, setFormFields] = useState({
    firstName: "",
    lastName: "",
    country: "",
    gender: "",
    emailValue: "",
    weight: "",
    weightMetric: "",
    heightMetric: "",
    height: "",
    dominantArm: "",
    dominantLeg: "",
    day: "",
    year: "",
    month: "",
  });
  const [disabled, setDisabled] = useState(true);
  const [message, setMessage] = useState("");
  const [days, setDays] = useState([]);
  const [bgColor, setBgColor] = useState("success.light");
  const [severity, setSeverity] = useState("");
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [dateOfBirthError, setDateOfBirthError] = useState(false);
  const [state, setState] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal, open } = state;

  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleClick = (newState) => {
    setState({ open: true, ...newState });
  };

  const navigate = useNavigate();
  const date = toISOLocal(new Date());

  useEffect(() => {
    const fetchData = async () => {
      const fetchFunction = () => fetchUserMetaData(userId);
      const params = { user_id: userId };
      const funcName = "fetchUserMetaData";

      // const response = await withTimingAndLogging(fetchFunction, 'User Metadata for account page has been fetched.', false, funcName, params);

      const response = await fetchUserMetaData(
        userId,
        "User Metadata for account page has been fetched.",
        params
      );
      const data = response.data;
      const userMetadata = data.data.user_metadata || {};
      const formFields = {};
      formFields["country"] = userMetadata.country || null;
      formFields["gender"] = userMetadata.gender || "Prefer Not to Say";

      const [year, month, day] = userMetadata.dob
        ? userMetadata.dob.split("-")
        : ["", "", ""];
      setDateOfBirthError(!userMetadata.dob);
      formFields["year"] = year;
      formFields["month"] = month;
      formFields["day"] = day;

      formFields["emailValue"] = data.data.email;

      const firstName =
        data.data.first_name ||
        (data.data.name ? data.data.name.split(" ")[0] : "");
      const lastName =
        data.data.last_name ||
        (data.data.name ? data.data.name.split(" ")[1] : "");

      formFields["firstName"] = firstName;
      formFields["lastName"] = lastName;

      if (userMetadata.height) {
        const { unit, value } = userMetadata.height;
        const heightMetric = unit;
        const heightValue =
          unit === "in"
            ? `${Math.trunc(value / 12)} feet ${value % 12} inches`
            : value;

        formFields["heightMetric"] = heightMetric;
        formFields["height"] = heightValue;
      }

      if (userMetadata.weight) {
        formFields["weight"] = userMetadata.weight.value;
        formFields["weightMetric"] = userMetadata.weight.unit;
      }

      formFields["dominantArm"] = userMetadata.dominant_arm;
      formFields["dominantLeg"] = userMetadata.dominant_leg;
      setFormFields(formFields);
    };

    fetchData();
  }, []);

  useEffect(() => {
    const daysInMonth = new Date(
      formFields.year,
      formFields.month,
      0
    ).getDate();
    const newDays = Array.from({ length: daysInMonth }, (_, index) => {
      const dayNumber = index + 1;
      return dayNumber < 10 ? `0${dayNumber}` : dayNumber.toString();
    });
    setDays(newDays);
    if (!newDays.includes(formFields.day)) {
      handleFormValueChange("day", "");
    }
  }, [formFields.month, formFields.year]);

  const handleFormValueChange = (fieldName, val) => {
    const formvalues = JSON.parse(JSON.stringify(formFields));
    formvalues[fieldName] = val;
    setFormFields(JSON.parse(JSON.stringify(formvalues)));
  };

  const handleUpdateSuccess = () => {
    setLoading(false);
    setDisabled(true);

    const savedState = localStorage.getItem("myState");
    const parsedState = JSON.parse(savedState);
    parsedState.name = `${formFields.firstName} ${formFields.lastName}`;
    setName(`${formFields.firstName} ${formFields.lastName}`);
    localStorage.setItem("myState", JSON.stringify(parsedState));

    setSnackbarMessage("Success! Your account information has been updated.");
    setSeverity("success");
    handleClick({
      vertical: "bottom",
      horizontal: "center",
    });
    setMessage("");
  };

  const handleUpdateError = () => {
    setLoading(false);
    setSeverity("error");
    setBgColor("error.light");
    setSnackbarMessage(
      "Error! Your account information did not update. Please try again."
    );
    handleClick({
      vertical: "bottom",
      horizontal: "center",
    });
    setDisabled(true);
  };

  const handleSubmit = async () => {
    setLoading(true);

    const trimmedFirstName = formFields.firstName.trim();
    const trimmedLastName = formFields.lastName.trim();

    const allSameTruthyOrFalsy =
      (formFields.day && formFields.month && formFields.year) ||
      formFields.day.concat(formFields.year).concat(formFields.month) === "";
    const firstNameLength = isStringLongEnough(trimmedFirstName);
    const lastNameLength = isStringLongEnough(trimmedLastName);

    if (
      validateEmail(formFields.emailValue) &&
      allSameTruthyOrFalsy &&
      firstNameLength &&
      lastNameLength
    ) {
      const newHeight =
        formFields.heightMetric === "in" && formFields.height !== "0"
          ? feetConversion(formFields.height)
          : formFields.height;
      const dateOfBirth =
        formFields.month && formFields.day && formFields.year
          ? `${formFields.year}-${formFields.month}-${formFields.day}`
          : null;
      setDateOfBirthError(false);
      const newGender =
        formFields.gender === "Prefer Not to Say" ? null : formFields.gender;

      const userMetaData = {
        userId: userId,
        firstName: trimmedFirstName,
        lastName: trimmedLastName,
        emailValue: formFields.emailValue,
        date: date,
        dateOfBirth: dateOfBirth,
        height: newHeight,
        heightMetric: formFields.heightMetric,
        weight: formFields.weight,
        weightMetric: formFields.weightMetric,
        domarm: formFields.dominantArm,
        domleg: formFields.dominantLeg,
        country: formFields.country,
        gender: newGender,
      };

      const fetchFunction = () => updateUserMetadata(userMetaData);
      const funcName = "updateUserMetaData";
      const params = { user_metadata: userMetaData };
      // const response = await withTimingAndLogging(fetchFunction,'User Account Metadata has been updated.', false, funcName, params);

      const response = await updateUserMetadata(
        userMetaData,
        "User Account Metadata has been updated.",
        params
      );
      response.statusCode === 200 ? handleUpdateSuccess() : handleUpdateError();
    } else {
      setLoading(false);
      if (!formFields.day || !formFields.month || !formFields.year) {
        setMessage("Please complete Date Of Birth!");
        setDateOfBirthError(true);
      }
      if (!validateEmail(formFields.emailValue)) {
        setMessage("Please enter a valid email!");
      }
      if (trimmedFirstName.length < 2 || trimmedLastName.length < 2) {
        setMessage(
          "Please enter a minimum of two characters for both first and last name!"
        );
      }
    }
  };

  const handleClose = (event, reason) => {
    setState({ ...state, open: false });
  };
  const handleHeightMetricChange = (newMetric) => {
    const convertedHeight = convertHeight(newMetric, formFields.height);
    handleFormValueChange("heigth", convertedHeight);
    handleFormValueChange("heightMetric", newMetric);
  };
  const handleWeightMetricChange = (newMetric) => {
    const convertedWeight = convertWeight(newMetric, formFields.weight);
    handleFormValueChange("weight", convertedWeight);
    handleFormValueChange("weightMetric", newMetric);
  };

  const logout = () => {
    localStorage.clear();
    navigate("/");
  };

  return (
    <Container maxWidth="xl" ml={0}>
      <Box ml={4} mb={3} mr={1}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={1}
        >
          <Stack direction="row" spacing={2}>
            <AccountCircleOutlined />
            <Typography>My Account</Typography>
          </Stack>

          <Stack
            direction="row"
            onClick={logout}
            spacing={1}
            sx={{ cursor: "pointer" }}
          >
            <LogoutOutlined style={{ color: "rgba(212, 47, 48, 1)" }} />
            <Typography color="rgba(212, 47, 48, 1)" textTransform="uppercase">
              Logout
            </Typography>
          </Stack>
        </Stack>

        <Typography variant="h4" mt={2}>
          Athlete
        </Typography>

        <Grid container mt={3} spacing={2}>
          <Grid item xs={12} sm={3} md={2}>
            <Stack
              gap={2}
              alignItems="left"
              sx={{ alignItems: { xs: "center", sm: "left" } }}
            >
              <Avatar sx={{ width: 130, height: 130 }} alt={`User`} />

              <Button
                variant="contained"
                mt={2}
                disableRipple
                onClick={() => setDisabled(false)}
                sx={{ ...whiteButtonStyle, width: 130 }}
              >
                Edit Profile
              </Button>
            </Stack>
          </Grid>

          <AccountInputs
            disabled={disabled}
            formFields={formFields}
            handleFormValueChange={(field, val) =>
              handleFormValueChange(field, val)
            }
            setMessage={(val) => setMessage(val)}
            dateOfBirthError={dateOfBirthError}
            handleHeightMetricChange={(val) => handleHeightMetricChange(val)}
            handleWeightMetricChange={(val) => handleWeightMetricChange(val)}
            message={message}
            loading={loading}
            setDisabled={(val) => setDisabled(val)}
            handleSubmit={() => handleSubmit()}
          />
        </Grid>
      </Box>

      <Snackbar
        open={open}
        anchorOrigin={{ vertical, horizontal }}
        autoHideDuration={6000}
        key={vertical + horizontal}
        message={snackbarMessage}
      >
        <Alert
          severity={severity}
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleClose}
            >
              <Close fontSize="small" />
            </IconButton>
          }
          sx={{ width: "100%", bgcolor: bgColor }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
}
