import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom';
import PasswordChecklist from 'react-password-checklist';
// @mui
import { 
  Typography, 
  Container,
  Stack, 
  InputAdornment, 
  Breadcrumbs, 
  MenuItem, 
  InputLabel, 
  FormControl,
  IconButton,
  Button,
  Grid,
  TextField,
  Select,
  FormHelperText
} from '@mui/material';
// @mui-icons
import { VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material';
// APIS
import { isEmailAvailable } from '../../api/authRoutes';
// Utils
import { months, years, countries, validateEmail, validatePassword, validatePasswordMatch, isFormValid, isStringLongEnough, isWithinCharacterLimit } from '../../utils/authData';
// ----------------------------------------------------------------------

const breadcrumbs = [
    <Typography variant='body1' sx={{color: "rgba(33, 32, 33, 1)", fontSize: "14px"}}> Step 1 </Typography>,
    <Typography variant='body1' sx={{color: "rgba(158, 158, 158, 1)", fontSize: "14px"}}> Step 2 </Typography>,
];

export default function CreateAccounts() {
  const navigate = useNavigate()
  const location = useLocation();

  const letters_spaces = /^[A-Za-z\s.-]+$/;
  const [token, setToken] = useState("")
  const [message, setMessage] = useState("");
  const [disabled, setDisabled] = useState(false)
  const [formData, setFormData] = useState({
    first: '',
    last: '',
    country: "",
    email: "",
    password: "",
    secondPassword: "",
    month: "",
    day: "",
    year: "",
  });
  const [days, setDays] = useState([])
  const [focusOne, setFocusOne] = useState(false);
  const [focusTwo, setFocusTwo] = useState(false);
  const [showPasswordOne, setShowPasswordOne] = useState(false);
  const [showPasswordTwo, setShowPasswordTwo] = useState(false);

  useEffect(()=> {

    if (location.state) {
      setFormData(location.state.formData)
      setToken(location.state.token)
    } else {
      const queryString = window.location.search;
      const startIndex = 8; 
      const nextQueryParamIndex = queryString.indexOf('?', startIndex); 
      let token = '';
      let email = '';
      
      if (nextQueryParamIndex !== -1) {
        token = queryString.slice(startIndex, nextQueryParamIndex);
        const emailStartIndex = queryString.indexOf('email=', nextQueryParamIndex); 
        if (emailStartIndex !== -1) {
          const emailEndIndex = queryString.indexOf('&', emailStartIndex); 
          if (emailEndIndex !== -1) {
            email = queryString.slice(emailStartIndex + 6, emailEndIndex);
          } else {
            email = queryString.slice(emailStartIndex + 6);
          }
        }
      } else {
        token = queryString.slice(startIndex);
      }
      setToken(token)
      if(email) {
        setFormData({
          ...formData,
          email: email
        });
        setDisabled(true)
      }
    }
  },[])

  const { first, last, email, year, day, month, password, secondPassword, country} = formData

  useEffect(() => {
    const daysInMonth = new Date(year, month, 0).getDate();
    const newDays = Array.from({ length: daysInMonth }, (_, index) => {
        const dayNumber = index + 1;
        return dayNumber < 10 ? `0${dayNumber}` : dayNumber.toString();
    });
    setDays(newDays);
    if (!newDays.includes(day)) {
      setFormData(prevState => ({
          ...prevState,
          day: "",
      }));
  }
}, [month, year]);


  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if(isWithinCharacterLimit(value)) {
      setFormData((prevData)=> ({ ...prevData, [name]: value }));
      setMessage("")
    } else {
      setMessage("Character limit has been reached")
    }
  }

  const checkEmailAvailability = async () => {
    const response = await isEmailAvailable(email)
    const { statusCode } = response
    return statusCode === 200;
  }
  
  const handleSubmit = async (e) => {
    e.preventDefault();

    const trimmedFirstName = first.trim()
    const trimmedLastName = last.trim()

    const isEmailValid = validateEmail(email);
    const isPasswordValid = validatePassword(password)
    const isPasswordMatchValid = validatePasswordMatch(password, secondPassword);
    const isFormFieldsFilled = isFormValid(formData);

    const firstNameLength = isStringLongEnough(trimmedFirstName)
    const lastNameLength = isStringLongEnough(trimmedLastName)

    if (!email) {
      setMessage('Please enter an email address.');
    } else if (isEmailValid && isPasswordMatchValid && isFormFieldsFilled && isPasswordValid && firstNameLength && lastNameLength) {
      const isEmailOpen = await checkEmailAvailability(email);
      if (isEmailOpen) {
        navigate('/gettingstarted', {
          state: {
            formData,
            token
          },
        });
      } else {
        setMessage('Email is not available! Please use another.');
      }
    } else {
      if (!isFormFieldsFilled) {
        setMessage('Please fill out all forms.');
      } else if (!isEmailValid) {
        setMessage('Please enter a valid Email!');
      } else if (!isPasswordValid) {
        setMessage('Please include at least one uppercase letter, 8 characters, and one number in your password.');
      } else if( !firstNameLength || !lastNameLength) {
        setMessage("Please enter at least two characters for the first and last name!")
      } else {
        setMessage('Passwords do not match!');
      }
    }
  };
  
  return (
    <Container maxWidth="xl" ml={0}>

    <Stack height={"100vh"}>
      <Breadcrumbs separator="›" mt={3} ml={4}>
          {breadcrumbs}
      </Breadcrumbs>
      <Stack mt={2} sx={{ alignItems: "center"}} spacing={1}>
        <Typography variant="h4">Create User Account</Typography>

        <Grid 
          container 
          component="form"
          justifyContent="center" 
          sx={{width: "85%"}}
          rowSpacing={2}
          columnSpacing={2}
        >

          <Grid item xs={6}>
            <TextField
              type='text'
              fullWidth
              required
              name='first'
              value={first}
              variant="outlined"
              label="First Name"
              onChange={(event) => handleInputChange(event)}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              type='text'
              required
              fullWidth
              name='last'
              value={last}
              variant="outlined"
              label="Last Name"
              onChange={(event) => handleInputChange(event)}
            /> 
          </Grid>

          <Grid item xs={12} sm={6}>
            <Stack direction={"row"} justifyContent="space-evenly" spacing={1}>
              <FormControl fullWidth required>
                <InputLabel id="month"> MM </InputLabel>
                <Select
                  labelId="month"
                  value={month}
                  label="MM"
                  name="month"
                  variant="outlined" 
                  onChange={(event)=> {
                    const {name, value} = event.target
                    setFormData({ ...formData, [name]: value });
                  }}
                >
                  {months.map((month, index) => (
                    <MenuItem key={index} value={month}>
                        {month}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
  
              <FormControl fullWidth required>
                <InputLabel id="day">DD</InputLabel>
                  <Select                
                    value={day}
                    variant="outlined" 
                    labelId="day"
                    label="DD"
                    name="day"
                    onChange={(event)=> {
                      const {name, value} = event.target
                      setFormData({ ...formData, [name]: value });
                    }}
                    >
                    {days.map((day, index) => (
                        <MenuItem key={index} value={day}>
                            {day}
                        </MenuItem>
                    ))}
                  </Select>                
              </FormControl>
    
              <FormControl fullWidth required>
                <InputLabel id="year">YYYY</InputLabel>
                <Select              
                  value={year}
                  labelId="year"
                  label="YYYY"
                  name="year"
                  variant="outlined" 
                  onChange={(event)=> {
                    const {name, value} = event.target
                    setFormData({ ...formData, [name]: value });
                  }}
                  >
                  {years.map((year, index) => (
                      <MenuItem key={index} value={year} justifyContent="center">
                          {year}
                      </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl fullWidth required>
              <InputLabel id="country">Country</InputLabel>
              <Select
                value={country}
                autoComplete="country"
                variant="outlined" 
                labelId="country"
                label="Country"
                id="country" 
                name="country"
                onChange={(event)=> {
                  const {name, value} = event.target
                  setFormData({ ...formData, [name]: value });
                }}
              >
                {countries.map((country, index) => (
                  <MenuItem key={index} value={country} disabled={country === "---------------------------------"}>
                      {country}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              required
              type='text'
              value={email}
              variant="outlined"
              label="Email"
              name='email'
              autoComplete="email"
              disabled={disabled}   
              onChange={(event)=> {
                const {name, value} = event.target
                const sanitizedValue = value.replace(/\s/g, '');
                setFormData({ ...formData, [name]: sanitizedValue });
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              onFocus={() => setFocusOne(true)}
              value={password}
              type={showPasswordOne ? 'text' : 'password'}
              variant="outlined"
              autoComplete="new-password"
              placeholder='Password'
              label="Password"
              fullWidth
              required
              name='password'
              onChange={(event)=> {
                const {name, value} = event.target
                const sanitizedValue = value.replace(/\s/g, '');
                setFormData({ ...formData, [name]: sanitizedValue });
              }}
              InputProps={{
                  startAdornment: focusOne && (
                  <InputAdornment position="start">
                    <IconButton
                        sx={{ "&:hover": { backgroundColor: "transparent" }, p: 0 }}
                        onClick={() => setShowPasswordOne((show) => !show)} 
                    >
                      {showPasswordOne ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                    </IconButton>
                  </InputAdornment>
                  ),
              }}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              fullWidth
              required
              onFocus={() => setFocusTwo(true)}
              type={showPasswordTwo ? 'text' : 'password'}
              value={secondPassword}
              variant="outlined"
              name='secondPassword'
              label="Re-enter Password"
              autoComplete="new-password"
              onChange={(event)=> {
                const {name, value} = event.target
                const sanitizedValue = value.replace(/\s/g, '');
                setFormData({ ...formData, [name]: sanitizedValue });
              }}
              InputProps={{
                startAdornment: focusTwo && (
                  <InputAdornment position="start">
                    <IconButton
                        sx={{ "&:hover": { backgroundColor: "transparent" }, padding: 0 }}
                        onClick={() => setShowPasswordTwo((show) => !show)}
                    >
                        {showPasswordTwo ? <VisibilityOffOutlined /> : <VisibilityOutlined />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <PasswordChecklist
              rules={["minLength","number","capital","match"]}
              minLength={8}
              invalidColor={"#d32f2f"}
              validColor={"#2e7d32"}
              value={password}
              valueAgain={secondPassword}
              onChange={(isValid) => {}}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography color={"error"} fontSize={16}>
              {message}
            </Typography>
          </Grid>

          <Grid item xs={12}>
              <Button 
                fullWidth
                onClick={handleSubmit}
                variant='contained'
                disableRipple
                >
                Next
              </Button>
          </Grid>
      </Grid>

      <Typography variant="body1" mt={1} sx={{color: "rgba(0, 0, 0, 0.5)", fontSize: ".9rem"}}> 
          Have an account already? 
          <Link to={'/login'} style={{color: "black", fontWeight: 600, cursor: "pointer", marginLeft: "4px"}}>
              Login
          </Link>
      </Typography>

      </Stack>
      </Stack>
    </Container>
  );
}