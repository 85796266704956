import api from "../utils/apiCall";

// Fetch all Org Users in Org
export const fetchAllOrgUsersInOrg = async (
  id,
  datadogMsg,
  params,
  admin = false,
  funcName = "fetchAllOrgUsersInOrg"
) => {
  const { page, offset, search_item, status } = params;
  return await api({
    path: `/org/orgusers/${page + 1}/${offset}/${status}/${
      search_item
        ? search_item.includes("/")
          ? search_item.replaceAll("/", "***")
          : search_item.includes("\\")
          ? search_item.replaceAll("\\", "@@@")
          : search_item
        : " "
    }/${id}`,
    params,
    datadogMsg,
    admin,
    funcName,
  }).then((response) => response);
};

// Fetch Org Activities
export const fetchOrgActivities = async (
  orgId,
  datadogMsg,
  params,
  admin = false,
  funcName = "fetchOrgActivities"
) => {
  return await api({
    path: `/org/orgactivities/${orgId}`,
    params,
    datadogMsg,
    admin,
    funcName,
  }).then((response) => response);
};
