// ----------------------------------------------------------------------

export default function Switch(theme) {
    return {
        MuiSwitch: {
            styleOverrides: {
                root: {
                    '&:active': {
                    '& .MuiSwitch-switchBase.Mui-checked': {},
                    },
                },
                switchBase: {
                    '&.Mui-checked': {
                    color: 'rgba(255, 192, 77, 1)',
                    '& + .MuiSwitch-track': {
                        opacity: 1,
                        backgroundColor: 'rgba(255, 192, 77, .4)',
                    },
                    },
                },
                track: {
                    borderRadius: 16 / 2,
                    opacity: 1,
                    backgroundColor:
                    theme.palette.mode === 'dark' ? '#e4e4e4' : 'rgba(0,0,0,.25)',
                    boxSizing: 'border-box',
                },
            },
        },
    };
  }
  