import React from "react";
import { Navigate, useLocation } from "react-router-dom";

import ErrorBoundary from "../components/ErrorBoundary";

const UnProtectedRoute = (props) => {
  const location = useLocation();
  const userToken = localStorage.getItem("myState");

  if (userToken && !location.pathname.includes("completeregistration")) {
    return <Navigate to="/dashboard/home" />;
  }

  return (
    <React.Fragment>
      <ErrorBoundary>{props.children}</ErrorBoundary>
    </React.Fragment>
  );
};

export default UnProtectedRoute;
