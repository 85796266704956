import { Navigate, useRoutes } from "react-router-dom";
import { useState } from "react";
// Layouts
import WebAppLayout from "../layouts/WebAppLayout";
import SimpleLayout from "../layouts/SimpleLayout";
import AuthLayout from "../layouts/AuthLayout";
// App Pages
import Account from "../pages/app/account";
import Athlete from "../pages/app/Athlete";
import Sessions from "../pages/app/Sessions";
import Dashboard from "../pages/app/Dashboard";
import OrgAthletes from "../pages/app/OrgAthletes";
import SpecificSession from "../pages/app/SpecificSession";
// Auth Pages
import Login from "../pages/auth/Login";
import Page404 from "../pages/auth/Page404";
import InviteUser from "../pages/auth/InviteUser";
import CreateAccount from "../pages/auth/CreateAccounts";
import GettingStarted from "../pages/auth/GettingStarted";
import ForgotPassword from "../pages/auth/ForgotPassword";
import CreateNewPassword from "../pages/auth/CreateNewPassword";
import CompleteRegistration from "../pages/auth/CompleteRegistration";
//Protected-UnProtected COmponents wrapper
import ProtectedRoute from "./ProtectedRoutes";
import UnProtectedRoute from "./UnProtectedRoutes";
import FillUserMetadata from "../pages/auth/FillUserMetadata";
import ReviewAccount from "../pages/auth/ReviewAccount";
// ----------------------------------------------------------------------

export default function Router() {
  const [admin, setAdmin] = useState(null);
  const [userId, setUserId] = useState(null);
  const [orgId, setOrgId] = useState(null);
  const [activities, setActivities] = useState(null);
  const [name, setName] = useState(null);

  const routes = useRoutes([
    {
      path: "/dashboard",
      element: (
        <ProtectedRoute>
          <WebAppLayout
            admin={admin}
            orgId={orgId}
            name={name}
            setName={setName}
            setAdmin={setAdmin}
            setUserId={setUserId}
            setOrgId={setOrgId}
            setActivities={setActivities}
          />
        </ProtectedRoute>
      ),
      children: [
        // { element: <Navigate to="/dashboard/" />, index: true },
        { element: <Navigate to="/dashboard/home" />, index: true },
        {
          path: "home",
          element: (
            <Dashboard
              admin={admin}
              activities={activities}
              orgId={orgId}
              userId={userId}
            />
          ),
        },

        {
          path: "sessions",
          element: (
            <Sessions
              admin={admin}
              userId={userId}
              orgId={orgId}
              activities={activities}
            />
          ),
        },

        {
          path: "specificsession/:sessionNumber",
          element: (
            <SpecificSession
              admin={admin}
              activities={activities}
              orgId={orgId}
            />
          ),
        },

        {
          path: "athletes",
          element: <OrgAthletes orgId={orgId} activities={activities} />,
        },

        {
          path: "athletepage/:athlete",
          element: <Athlete orgId={orgId} />,
        },

        {
          path: "account",
          element: (
            <Account userId={userId} setOrgId={setOrgId} setName={setName} />
          ),
        },
      ],
    },
    {
      path: "/",
      element: (
        <UnProtectedRoute>
          <AuthLayout />
        </UnProtectedRoute>
      ),
      children: [
        { element: <Navigate to="/login" />, index: true },
        {
          path: "login",
          element: <Login />,
        },
        {
          path: "createaccount",
          element: <CreateAccount />,
        },
        {
          path: "gettingstarted",
          element: <GettingStarted />,
        },
        {
          path: "forgotpassword",
          element: <ForgotPassword />,
        },
        {
          path: "createnewpassword",
          element: <CreateNewPassword />,
        },
        {
          path: "completeregistration",
          element: <CompleteRegistration />,
        },
        {
          path: "createnewaccount",
          element: <InviteUser />,
        },
        {
          path: "completeaccount",
          element: <FillUserMetadata />,
        },
        {
          path: "reviewaccount",
          element: <ReviewAccount />,
        },
      ],
    },
    {
      element: <SimpleLayout />,
      children: [
        { path: "404", element: <Page404 /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
  ]);

  return routes;
}
