import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
// @mui
import { List, ListItemButton, ListItemIcon, ListItemText, Collapse, Skeleton, Avatar } from '@mui/material';
// @mui-icons
import { ExpandLess, ExpandMore } from '@mui/icons-material/'

export default function DropDownList({
  activeOrgs, setActiveOrgs, orgId, setOrgId
}) {
  const [open, setOpen] = React.useState(false);
  const handleClick = () => {
    setOpen(!open);
  };
  const navigate = useNavigate();
  const location = useLocation()

  const handleSwitchOrgs = (orgId, activeOrg) => {
    const storedData = JSON.parse(localStorage.getItem('myState'));
    storedData.org_id = orgId;
    localStorage.setItem('myState', JSON.stringify(storedData));
  
    const updatedOrgs = [activeOrg, ...activeOrgs.filter(org => org.organization_id !== orgId)];
    setOrgId(orgId);
    setActiveOrgs(updatedOrgs);
    setOpen(false);

    if(location.pathname.includes('athletepage')) {
      navigate('/dashboard/athletes')
    }
    if(location.pathname.includes('specificsession')) {
      navigate('/dashboard/sessions')
    }

    if(location.pathname.includes('athletes')){
      navigate('/dashboard/home')
    }
  };
  
  // Loading State
  if (!activeOrgs || activeOrgs.length === 0) {

      return (
        <List sx={{ width: '100%', bgcolor: 'background.paper' }} component="nav">
          <ListItemButton disableRipple>
            <ListItemIcon>
              <Skeleton variant="rounded" width={40} height={40} sx={{ bgcolor: 'grey.300' }}/>
            </ListItemIcon>
            <ListItemText primary={<Skeleton variant="text" width={100} sx={{ bgcolor: 'grey.300' }}/>} />
          </ListItemButton>
        </List>
      );
    
  }

  if(activeOrgs && activeOrgs.length > 1) {
  let org
  for(let i=0; i < activeOrgs.length; i++ ) {
    if(activeOrgs[i].organization_id === orgId) {
      org = activeOrgs[i]
      activeOrgs.splice(i, 1)
      activeOrgs.unshift(org)
    }
  }
  }

  let cards 
  if(activeOrgs.length === 1){
    return (
      <List sx={{ width: '100%', bgcolor: 'background.paper' }} component="nav">
        <ListItemButton disableRipple onClick={handleClick}>
          <ListItemIcon>    
            <Avatar 
              variant='rounded' 
              src={`${activeOrgs[0].logo}`} 
              alt={`${activeOrgs[0].name}`} 
              sx={{border: "1px solid rgba(158, 158, 158, 1)"}}
            />   
          </ListItemIcon>
            <ListItemText primary={`${activeOrgs[0].name}`} />
        </ListItemButton>
      </List>
    )
  } 
  if (activeOrgs.length > 1) {
      cards = activeOrgs.slice(1).map((activeOrg, index) => {
      return <List component="div" disablePadding >
              <ListItemButton disableRipple onClick={() => handleSwitchOrgs(activeOrg.organization_id, activeOrg)}>
                <ListItemIcon>
                  <Avatar 
                    variant='rounded' 
                    src={`${activeOrg.logo}`} 
                    alt={`${activeOrg.name}`} 
                    sx={{border: "1px solid rgba(158, 158, 158, 1)"}}
                  /> 
                </ListItemIcon>
                <ListItemText primary={`${activeOrg.name}`} />
            </ListItemButton>
        </List> 
      })


      return (
        <List sx={{ width: '100%', bgcolor: 'background.paper' }} component="nav">
          <ListItemButton disableRipple onClick={handleClick}>
            <ListItemIcon>     
              <Avatar 
                variant='rounded' 
                src={`${activeOrgs[0].logo}`} 
                alt={`${activeOrgs[0].name}`} 
                sx={{border: "1px solid rgba(158, 158, 158, 1)"}}
              />        
            </ListItemIcon>
            <ListItemText primary={`${activeOrgs[0].name}`} />

            {open ? <ExpandLess /> : <ExpandMore />} 
          </ListItemButton>

          <Collapse in={open} timeout="auto" unmountOnExit>  
            {cards}
          </Collapse> 
        </List>
      )
  } 

    return (
        <List sx={{ width: '100%', bgcolor: 'background.paper' }} component="nav">
              {cards}
        </List>
    );
  }