import { Typography } from "@mui/material";
// ----------------------------------------------------------------------
export const whiteButtonStyle = {
    border: ".7px solid black",
    background: "white",
    '&:hover': {
        background: 'rgba(0, 0, 0, 0.065)'
    },
};

export const yellowBorderButtonStyle = {
  border: ".7px solid #FFC04D",
  background: "white",
  '&:hover': {
      background: 'rgba(0, 0, 0, 0.065)'
  },
};

export const inviteAthleteModalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "58%",
  bgcolor: 'background.paper',
  borderRadius: 1,
  p: 4,
};

export const editAthleteModalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 550,
  maxWidth: "60%",
  bgcolor: 'background.paper',
  borderRadius: 1,
  p: 5,
};

export const authModalStyles = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  width: "fit-content",
  transform: 'translate(-50%, -50%)',
  bgcolor: 'background.paper',
  borderRadius: 1,
  p: 6,
};

export const navDrawerStyle = {
  border: "none",
  boxSizing: "border-box",
  gap: 0.5,
  boxShadow:
      "0px 0px 0.5px rgba(66, 71, 76, 0.32), 0px 4px 8px rgba(66, 71, 76, 0.05), 0px 4px 40px #EEEEEE",
}

export const accountTextFieldStyleTwo = {
  width: "50%",
  "& label.Mui-focused": {
      color: "#FFC04D"
  },
  "& .MuiInput-underline": {
      '&:hover:not(.Mui-disabled,.Mui-error)::before ': {
          borderBottom: '1px solid #FFC04D', 
      },
  },
  '& .MuiInputBase-root': { 
      '&:hover ': {
          borderColor: '#FFC04D',
      },
  }    
}

export const registerAccountBreadcrumbs = [
    <Typography> Step 1 </Typography>,
    <Typography>Step 2</Typography>,
    <Typography>Step 3</Typography>,
];