
import React, { useState } from "react";
// ChartJS
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  LineElement,
  Filler,
  PointElement,
  Legend,
} from "chart.js";
import { Bar, Line } from "react-chartjs-2";
// @mui
import { 
  Box, 
  Stack, 
  Card,
  CardHeader,
  FormControlLabel,
  CardContent,
  Skeleton
} from "@mui/material";
// Charts
import { 
  adjustLegend, barPattern, stackedBarChartOptions, getSessionCountData, 
  getRepData, lineChartOptions, getMonthlyChartData, getYearlyChartData, generateLineData
} from "../../components/chart/chartData";
// Components
import { ChartToggleButtonGroup} from "../../components/button-groups"
// ----------------------------------------------------------------------

ChartJS.register(BarElement, LineElement, PointElement, Filler, CategoryScale, LinearScale, Tooltip, Legend);

export default function StackedBarChartCard({
  timeframe,
  activities,
  activityType,
  sessionSummary,
  chartLabels
}) {
  const [showReps, setShowReps] = useState(true);
  const [alignment, setAlignment] = useState("left");

  const handleChange = (event, newAlignment) => {
    setAlignment(newAlignment);
  };
  
  const control = {
    value: alignment,
    onChange: handleChange,
    exclusive: true,
  };

  const handleAlignment = (event, newAlignment) => {
    setAlignment(newAlignment);
  };

  const chartType = chartLabels.length <= 7;
  const repData = getRepData(sessionSummary, activities, timeframe, chartLabels)
  const sessionCountData = getSessionCountData(sessionSummary, timeframe, chartLabels)

  let data
  if(chartLabels.length === 12) {
    data = getYearlyChartData(sessionSummary, activities, timeframe)
  } else {
    data = getMonthlyChartData(sessionSummary, activities, timeframe)
  }

  return (
    <Card>
      <CardHeader
        title={showReps ? `Amount of Reps in ${activityType}` : `Number of Sessions in ${activityType}`} 
        action={
            <FormControlLabel
              control={
                <ChartToggleButtonGroup
                alignment={alignment}
                handleAlignment={handleAlignment}
                setShowReps={setShowReps}
                control={control}
              />
              }
            />
        }
      />
      <CardContent>
        <Stack
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
          pb={0}
          sx={{ height: 365 }}
        >
          {sessionSummary && activities ? (
            <Box sx={{ height: 365, width: "100%" }} >
              {chartType ? (
                <Bar
                  data={showReps ? repData : sessionCountData}
                  options={stackedBarChartOptions}
                  plugins={[adjustLegend, barPattern]}
                  data-testid="chart"
                />
                ) : ( 
                 <Line 
                  data={generateLineData(data, chartLabels, showReps, activities, timeframe)} 
                  options={lineChartOptions} 
                  plugins={[adjustLegend]} 
                  data-testid="chart"
                  />
                )} 
            </Box>
          ) : (
            <Skeleton 
              variant="rounded" 
              width="100%" 
              height="90%" 
              sx={{ bgcolor: 'grey.200' }} 
              data-testid="loading-skeleton"
            />
          )}
        </Stack>
      </CardContent>
    </Card>
  );
}