import { getActivityId } from "./utilityFunctions";
// import { withTimingAndLogging } from "./logFunctions/logs";
import {
  fetchPaginatedUserSessions,
  fetchPaginatedOrgSessions,
} from "../api/sessionRoutes";
import { createCoachData, createAthleteData } from "./tableFunctions";
// ----------------------------------------------------------------------

export const fetchSessionHistoryData = async (
  activities,
  activityType,
  rowsPerPage,
  orgId,
  userId,
  lastKey,
  admin,
  tabValue,
  page,
  handleButtonClick,
  handleFlagClick
) => {
  const activity_id = getActivityId(activityType, activities);

  const fetchFunction = admin
    ? () =>
        fetchPaginatedOrgSessions(
          orgId,
          rowsPerPage,
          lastKey,
          activity_id,
          tabValue,
          page
        )
    : () =>
        fetchPaginatedUserSessions(
          userId,
          orgId,
          rowsPerPage,
          lastKey,
          activity_id,
          tabValue,
          page
        );

  const params = admin
    ? {
        org_id: orgId,
        limit: rowsPerPage,
        last_key: lastKey,
        activity_id: activity_id,
        status: tabValue,
        page,
      }
    : {
        user_id: userId,
        org_id: orgId,
        limit: rowsPerPage,
        last_key: lastKey,
        activity_id: activity_id,
        status: tabValue,
        page,
      };

  const funcName = admin
    ? "fetchPaginatedOrgSessions"
    : "fetchPaginatedUserSessions";
  // const data = await withTimingAndLogging(fetchFunction, 'Paginated Sessions have been fetched.', admin, funcName, params);

  const data = admin
    ? await fetchPaginatedOrgSessions(
        orgId,
        rowsPerPage,
        lastKey,
        activity_id,
        tabValue,
        page,
        "Paginated Sessions have been fetched.",
        params,
        admin
      )
    : await fetchPaginatedUserSessions(
        userId,
        orgId,
        rowsPerPage,
        lastKey,
        activity_id,
        tabValue,
        page,
        "Paginated Sessions have been fetched.",
        params,
        admin
      );
  const {
    total_sessions: totalSessionsDone,
    items: sessions,
    last_key: newLastKey,
  } = data.data.data;

  const newSessions = admin
    ? sessions.map((session, index) =>
        createCoachData(
          session,
          activities,
          index,
          handleButtonClick,
          handleFlagClick
        )
      )
    : sessions.map((session, index) =>
        createAthleteData(
          session,
          activities,
          index,
          handleButtonClick,
          handleFlagClick
        )
      );

  return { totalSessionsDone, sessions, newLastKey, newSessions };
};
