// ----------------------------------------------------------------------

export default function ToggleButtonGroup(theme) {
    return {
      MuiToggleButtonGroup: {
        styleOverrides: {
          grouped: {
            margin: theme.spacing(0.5),
            border: '10px',
            '&.Mui-disabled': {
              border: '10px', 
            },
            '&:not(:first-of-type)': {
              borderRadius: '10px',
            },
            '&:first-of-type': {
              borderRadius: '10px',
            },
          },
        },
      },
    };
  }
  