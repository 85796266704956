import React, { useState, useEffect } from "react";
// @mui
import {
  Container,
  Grid,
  Typography,
  Stack,
  Snackbar,
  Alert,
} from "@mui/material";
import {
  FormatListBulletedOutlined,
  AccessTimeOutlined,
  ArrowDropDownCircleTwoTone,
} from "@mui/icons-material";
// APIs
import {
  fetchOrgDashboardSummary,
  fetchUserDashboardSummary,
} from "../../api/sessionRoutes";
import { fetchAdminStatus } from "../../api/authRoutes";
// Components
import {
  SessionCardInfo,
  StackedBarChartCard,
  ProductiveCard,
} from "../../sections/dashboard";
import { TimeFrameSelect, ActivitySelect } from "../../components/selects";
// Utils
import {
  getWeekDates,
  getMonthDates,
  getYearDates,
  getActivityId,
  getFormattedDateWithTimezone,
  generateChartLabels,
  fancyTimeFormat,
} from "../../utils/utilityFunctions";
// import { withTimingAndLogging } from "../../utils/logFunctions/logs";
// ----------------------------------------------------------------------

export default function DashboardPage({ admin, activities, orgId, userId }) {
  const initialTimeframe = getWeekDates();
  const [timeframe, setTimeframe] = useState(initialTimeframe);
  const [showExpirationMessage, setShowExpirationMessage] = useState({
    msg: "",
    severity: "",
  });
  const [selectValue, setSelectValue] = useState("week");
  const [activityType, setActivityType] = useState("All Activities");
  const [totalSessionNumber, setTotalSessionNumber] = useState(null);
  const [totalDuration, setTotalDuration] = useState(null);
  const [mostTrainedAthlete, setMostTrainedAthlete] = useState(null);
  const [leastTrainedAthlete, setLeastTrainedAthlete] = useState(null);
  const [mostTrainedAthleteReps, setMostTrainedAthleteReps] = useState(null);
  const [leastTrainedAthleteReps, setLeastTrainedAthleteReps] = useState(null);
  const [averageRepCount, setAverageRepCount] = useState("");
  const [chartLabels, setChartLabels] = useState([]);
  const [dashboardSummary, setDashboardSummary] = useState(null);

  const [state, setState] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal } = state;

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const handleSelectChange = (e) => {
    const newSelectValue = e.target.value;
    setSelectValue(newSelectValue);

    let newTimeframe = [];
    if (newSelectValue === "month") {
      newTimeframe = getMonthDates();
    } else if (newSelectValue === "year") {
      newTimeframe = getYearDates();
    } else {
      newTimeframe = getWeekDates();
    }

    newTimeframe = [...newTimeframe];
    setTimeframe(newTimeframe);
  };

  const handleActivityChange = (event) => {
    const selectedActivity = event.target.value;
    setActivityType(selectedActivity);
  };

  function resetDashboardState() {
    setDashboardSummary(null);
    setMostTrainedAthlete(null);
    setLeastTrainedAthlete(null);
    setMostTrainedAthleteReps(null);
    setLeastTrainedAthleteReps(null);
    setTotalSessionNumber(null);
    setTotalDuration(null);
    setAverageRepCount(null);
  }

  function updateDashboardSummary(data) {
    const sessionData =
      data.sessions_summary === null ? {} : data.sessions_summary;
    setDashboardSummary(sessionData);
    setTotalSessionNumber(data.total_sessions);
    const totalDurationFormatted = fancyTimeFormat(data.total_duration);
    setTotalDuration(totalDurationFormatted);
  }

  //code for expiration modal
  const handleExpirationModal = async () => {
    // no need to hit fetchAdminStatus() at the time of org change
    if (localStorage.getItem("changingOrg") === "false")
      await fetchAdminStatus(userId, orgId, "Fetching admin status.", {
        user_id: userId,
        org_id: orgId,
      });

    if (!localStorage.getItem("expiryDetails")) return;
    const expiryDetails = JSON.parse(localStorage.getItem("expiryDetails"));
    const days_left = expiryDetails.days_left;
    if (days_left == 365) return;
    else if (days_left < 0) {
      setShowExpirationMessage({
        severity: "error",
        msg: expiryDetails.expiration_response,
      });
    } else {
      if (days_left > 30) return;
      setShowExpirationMessage({
        severity: "warning",
        msg: `You have ${days_left} Days left before expiration`,
      });
    }
    setState({ ...state, open: true });
  };

  async function updateAthleteStats(userSummary) {
    if (userSummary) {
      const sortedData = Object.entries(userSummary).sort(
        (a, b) => a[1].total_reps - b[1].total_reps
      );
      const totalReps = Object.values(userSummary).reduce(
        (total, user) => total + user.total_reps,
        0
      );
      const average = (totalReps / Object.keys(userSummary).length).toFixed();

      setMostTrainedAthlete(
        `${sortedData[sortedData.length - 1][1].first_name} ${
          sortedData[sortedData.length - 1][1].last_name
        }`
      );
      setLeastTrainedAthlete(
        `${sortedData[0][1].first_name} ${sortedData[0][1].last_name}`
      );
      setMostTrainedAthleteReps(
        sortedData[sortedData.length - 1][1].total_reps
      );
      setLeastTrainedAthleteReps(sortedData[0][1].total_reps);
      setAverageRepCount(average);
    } else {
      setMostTrainedAthlete("No Current Athletes");
      setLeastTrainedAthlete("No Current Athletes");
      setMostTrainedAthleteReps("0");
      setLeastTrainedAthleteReps("0");
      setAverageRepCount("0");
    }
  }

  useEffect(() => {
    async function fetchData(org, user, admin) {
      resetDashboardState();

      const activity_id = getActivityId(activityType, activities);
      const { stime, etime } = getFormattedDateWithTimezone(selectValue);
      const chartLabel = generateChartLabels(selectValue);
      setChartLabels(chartLabel);

      const fetchFunction = admin
        ? () => fetchOrgDashboardSummary(org, stime, etime, activity_id)
        : () => fetchUserDashboardSummary(user, org, stime, etime, activity_id);

      const params = admin
        ? {
            org_id: org,
            start_time: stime,
            end_time: etime,
            activity_id: activity_id,
          }
        : {
            user_id: user,
            org_id: org,
            start_time: stime,
            end_time: etime,
            activity_id: activity_id,
          };

      const funcName = admin
        ? "getOrgDashboardSummary"
        : "getUserDashboardSummary";

      // const response = await withTimingAndLogging(fetchFunction, 'Dashboard session summary has been fetched.', admin, funcName, params);

      const response = admin
        ? await fetchOrgDashboardSummary(
            org,
            stime,
            etime,
            activity_id,
            "Dashboard session summary has been fetched.",
            params,
            admin
          )
        : await fetchUserDashboardSummary(
            user,
            org,
            stime,
            etime,
            activity_id,
            "Dashboard session summary has been fetched.",
            params,
            admin
          );

      if (admin) {
        updateAthleteStats(response.data.data.user_summary);
      }

      updateDashboardSummary(response.data.data);
    }

    if (orgId && admin !== null && activities) {
      fetchData(orgId, userId, admin);
    }
  }, [orgId, selectValue, activityType, admin]);

  useEffect(() => {
    handleExpirationModal();
  }, []);

  return (
    <Container ml={0} maxWidth="xl">
      <Typography variant="h4" mb={4} ml={1}>
        {" "}
        {admin ? "Admin Dashboard" : "User Dashboard"}
      </Typography>
      <Snackbar
        open={state.open}
        anchorOrigin={{ vertical, horizontal }}
        autoHideDuration={6000}
        key={vertical + horizontal}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={showExpirationMessage.severity}
          variant="filled"
        >
          {showExpirationMessage.msg}
        </Alert>
      </Snackbar>

      <Stack pb={2} direction="row">
        <ActivitySelect
          activityType={activityType}
          handleActivityChange={handleActivityChange}
          activities={activities}
        />
        <TimeFrameSelect
          selectValue={selectValue}
          handleSelectChange={handleSelectChange}
        />
      </Stack>

      <Grid container spacing={2} columns={18}>
        {admin && (
          <>
            <Grid item xs={18} sm={6} md={6}>
              <ProductiveCard
                title="Most Active"
                number={mostTrainedAthleteReps}
                athlete={mostTrainedAthlete}
                icon={
                  <ArrowDropDownCircleTwoTone
                    sx={{ color: "success.light", transform: "rotate(180deg)" }}
                  />
                }
                suffix={"reps"}
                activityType={activityType}
              />
            </Grid>

            <Grid item xs={18} sm={6} md={6}>
              <ProductiveCard
                title="Average Reps"
                number={averageRepCount}
                athlete={`This past ${selectValue}`}
                icon={
                  <ArrowDropDownCircleTwoTone
                    sx={{ color: "primary.main", transform: "rotate(270deg)" }}
                  />
                }
                suffix={"reps"}
                activityType={activityType}
              />
            </Grid>

            <Grid item xs={18} sm={6} md={6}>
              <ProductiveCard
                title="Least Active"
                number={leastTrainedAthleteReps}
                athlete={leastTrainedAthlete}
                icon={
                  <ArrowDropDownCircleTwoTone sx={{ color: "warning.main" }} />
                }
                suffix={"reps"}
                activityType={activityType}
              />
            </Grid>
          </>
        )}

        <Grid item xs={18} sm={18} md={18}>
          <StackedBarChartCard
            activities={activities}
            timeframe={timeframe}
            chartLabels={chartLabels}
            sessionSummary={dashboardSummary}
            activityType={activityType}
          />
        </Grid>

        <Grid item xs={18} sm={9} md={9}>
          <SessionCardInfo
            title="Total Sessions Completed"
            total={totalSessionNumber}
            icon={
              <FormatListBulletedOutlined sx={{ color: "success.light" }} />
            }
            activityType={activityType}
            suffix={"sessions"}
          />
        </Grid>

        <Grid item xs={18} sm={9} md={9}>
          <SessionCardInfo
            title="Total Time Spent"
            total={totalDuration}
            icon={<AccessTimeOutlined sx={{ color: "success.light" }} />}
            suffix={"hh-mm-ss"}
            activityType={activityType}
          />
        </Grid>
      </Grid>
    </Container>
  );
}
