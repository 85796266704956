import DownloadSelect from "../../components/selects/DownloadSelect";

// @mui
import { Menu } from "@mui/material";
// ----------------------------------------------------------------------

export function SessionGridDropDown({
  testSessionClicked,
  anchorEl,
  open,
  onClose,
  userId,
  orgId,
  session,
}) {
  return (
    <Menu id="long-menu" anchorEl={anchorEl} open={open} onClose={onClose}>
      <DownloadSelect
        onClose={onClose}
        ids={session}
        userId={userId}
        orgId={orgId}
        isTestSession={testSessionClicked}
      />
    </Menu>
  );
}
