import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import validator from "validator";
import PasswordChecklist from "react-password-checklist";
// @mui
import {
  Typography,
  Stack,
  InputAdornment,
  IconButton,
  Container,
  Button,
  Modal,
  Box,
  Divider,
  TextField,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// @mui-icons
import {
  ArrowBack,
  VisibilityOffOutlined,
  VisibilityOutlined,
} from "@mui/icons-material";
// APIs
import { resetPassword, forgotPassword } from "../../api/authRoutes";
// Utils
import { whiteButtonStyle, authModalStyles } from "../../utils/styles";
// import { withTimingAndLogging } from '../../utils/logFunctions/logs';
// ----------------------------------------------------------------------

export default function CreateNewPassword() {
  const [focusOne, setFocusOne] = useState(false);
  const [focusTwo, setFocusTwo] = useState(false);
  const [passwordValue, setPasswordValue] = useState("");
  const [secondPasswordValue, setSecondPasswordValue] = useState("");
  const [showPasswordOne, setShowPasswordOne] = useState(false);
  const [showPasswordTwo, setShowPasswordTwo] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const [codeValue, setCodeValue] = useState("");
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState(null);

  useEffect(() => {
    if (location.state) {
      setEmail(location.state.email);
    }
  }, []);

  const handleChange = (e) => {
    const value = e.target.value;
    const sanitizedValue = value.replace(/\s/g, "");
    if (!isNaN(sanitizedValue) && sanitizedValue.length <= 6) {
      setCodeValue(sanitizedValue);
      if (sanitizedValue.length < 6) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    }
  };

  const resendCode = async () => {
    if (!email) {
      navigate("/forgotpassword");
    } else {
      const fetchFunction = () => forgotPassword(email);
      const funcName = `forgotPassword`;
      const params = { email: email };
      // const response = await withTimingAndLogging(fetchFunction, 'Resending forget password code.', false, funcName, params);
      const response = await forgotPassword(
        email,
        "Resending forget password code.",
        params
      );
      setMessage(response.data.data.message);
    }
  };
  const location = useLocation();

  const handleClickShowPasswordOne = () => setShowPasswordOne((show) => !show);
  const handleClickShowPasswordTwo = () => setShowPasswordTwo((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const validatePassword = (value) => {
    setPasswordValue(value);
    if (
      validator.isStrongPassword(value, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 0,
      }) &&
      value === secondPasswordValue
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const navigate = useNavigate();

  const determineSecondPasswordValue = (e) => {
    setSecondPasswordValue(e.target.value);
    if (
      validator.isStrongPassword(e.target.value, {
        minLength: 8,
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minSymbols: 0,
      }) &&
      passwordValue === e.target.value
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  const createPassword = async (e) => {
    setLoading(true);

    const fetchFunction = () => resetPassword(codeValue, email, passwordValue);
    const funcName = "resetPassword";
    const params = { code: codeValue, email: email, password: passwordValue };
    // const response = await withTimingAndLogging(fetchFunction, 'User has successfully updated password.', false, funcName, params);
    const response = await resetPassword(
      codeValue,
      email,
      passwordValue,
      "User has successfully updated password.",
      params
    );
    const { data, statusCode } = response;

    if (statusCode === 200) {
      setOpen(true);
      setLoading(false);
      setSuccessMessage("Your password has been reset successfully.");
    } else {
      setLoading(false);
      setErrorMessage(data.data.message);
    }
  };

  const sendEmail = () => {
    navigate("/forgotpassword", {
      state: {
        email: email,
      },
    });
  };

  return (
    <Container maxWidth="xl" ml={0}>
      <Stack
        component="form"
        alignItems="center"
        sx={{ width: "100%", height: "100vh" }}
      >
        <Typography variant="h4" mb={4} mt={6}>
          Enter Verification Code
        </Typography>

        <TextField
          label="Verification Code"
          variant="outlined"
          id="field"
          sx={{ width: "62%" }}
          value={codeValue}
          onChange={handleChange}
          inputProps={{ maxLength: 6 }}
        />

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="center"
          gap={1}
          mt={1.5}
        >
          <Typography fontWeight={500} sx={{ color: "rgba(0, 0, 0, 0.5)" }}>
            Didn't receive a code?
          </Typography>

          <Typography
            onClick={resendCode}
            fontWeight={600}
            sx={{ textDecoration: "underline", cursor: "pointer" }}
          >
            Resend
          </Typography>
        </Stack>

        <Typography mt={1} color="success.main">
          {message}
        </Typography>

        <Stack
          justifyContent="center"
          sx={{ width: "62%" }}
          mt={2}
          spacing={1.5}
          gap={1}
        >
          <TextField
            onFocus={() => setFocusOne(true)}
            value={passwordValue}
            id="field1"
            type={showPasswordOne ? "text" : "password"}
            variant="outlined"
            label="Enter Password"
            fullWidth
            onChange={(e) => validatePassword(e.target.value)}
            InputProps={{
              autoComplete: "new-password",
              startAdornment: focusOne && (
                <InputAdornment position="start">
                  <IconButton
                    sx={{
                      "&:hover": { backgroundColor: "transparent" },
                      padding: 0,
                    }}
                    onClick={handleClickShowPasswordOne}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPasswordOne ? (
                      <VisibilityOffOutlined />
                    ) : (
                      <VisibilityOutlined />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <TextField
            onFocus={() => setFocusTwo(true)}
            type={showPasswordTwo ? "text" : "password"}
            value={secondPasswordValue}
            id="field2"
            variant="outlined"
            label="Re-enter Password"
            fullWidth
            onChange={(e) => determineSecondPasswordValue(e)}
            InputProps={{
              autoComplete: "new-password",
              startAdornment: focusTwo && (
                <InputAdornment position="start">
                  <IconButton
                    sx={{
                      "&:hover": { backgroundColor: "transparent" },
                      padding: 0,
                    }}
                    onClick={handleClickShowPasswordTwo}
                    onMouseDown={handleMouseDownPassword}
                  >
                    {showPasswordTwo ? (
                      <VisibilityOffOutlined />
                    ) : (
                      <VisibilityOutlined />
                    )}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <PasswordChecklist
            rules={["minLength", "number", "capital", "match"]}
            minLength={8}
            invalidColor={"#d32f2f"}
            validColor={"#2e7d32"}
            value={passwordValue}
            valueAgain={secondPasswordValue}
            onChange={(isValid) => {}}
          />

          <Typography height={"2rem"} color={"error"}>
            {errorMessage}
          </Typography>

          <Stack direction={"row"} justifyContent="space-between">
            <Button
              onClick={sendEmail}
              sx={{ ...whiteButtonStyle }}
              width="md"
              disableRipple
              startIcon={<ArrowBack sx={{ color: "primary.main" }} />}
            >
              Back
            </Button>

            <LoadingButton
              variant="contained"
              disableRipple
              disabled={disabled}
              loading={loading}
              width="md"
              onClick={createPassword}
            >
              Confirm
            </LoadingButton>
          </Stack>
        </Stack>
      </Stack>

      <Modal open={open} onClose={() => setOpen(false)}>
        <Box sx={authModalStyles}>
          <Stack
            spacing={2}
            m={1}
            justifyContent="center"
            alignItems={"center"}
          >
            <Typography variant="h6" alignItems="center">
              Success!
            </Typography>
            <Divider />
            <Typography fontSize={16}>{successMessage}</Typography>
            <Button variant="contained" href="/login" sx={{ width: "50%" }}>
              Login
            </Button>
          </Stack>
        </Box>
      </Modal>
    </Container>
  );
}
