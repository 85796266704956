import React from 'react';
// @mui
import { Card, Stack, Typography, Skeleton } from '@mui/material';
// ----------------------------------------------------------------------

export default function SkeletonCard({}){

return  (
    <Card>
        <Stack direction="row" alignItems="center" justifyContent="space-between" p={1.5}>
        <Stack sx={{ width: "50%" }}>
            <Typography mb={.2} variant='body1' pl={3}> 
            <Skeleton sx={{ bgcolor: 'grey.300' }}/>
            </Typography>

            <Typography variant="body2" pl={3}>
            <Skeleton sx={{ bgcolor: 'grey.300' }}/>
            </Typography>
        </Stack>

        <Stack justifyContent="flex-end" sx={{ width: "30%" }}>
            <Typography variant="body2" pl={3}>
                <Skeleton sx={{ bgcolor: 'grey.300' }}/>
            </Typography>
            </Stack>
        </Stack>
    </Card>
    )
}