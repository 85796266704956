import React, { useState } from "react";
// @mui
import {
  Box,
  Stack,
  Modal,
  Divider,
  Typography,
  Tabs,
  Tab,
  IconButton,
} from "@mui/material/";
import { TabPanel, TabContext } from "@mui/lab";
// Components
import FileReader from "./FileReader";
import EmailInvite from "./EmailInvite";
// Utils
import { inviteAthleteModalStyle } from "../../../utils/styles";
import { Close } from "@mui/icons-material";
// ----------------------------------------------------------------------

export default function InviteAthleteModal({
  openModal,
  orgId,
  setOpenModal,
  handleClick,
  checkInviteLeft,
  invite_left,
}) {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  return (
    <Modal
      open={openModal}
      hideBackdrop={false}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box gap={2} sx={inviteAthleteModalStyle}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mb={1}
        >
          <Typography
            id="modal-modal-title"
            variant="h4"
            fontFamily={"Nasalization"}
            whiteSpace="nowrap"
          >
            Add Athlete
          </Typography>

          <IconButton onClick={handleCloseModal}>
            <Close />
          </IconButton>
        </Stack>

        <Divider />

        <Box display="flex" justifyContent="center">
          <Tabs value={value} onChange={handleChange}>
            <Tab
              label="Invite Athlete"
              sx={{
                fontSize: 13,
                "&.Mui-selected": {
                  color: "#181818",
                  fontWeight: 700,
                },
              }}
            />
            <Tab
              label="Upload CSV"
              sx={{
                fontSize: 13,
                "&.Mui-selected": {
                  color: "#181818",
                  fontWeight: 700,
                },
              }}
            />
          </Tabs>
        </Box>

        <TabContext value={value}>
          <TabPanel value={0}>
            <EmailInvite
              orgId={orgId}
              openModal={openModal}
              setOpenModal={setOpenModal}
              handleClick={handleClick}
              handleCloseModal={handleCloseModal}
              checkInviteLeft={(val) => checkInviteLeft(val)}
              invite_left={invite_left}
            />
          </TabPanel>

          <TabPanel value={1}>
            <Stack mt={4}>
              <FileReader
                orgId={orgId}
                handleCloseModal={handleCloseModal}
                checkInviteLeft={(val) => checkInviteLeft(val)}
                invite_left={invite_left}
              />
            </Stack>
          </TabPanel>
        </TabContext>
      </Box>
    </Modal>
  );
}
