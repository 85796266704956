import React from 'react';
// @mui
import { Card, Stack, Typography } from '@mui/material';
import SkeletonCard from './SkeletonCard';
// ----------------------------------------------------------------------

export default function ProductiveCard({
  icon, 
  title,
  activityType,
  athlete,
  number,
  suffix,
  ...other
}){

  if (!number) {
    return (
      <SkeletonCard/>
    )
  }

  return (
    <Card {...other}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" p={1.5}>

        <Stack sx={{width: "50%"}}>

          <Stack direction={"row"}>
            <Typography pr={.5}>
              {icon}
            </Typography>

            <Typography fontWeight={700} mb={.2} whiteSpace="nowrap" variant='body1' color={'grey.700'}> 
              {title}
            </Typography>
          </Stack>

          <Typography variant="body2" fontWeight={700} sx={{pb: 0, pl: 3}}>
            {athlete}
          </Typography>

        </Stack>

        <Stack direction="row" alignItems="flex-end" justifyContent="flex-end" sx={{width: "50%"}}>

          <Typography variant="h3" sx={{pb: .5, pr: 1}} whiteSpace="nowrap">
            {number}
          </Typography>

          <Typography variant="h6" sx={{ pb: .5, pr: 1}} whiteSpace="nowrap">
            {suffix}
          </Typography>

        </Stack>

      </Stack>
    </Card>
  )
}