import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Outlet, useParams } from "react-router-dom";
import { styled } from "@mui/material/styles";
// APIs
import { fetchAdminStatus } from "../api/authRoutes";
import { fetchOrgActivities } from "../api/orgRoutes";
// Components
import Nav from "./nav";
import Header from "./header";
/////////////////////////////////////

export default function WebAppLayout({
  admin,
  orgId,
  name,
  setUserId,
  setAdmin,
  setOrgId,
  setActivities,
  setName,
}) {
  const [open, setOpen] = useState(false);
  const [activeOrgs, setActiveOrgs] = useState(null);
  // ---------------
  const mobile = 64;
  const desktop = 92;
  const params = useParams();
  // ---------------
  // Have to be memoized
  const StyledRoot = useMemo(
    () =>
      styled("div")({
        display: "flex",
        minHeight: "100%",
        overflow: "hidden",
      }),
    []
  );

  const Main = useMemo(
    () =>
      styled("div")(({ theme }) => ({
        flexGrow: 1,
        overflow: "auto",
        fontSize: "10px",
        minHeight: "100%",
        paddingTop: mobile,
        paddingBottom: theme.spacing(10),
        [theme.breakpoints.up("lg")]: {
          paddingTop: desktop,
          paddingLeft: theme.spacing(2),
          paddingRight: theme.spacing(2),
        },
      })),
    [mobile, desktop]
  );
  // ---------------

  const fetchData = useCallback(
    async (user_id, org_id, name, active_orgs) => {
      localStorage.setItem("orgID", org_id);
      const [adminStatus, orgActivities] = await Promise.all([
        fetchAdminStatus(user_id, org_id, "Fetching admin status.", {
          user_id,
          org_id,
        }),
        fetchOrgActivities(org_id, "Fetching organization activities.", {
          org_id,
        }),
      ]);
      const isAdmin = adminStatus.data.data.type === "admin";
      const sortedActivities = orgActivities.data.data.activity_list.sort(
        (p1, p2) =>
          p1.display_name > p2.display_name
            ? 1
            : p1.display_name < p2.display_name
            ? -1
            : 0
      );

      setAdmin(isAdmin);
      setActivities(sortedActivities);
      setUserId(user_id || "");
      setOrgId(org_id || "");
      setName(name || "");
      setActiveOrgs(active_orgs || null);

      const parsedState = {
        ...JSON.parse(localStorage.getItem("myState")),
        admin: isAdmin,
        org_id: org_id,
      };

      localStorage.setItem("changingOrg", true);

      setTimeout(() => localStorage.setItem("changingOrg", false), 10000);

      localStorage.setItem("myState", JSON.stringify(parsedState));
    },
    [orgId]
  );

  useEffect(() => {
    setAdmin(null);
    const savedState = JSON.parse(localStorage.getItem("myState")) || {};
    const user_id = savedState.user_id;
    const org_id = savedState.org_id;
    const name = savedState.name;
    const active_orgs = savedState.active_orgs;

    fetchData(user_id, org_id, name, active_orgs);
  }, [orgId, fetchData]);

  const onOpenNav = useCallback(() => {
    setOpen(true);
  }, [setOpen]);

  const onCloseNav = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  return (
    <>
      <StyledRoot>
        <Header onOpenNav={onOpenNav} fullName={name} />

        <Nav
          open={open}
          onCloseNav={onCloseNav}
          admin={admin}
          name={name}
          activeOrgs={activeOrgs}
          setActiveOrgs={setActiveOrgs}
          orgId={orgId}
          setOrgId={setOrgId}
        />

        <Main>
          {admin !== null ? <Outlet setName={setName} {...params} /> : <></>}
        </Main>
      </StyledRoot>
    </>
  );
}
