import * as React from "react";
import {
  MenuItem,
  FormControl,
  ListItemText,
  Checkbox,
  Button,
  Tooltip,
  CircularProgress,
  Snackbar,
  Alert,
} from "@mui/material";
import { Lock } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import { fetchAndDownloadSessionData } from "../../utils/utilityFunctions";

const BpIcon = styled("span")(({ theme }) => ({
  borderRadius: 3,
  width: 16,
  height: 16,
  boxShadow:
    theme.palette.mode === "dark"
      ? "0 0 0 1px rgb(16 22 26 / 40%)"
      : "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
  backgroundColor: theme.palette.mode === "dark" ? "#394b59" : "#f5f8fa",
  backgroundImage:
    theme.palette.mode === "dark"
      ? "linear-gradient(180deg,hsla(0,0%,100%,.05),hsla(0,0%,100%,0))"
      : "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
  ".Mui-focusVisible &": {
    outline: "2px auto rgba(19,124,189,.6)",
    outlineOffset: 2,
  },
  "input:hover ~ &": {
    backgroundColor: theme.palette.mode === "dark" ? "#30404d" : "#ebf1f5",
  },
  "input:disabled ~ &": {
    boxShadow: "none",
    background:
      theme.palette.mode === "dark"
        ? "rgba(57,75,89,.5)"
        : "rgba(206,217,224,.5)",
  },
}));

const BpCheckedIcon = styled(BpIcon)({
  backgroundColor: "#137cbd",
  backgroundImage:
    "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
  "&::before": {
    display: "block",
    width: 16,
    height: 16,
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
      " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
      "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
    content: '""',
  },
  "input:hover ~ &": {
    backgroundColor: "#106ba3",
  },
});

// Inspired by blueprintjs
const BpCheckbox = (props) => {
  return (
    <Checkbox
      sx={{
        "&:hover": { bgcolor: "transparent" },
      }}
      disableRipple
      color="default"
      checkedIcon={<BpCheckedIcon />}
      icon={<BpIcon />}
      inputProps={{ "aria-label": "Checkbox demo" }}
      {...props}
    />
  );
};

export default function DownloadSelect({
  onClose,
  isTestSession,
  allTestSession,
  someTestSession,
  ids,
  userId,
  orgId,
  allValidSession,
}) {
  const [downloadable, setDownloadable] = React.useState([]);
  // Hiding option: Download Analyzed Data for testing activites, if want to show that but greyed out don't filter options below
  const options =
    isTestSession || allTestSession
      ? ["Download Raw Data", "Download Meta Data"]
      : ["Download Raw Data", "Download Analyzed Data", "Download Meta Data"];
  const [loading, setLoading] = React.useState(false);
  const [state, setState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
    msg: "",
  });
  const { vertical, horizontal } = state;

  const handleNonDownloadableTypesString = (val) => {
    if (val === "Download Raw Data") return "raw";
    if (val === "Download Analyzed Data") return "analyzed";
    return "metadata";
  };

  const handleDownloadClick = async () => {
    if (loading) return;
    setLoading(true);
    // setState({
    //   ...state,
    //   open: true,
    //   msg: "Analyzed data is not available for test activities",
    //   severity:"error"
    // });
    const type = downloadable
      .map((item) => handleNonDownloadableTypesString(item))
      ?.join(",");
    const response = await fetchAndDownloadSessionData(
      userId,
      orgId,
      ids,
      type
    );
    if (response) {
      setState({
        ...state,
        open: true,
        msg: "Download successful",
        severity: "success",
      });
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const handleChange = (val) => {
    if (isDownloadable(val)) {
      if (downloadable.includes(val))
        setDownloadable(downloadable.filter((item) => item != val));
      else setDownloadable([...downloadable, val]);
    }
  };

  const isDownloadable = (val) => {
    const expiryDetails = JSON.parse(localStorage.getItem("expiryDetails"));
    if (expiryDetails.days_left < 0) return false;
    if (val === "Download Analyzed Data")
      return (
        !isTestSession && !allTestSession && expiryDetails.download_analyzed
      );
    if (val === "Download Raw Data") return expiryDetails.download_raw;
    if (isTestSession || allTestSession) return expiryDetails.download_raw;
    return expiryDetails.download_analyzed || expiryDetails.download_raw;
  };

  const handleNonDownloadableMsg = (val) => {
    if (val === "Download Raw Data")
      return "You don't have the permission to download raw data";
    if (val === "Download Analyzed Data") {
      if (isTestSession || allTestSession)
        return "Analyzed data is not available for test activities";
      return "You don't have the permission to download analyzed data";
    }
    return "You don't have the permission to download meta data";
  };

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  return (
    <FormControl sx={{ m: 1, width: 210 }}>
      <Snackbar
        open={state.open}
        anchorOrigin={{ vertical, horizontal }}
        autoHideDuration={6000}
        key={vertical + horizontal}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity={state.severity} variant="filled">
          {state.msg}
        </Alert>
      </Snackbar>
      {options.map((option) => (
        <Tooltip
          title={isDownloadable(option) ? "" : handleNonDownloadableMsg(option)}
          arrow
          placement="top"
        >
          <span style={isDownloadable(option) ? {} : { cursor: "not-allowed" }}>
            <MenuItem
              key={option}
              onClick={() => handleChange(option)}
              disabled={!isDownloadable(option)}
            >
              {isDownloadable(option) ? (
                <BpCheckbox checked={downloadable.includes(option)} />
              ) : (
                <Lock />
              )}
              <ListItemText primary={option} />
            </MenuItem>
          </span>
        </Tooltip>
      ))}
      <span>
        <span style={!downloadable.length ? { cursor: "not-allowed" } : {}}>
          <Button
            sx={{ m: 1 }}
            onClick={() => handleDownloadClick()}
            variant="contained"
            disabled={!downloadable.length}
          >
            {loading ? (
              <CircularProgress size={22} color="inherit" />
            ) : (
              "Download"
            )}
          </Button>
        </span>
        {"  "}
        <span>
          <Button
            sx={{ m: 1 }}
            onClick={() => {
              onClose();
            }}
            variant="contained"
            color="error"
          >
            {loading || !isDownloadable("Download Meta Data")
              ? "Close"
              : "Cancel"}
          </Button>
        </span>
      </span>
    </FormControl>
  );
}
