import React, { useState } from "react";
// @mui
import {
  Box,
  Typography,
  Stack,
  Modal,
  Button,
  IconButton,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// @mui-icons
import CloseIcon from "@mui/icons-material/Close";
// API
import { flagUnflagSession } from "../../api/userRoutes";
// Utils
import { whiteButtonStyle, editAthleteModalStyle } from "../../utils/styles";
// import { withTimingAndLogging } from '../../utils/logFunctions/logs';

export default function FlagUnflagModal({
  handleCloseModal,
  openModal,
  data: { sessionId, user, flagged, orgId },
  setCount,
  handleOpenSnackBar,
}) {
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const params = { athleteId: user, orgId, sessionId, flagged };
      const response = await flagUnflagSession("Flag-Unflag session.", params);
      const { data, statusCode } = response;
      if (statusCode === 200) {
        setCount((prevCount) => prevCount + 1);
        handleOpenSnackBar();
        closeModal();
      }
    } catch (error) {}
    setLoading(false);
  };

  const closeModal = () => {
    handleCloseModal();
  };

  return (
    <Modal
      open={openModal}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
    >
      <Box
        gap={1}
        sx={{
          ...editAthleteModalStyle,
          maxHeight: "95vh",
        }}
      >
        <Stack direction="row" justifyContent="space-between" mb={1}>
          <Typography id="modal-modal-title" variant="h6" whiteSpace="nowrap">
            Do you want to mark this session {flagged ? "unflagged" : "flagged"}
            ?
          </Typography>
          <IconButton
            aria-label="close"
            onClick={closeModal}
            sx={{ mt: -8, mr: -4 }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <Stack mt={2} direction="row" spacing={1} justifyContent="center">
          <LoadingButton
            variant="contained"
            sx={{ width: "7rem" }}
            disableRipple
            loading={loading}
            textAlign="center"
            onClick={handleSubmit}
          >
            Yes
          </LoadingButton>

          <Button
            sx={{ width: "7rem", ...whiteButtonStyle }}
            onClick={closeModal}
            disableRipple
            disabled={loading}
          >
            No
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
}
