import axios from "axios";
import { datadogLogs } from "@datadog/browser-logs";

let interceptor;

const savedState = localStorage.getItem("myState");
const parsedState = JSON.parse(savedState);

// Logging for DataDog
// -----------------------------
export function logInfoFunctionCall(message, admin, latency, funcName, params) {
  const logInfoParams = {
    admin: parsedState?.admin,
    latency: latency,
    current_user_id: parsedState?.user_id,
    current_org_id: parsedState?.org_id,
    function_called: funcName,
    params: params,
  };

  logInfo(message, logInfoParams);
}

export function logErrorFunctionCall(message, latency, funcName, params) {
  const logInfoParams = {
    admin: parsedState?.admin,
    latency: latency,
    current_user_id: parsedState?.user_id,
    current_org_id: parsedState?.org_id,
    function_called: funcName,
    params: params,
  };

  logError(message, logInfoParams);
}

const api = async ({
  path,
  body = {},
  method = "GET",
  timeout = 30000,
  isTimeoutEnabled = true,
  isFormData = false,
  enableDatadog = true,
  datadogMsg = "",
  funcName = "",
  admin = false,
  params,
}) => {
  axios.interceptors.response.eject(interceptor);

  const source = axios.CancelToken.source();

  if (isTimeoutEnabled) {
    setTimeout(() => {
      source.cancel("Cancelling after 30000ms");
    }, timeout);
  }

  let headers = {
    // "content-type": "application/json",
    Authorization: "",
  };

  if (isFormData) {
    headers["content-type"] = "multipart/form-data";
  }

  const options = {
    url: path,
    method: method,
    headers,
    data: isFormData ? body : { ...body },
    cancelToken: source.token,
  };

  const startTime = Date.now();

  try {
    // window.GlobalFunctions.consoleLog(options, "==request");
    //console.log(options, "==request");
    const response = await axios(options);
    const statusCode = response.status;
    // window.GlobalFunctions.consoleLog(response, "==response");
    // console.log(response, "==response");

    if (enableDatadog) {
      const endTime = Date.now();
      const elapsedTime = (endTime - startTime) / 1000;
      logInfoFunctionCall(datadogMsg, admin, elapsedTime, funcName, params);
    }

    return { data: response.data, statusCode };
  } catch (error) {
    if (enableDatadog) {
      const endTime = Date.now();
      const elapsedTime = (endTime - startTime) / 1000;
      logErrorFunctionCall(
        `Error in function ${funcName}`,
        admin,
        elapsedTime,
        funcName,
        params
      );
    }

    // to fetch data coming with 40 & 404 status code
    if (
      (error.response.status == 400 || error.response.status == 404) &&
      error.response.hasOwnProperty("data")
    ) {
      return { data: error.response.data, statusCode: error.response.status };
    }

    // Return a res instead of rej because for example with logging in - the loading button will spin infinitely since it isnt receiving a failed status code
    return new Promise((res, rej) => {
      rej(error);
    });
  } finally {
    axios.interceptors.response.eject(interceptor);
  }
};
export default api;

export const logError = (errorMessage, errorDetails) => {
  datadogLogs.logger.error(errorMessage, {
    service: "nextiles.web.v1",
    env: process.env.STAGE,
    details: errorDetails,
  });
};

export const logInfo = (infoMessage, infoDetails) => {
  datadogLogs.logger.info(infoMessage, {
    service: "nextiles.web.v1",
    env: process.env.STAGE,
    details: infoDetails,
  });
};
