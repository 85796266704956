import { useState } from 'react';
import { useNavigate } from 'react-router-dom'
// @mui
import { alpha } from '@mui/material/styles';
import { 
  MenuItem, 
  Avatar, 
  IconButton, 
  Popover, 
  Divider,
  Stack,
  Typography
} from '@mui/material';
// ----------------------------------------------------------------------

export default function AccountPopover({ fullName }) {
  const [open, setOpen] = useState(null);
  const navigate = useNavigate()

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const logout = () => {
    localStorage.clear();
    setOpen(null);
    navigate('/login')
  }

  if (fullName) {

    let words = fullName.split(' ');
  
    let capitalizeFirstLetter = (word) => word.charAt(0).toUpperCase() + word.slice(1);
    
    let firstName = capitalizeFirstLetter(words[0]);
    let lastName = capitalizeFirstLetter(words[1]);
    
    let firstInitial = firstName.charAt(0);
    let secondInitial = lastName.charAt(0);
  
    return (
      <>
        <IconButton
          onClick={handleOpen}
          sx={{
            p: 0,
            ...(open && {
              '&:before': {
                zIndex: 1,
                content: "''",
                width: '100%',
                height: '100%',
                position: 'absolute',
                bgcolor: (theme) => alpha(theme.palette.grey[900], 0.1),
              },
            }),
          }}
        >
          <Avatar 
            mt={.2} 
            mr={2} 
            sx={{ 
              bgcolor: "primary.light", 
              color: "#212021", 
              border: "1px solid #FFC04D", 
              fontFamily: "Nasalization" 
            }} 
            variant="rounded"
          >
            {firstInitial}{secondInitial}
          </Avatar>
        </IconButton>
  
        <Popover
          open={Boolean(open)}
          anchorEl={open}
          onClose={handleClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          PaperProps={{
            sx: {
              p: 0,
              mt: 1.5,
              ml: 0.75,
              width: 180,
              '& .MuiMenuItem-root': {
                typography: 'body2',
                borderRadius: 0.75,
              },
            },
          }}
        >
          <Stack p={1}>

            <Stack flexDirection={"row"} justifyContent={"flex-start"} mb={2} ml={1} spacing={1}>
              <Avatar 
                sx={{ 
                  bgcolor: "primary.light", 
                  color: "#212021", 
                  border: "1px solid #FFC04D", 
                  fontFamily: "Nasalization",
                }} 
                variant="rounded"
              >
                {firstInitial}{secondInitial}
              </Avatar>
              <Typography variant='body1' fontSize={16} pl={1}>
                {firstName} {lastName}
              </Typography>
            </Stack>

              <MenuItem onClick={()=> {navigate("/dashboard/account")}}>
                My Account
              </MenuItem>

            <Divider/>

            <MenuItem onClick={logout} >
              <Typography color={"error.main"}>
                Logout
              </Typography>
            </MenuItem>
            
          </Stack>
        </Popover>
      </>
    );
  }
}