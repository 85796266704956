// ----------------------------------------------------------------------

export default function Card(theme) {
    return {
      MuiCard: {
        styleOverrides: {
          root: {
            boxShadow: '0px 0px 0.5px rgba(66, 71, 76, 0.32), 0px .4rem .8rem rgba(66, 71, 76, 0.05), 0px .4rem 4rem #EEEEEE',
            borderRadius: ".4rem",
            position: 'relative',
            zIndex: 0,
          },
        },
      },
      MuiCardHeader: {
        defaultProps: {
          titleTypographyProps: {  fontWeight: 700, variant: 'caption',},
          subheaderTypographyProps: { variant: 'body1' },
        },
        styleOverrides: {
          root: {
            padding: theme.spacing(3, 3, 0),
          },
        },
      },
    };
  }
  