import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { Box, Stack, AppBar, Toolbar, IconButton } from '@mui/material';
import { useScrollTrigger, Slide } from '@mui/material';
// @mui-icons
import MenuIcon from '@mui/icons-material/Menu';
// Components
import AccountPopover from './AccountPopover';
// ----------------------------------------------------------------------

const NAV_WIDTH = "15rem";

const HEADER_MOBILE = 64;

const StyledRoot = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backgroundColor: 'transparent',
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${NAV_WIDTH + 1}px)`,
  },
}));

const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  minHeight: HEADER_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: HEADER_MOBILE,
  },
}));

// ----------------------------------------------------------------------

Header.propTypes = {
  onOpenNav: PropTypes.func,
  fullName: PropTypes.string,
};

export default function Header({ onOpenNav, fullName }) {

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      <StyledRoot>
        <StyledToolbar flexDirection="row" sx={{ justifyContent: "space-between", alignItems: "center"}}>
          <IconButton
            onClick={onOpenNav}
            sx={{
              mr: 1,
              color: 'text.primary',
              display: { lg: 'none' },
            }}>    
            <MenuIcon/>
          </IconButton>
          <Box sx={{ flexGrow: 1 }} />

          <Stack
            direction="row"
            alignItems="center"
            spacing={{
              xs: 0.5,
              sm: 1,
            }}
          >
            <AccountPopover fullName={fullName}/>
          </Stack>
        </StyledToolbar>
      </StyledRoot>
    </Slide>
  );
}
