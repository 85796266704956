import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// @mui
import {
  Container,
  Modal,
  Box,
  Typography,
  Stack,
  Divider,
  MenuItem,
  Button,
  FormControl,
  InputLabel,
  Grid,
  Select,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// APIs
import { updateUserMetadata } from "../../api/authRoutes";
// Utils
import { authModalStyles } from "../../utils/styles";
import { toISOLocal } from "../../utils/utilityFunctions";
// import { withTimingAndLogging } from '../../utils/logFunctions/logs';
import {
  heights,
  weights,
  cms,
  kgs,
  convertHeight,
  convertWeight,
  feetConversion,
  isUpdateFormValid,
} from "../../utils/authData";
// ----------------------------------------------------------------------

export default function CompleteRegistration() {
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [userId, setUserId] = useState(null);
  const [successMessage, setSuccessMessage] = useState("");
  const [formData, setFormData] = useState({
    gender: "",
    height: "",
    heightMetric: "",
    weight: "",
    weightMetric: "",
    dominantLeg: "",
    dominantArm: "",
  });
  const [disabled, setDisabled] = useState(true);
  const [fieldErrors, setFieldErrors] = useState({
    gender: true,
    height: true,
    heightMetric: true,
    weight: true,
    weightMetric: true,
    dominantLeg: true,
    dominantArm: true,
  });

  useEffect(() => {
    if (location.state) {
      setUserId(location.state.userId);
      let heightMetric = null,
        heightValue = null;
      let weightMetric = null,
        weightValue = null;
      if (location.state.userMetaData.height) {
        if (
          location.state.userMetaData.height.value &&
          location.state.userMetaData.height.unit
        ) {
          const { unit, value } = location.state.userMetaData.height;
          heightMetric = unit === "in" ? "in" : "cm";
          heightValue =
            unit === "in"
              ? `${Math.trunc(value / 12)} feet ${value % 12} inches`
              : value;
        }
      }

      if (location.state.userMetaData.weight) {
        if (
          location.state.userMetaData.weight.unit &&
          location.state.userMetaData.weight.unit
        ) {
          const { unit, value } = location.state.userMetaData.weight;
          weightMetric = unit;
          weightValue = value;
        }
      }
      const combinedState = {
        emailValue: location.state.emailValue,
        firstName: location.state.firstName,
        lastName: location.state.lastName,
        dateOfBirth: location.state.dateOfBirth,
        country: location.state.country,
        gender: location.state.userMetaData.gender || null, // Default to 'male' if not available
        height: heightValue,
        heightMetric: heightMetric,
        weight: weightValue,
        weightMetric: weightMetric,
        dominantLeg: location.state.userMetaData.dominant_leg,
        dominantArm: location.state.userMetaData.dominant_arm,
      };
      setFormData(combinedState);

      for (const key in combinedState) {
        if (combinedState[key] !== null) {
          setFieldErrors((prevFieldErrors) => ({
            ...prevFieldErrors,
            [key]: fieldErrors[key] === false,
          }));
        }
      }
    }
  }, []);

  const {
    emailValue,
    firstName,
    lastName,
    dateOfBirth,
    heightMetric,
    height,
    weight,
    weightMetric,
    dominantArm,
    dominantLeg,
    country,
    gender,
  } = formData;

  const handleFormDataChange = (e) => {
    const { name, value } = e.target;

    if (name === "heightMetric") {
      const convertedHeight = convertHeight(value, formData.height);
      setFormData({
        ...formData,
        [name]: value,
        height: convertedHeight,
      });
      setFieldErrors({
        ...fieldErrors,
        [name]: false,
        heightMetric: false,
      });
    } else if (name === "weightMetric") {
      const convertedWeight = convertWeight(value, formData.weight);
      setFormData({
        ...formData,
        [name]: value,
        weight: convertedWeight,
      });
      setFieldErrors({
        ...fieldErrors,
        [name]: false,
        weightMetric: false,
      });
    } else if (name === "height" && heightMetric === null) {
      setFormData({
        ...formData,
        [name]: value,
        heightMetric: "in",
      });
      setFieldErrors({
        ...fieldErrors,
        [name]: false,
        heightMetric: false,
      });
    } else if (name === "weight" && weightMetric === null) {
      setFormData({
        ...formData,
        [name]: value,
        weightMetric: "lb",
      });
      setFieldErrors({
        ...fieldErrors,
        [name]: false,
        weightMetric: false,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
      setFieldErrors({
        ...fieldErrors,
        [name]: false,
      });
    }

    if (name === "height" && heightMetric === null) {
      const isFormFieldsFilled = isUpdateFormValid({
        ...fieldErrors,
        [name]: false,
        heightMetric: false,
      });
      if (isFormFieldsFilled) {
        setDisabled(false);
      }
    }

    if (name === "weight" && weightMetric === null) {
      const isFormFieldsFilled = isUpdateFormValid({
        ...fieldErrors,
        [name]: false,
        weightMetric: false,
      });
      if (isFormFieldsFilled) {
        setDisabled(false);
      }
    }

    const isFormFieldsFilled = isUpdateFormValid({
      ...fieldErrors,
      [name]: false,
    });
    if (isFormFieldsFilled) {
      setDisabled(false);
    }
  };

  const submitAccount = async () => {
    setLoading(true);

    const date = toISOLocal(new Date());
    const newInches =
      heightMetric === "in" && height !== "0" ? feetConversion(height) : height;
    const newGender = gender === "Prefer Not to Say" || "" ? null : gender;

    const userMetaData = {
      userId: userId,
      firstName: firstName,
      lastName: lastName,
      emailValue: emailValue,
      date: date,
      dateOfBirth: dateOfBirth,
      height: newInches,
      heightMetric: heightMetric,
      weight: weight,
      weightMetric: weightMetric,
      domarm: dominantArm,
      domleg: dominantLeg,
      country: country,
      gender: newGender,
    };

    const fetchFunction = () => updateUserMetadata(userMetaData);
    const funcName = `updateUserMetadata`;
    const params = { user_metadata: userMetaData };
    // const response = await withTimingAndLogging(fetchFunction, 'Updating User MetaData with missing fields.', false, funcName, params);
    const response = await updateUserMetadata(
      userMetaData,
      "Updating User MetaData with missing fields.",
      params
    );
    const statusCode = response.statusCode;
    const data = response.data;

    if (statusCode === 200) {
      setLoading(false);
      setOpen(true);
      setSuccessMessage(data.data.message);
    } else {
      setLoading(false);
      setMessage(data.data.message);
    }
  };

  return (
    <Container maxWidth="xl" ml={0}>
      <Stack sx={{ alignItems: "center" }} height={"100vh"}>
        <Typography variant="h4" mb={2} mt={5}>
          {" "}
          Getting Started
        </Typography>

        <Grid
          container
          component="form"
          justifyContent="center"
          sx={{ width: "85%" }}
          rowSpacing={1}
          columnSpacing={2}
        >
          <Grid item xs={12}>
            <Typography variant="body1" mb={1}>
              What's your gender?
            </Typography>
            <FormControl fullWidth>
              <InputLabel id="gender">Gender</InputLabel>
              <Select
                value={gender}
                fullWidth
                labelId="gender"
                error={fieldErrors.gender}
                label="Gender"
                name="gender"
                placeholder="Gender"
                variant="outlined"
                onChange={handleFormDataChange}
              >
                <MenuItem disableRipple key={0} value={"male"}>
                  Male
                </MenuItem>
                <MenuItem disableRipple key={1} value={"female"}>
                  Female
                </MenuItem>
                <MenuItem disableRipple key={2} value={"Prefer Not to Say"}>
                  Prefer Not To Say
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <Typography variant="body1" mb={1} mt={1.5}>
              What's your height?
            </Typography>
            <FormControl fullWidth>
              <InputLabel>Height</InputLabel>
              <Select
                value={height}
                name="height"
                label="Height"
                fullWidth
                error={fieldErrors.height}
                variant="outlined"
                onChange={handleFormDataChange}
              >
                {heightMetric === "cm"
                  ? cms.map((height, index) => {
                      return (
                        <MenuItem disableRipple key={index} value={height}>
                          {" "}
                          {height}{" "}
                        </MenuItem>
                      );
                    })
                  : heights.map((height, index) => {
                      return (
                        <MenuItem disableRipple key={index} value={height}>
                          {" "}
                          {height}{" "}
                        </MenuItem>
                      );
                    })}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <Typography variant="body1" mb={1} mt={1.5}>
              Metric
            </Typography>

            <FormControl fullWidth>
              <InputLabel>Height Unit</InputLabel>
              <Select
                value={heightMetric}
                name="heightMetric"
                error={fieldErrors.heightMetric}
                variant="outlined"
                fullWidth
                label="Height Unit"
                onChange={handleFormDataChange}
              >
                <MenuItem disableRipple key={heightMetric} value={"in"}>
                  ft
                </MenuItem>
                <MenuItem disableRipple key={heightMetric} value={"cm"}>
                  cm
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <Typography variant="body1" mb={1} mt={1.5}>
              What's your weight?
            </Typography>

            <FormControl fullWidth>
              <InputLabel>Weight</InputLabel>
              <Select
                value={weight}
                name="weight"
                label="Weight"
                error={fieldErrors.weight}
                variant="outlined"
                onChange={handleFormDataChange}
                fullWidth
              >
                {weightMetric === "kg"
                  ? kgs.map((weight, index) => {
                      return (
                        <MenuItem disableRipple key={index} value={weight}>
                          {" "}
                          {weight}{" "}
                        </MenuItem>
                      );
                    })
                  : weights.map((weight, index) => {
                      return (
                        <MenuItem disableRipple key={index} value={weight}>
                          {" "}
                          {weight}{" "}
                        </MenuItem>
                      );
                    })}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <Typography variant="body1" mb={1} mt={1.5}>
              Metric
            </Typography>

            <FormControl fullWidth>
              <InputLabel>Weight Unit</InputLabel>

              <Select
                name="weightMetric"
                label="Weight Unit"
                value={weightMetric}
                error={fieldErrors.weightMetric}
                variant="outlined"
                fullWidth
                onChange={handleFormDataChange}
              >
                <MenuItem disableRipple key={weightMetric} value={"lb"}>
                  lbs
                </MenuItem>
                <MenuItem disableRipple key={weightMetric} value={"kg"}>
                  kgs
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <Typography variant="body1" mb={1} mt={1.5}>
              Which leg are you dominant in?
            </Typography>

            <FormControl fullWidth>
              <InputLabel>Dominant Leg </InputLabel>
              <Select
                value={dominantLeg}
                error={fieldErrors.dominantLeg}
                name="dominantLeg"
                label="Dominant Leg"
                variant="outlined"
                onChange={handleFormDataChange}
                fullWidth
              >
                <MenuItem disableRipple value={"right"} key={1}>
                  Right
                </MenuItem>
                <MenuItem disableRipple value={"left"} key={2}>
                  Left
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6}>
            <Typography variant="body1" mb={1} mt={1.5}>
              Which arm are you dominant in?
            </Typography>

            <FormControl fullWidth>
              <InputLabel>Dominant Arm</InputLabel>
              <Select
                name="dominantArm"
                value={dominantArm}
                error={fieldErrors.dominantArm}
                variant="outlined"
                label="Dominant Arm"
                onChange={handleFormDataChange}
                fullWidth
              >
                <MenuItem disableRipple value={"right"}>
                  Right
                </MenuItem>
                <MenuItem disableRipple value={"left"}>
                  Left
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="body1"
              mt={2}
              mb={2}
              color={"error"}
              fontSize={16}
            >
              {message}
            </Typography>
          </Grid>

          <Grid item xs={12} sx={{ textAlign: "center" }}>
            <LoadingButton
              onClick={submitAccount}
              size="md"
              disabled={disabled}
              type="submit"
              disableRipple
              sx={{ boxShadow: "none" }}
              variant="contained"
              loading={loading}
            >
              Complete
            </LoadingButton>
          </Grid>
        </Grid>
      </Stack>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box sx={authModalStyles}>
          <Stack spacing={2} m={1} alignItems="center">
            <Typography variant="h6">Success!</Typography>
            <Divider />
            <Typography fontSize={16}>{successMessage}</Typography>
            <Button
              variant="contained"
              href="/dashboard/home"
              sx={{ width: "50%" }}
            >
              Login
            </Button>
          </Stack>
        </Box>
      </Modal>
    </Container>
  );
}
