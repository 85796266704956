import * as React from 'react';
import {
    Box, 
    Divider,
    Modal, 
    Stack, 
    Link, 
    Typography, 
    IconButton,
} from '@mui/material/';
// @mui-icons
import CloseIcon from '@mui/icons-material/Close';

export default function SettingsModal({openModal, handleCloseModal}) {
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "50%",
        bgcolor: 'background.paper',
        borderRadius: 1,
        myTextStyle: {
            textDecoration: 'none',
            '&:hover': {
             color: 'white'
          }
       }
    };

    return (
        <Modal
        open={openModal}
        hideBackdrop={false}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Box sx={style} >
            <Stack direction="row" justifyContent="space-between" alignItems="center"  mb={1} p={4}>
                <Typography id="modal-modal-title" variant="h4" fontFamily={"Nasalization"} >
                    System Preferences
                </Typography>
                <IconButton
                    aria-label="close"
                    onClick={handleCloseModal}
                >
                        <CloseIcon />
                </IconButton>
            </Stack>
            <Divider/>

            <Stack mt={1} mb={4}>
                <Typography variant="body1" fontSize={"1.3rem"} pl={4} pr={4} mb={1} mt={2}>Ongoing Maintenance</Typography>
                <Typography pl={4} pr={4}> Web app undergoes regular updates, please routinely logout and log back in to see changes.</Typography>
            </Stack>
            <Stack mt={1} mb={4}>
                <Typography variant="body1" fontSize={"1.3rem"} pl={4} pr={4} mb={1}>Questions</Typography>
                <Typography pl={4} pr={4}> All questions about{' '}
                    <Link href="https://www.nextiles.tech/" rel="noopener">Nextiles</Link>  
                    {' '}and its products can be directed to {' '}
                    <Link
                        href='#'
                        onClick={(e) => {
                            window.location.href = "mailto:business@nextiles.tech";
                            e.preventDefault();
                        }}
                    >
                    business@nextiles.tech.
                    </Link>
                </Typography>
            </Stack>
        </Box>
    </Modal> 
    )
}