import React, { useEffect, useState } from "react";
// @mui
import {
  Box,
  IconButton,
  Typography,
  Stack,
  Avatar,
  Modal,
  Divider,
  Slider,
  MenuItem,
  Button,
  Grid,
  TextField,
  Select,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// @mui-icons
import CloseIcon from "@mui/icons-material/Close";
// APIs
import { updateUserMetadata } from "../../api/authRoutes";
import {
  updateUserAdminSettings,
  fetchUserOrgMetadata,
} from "../../api/userRoutes";
// Utils
import { whiteButtonStyle, editAthleteModalStyle } from "../../utils/styles";
import { toISOLocal } from "../../utils/utilityFunctions";
// import { withTimingAndLogging } from '../../utils/logFunctions/logs';
import {
  heights,
  cms,
  generateWeightMenuItems,
  feetConversion,
} from "../../utils/authData";
// ----------------------------------------------------------------------

export default function EditAthleteProfile({
  handleCloseModal,
  openModal,
  setUpdateUserData,
  orgId,
  userId,
}) {
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState("");
  const [value, setValue] = useState();
  const [notes, setNotes] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [userMetaData, setUserMetaData] = useState({});
  const [weight, setWeight] = useState("");
  const [weightMetric, setWeightMetric] = useState("");
  const [height, setHeight] = useState("");
  const [heightMetric, setHeightMetric] = useState("");
  const [dominantArm, setDominantArm] = useState("");
  const [dominantLeg, setDominantLeg] = useState("");
  const [loading, setLoading] = useState(false);
  const states = ["Active", "Risk of Injury", "Injured", "Inactive"];

  const handleSliderChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    async function fetchData() {
      const params = { user_id: userId, org_id: orgId };
      // const response = await withTimingAndLogging(fetchFunction, 'Fetching athlete metadata for edit athlete profile modal.', false, funcName, params);
      const response = await fetchUserOrgMetadata(
        userId,
        orgId,
        "Fetching athlete metadata for edit athlete profile modal.",
        params
      );
      const data = response.data;

      setEmail(data.userMetaData.email);
      const firstName =
        data.userMetaData.first_name ||
        (data.userMetaData.name ? data.userMetaData.name.split(" ")[0] : "");
      const lastName =
        data.userMetaData.last_name ||
        (data.userMetaData.name ? data.userMetaData.name.split(" ")[1] : "");
      setFirstName(firstName);
      setLastName(lastName);
      setUserMetaData(data.userMetaData?.user_metadata);
      setName(data.userMetaData.name);
      setStatus(data.userOrgMetaData.admin_settings?.status);
      setValue(data.userOrgMetaData.admin_settings?.level);
      setNotes(data.userOrgMetaData.admin_settings?.notes);

      if (data.userMetaData.user_metadata.height) {
        const { unit, value } = data.userMetaData.user_metadata.height;
        const heightMetric = unit || "";
        const heightValue =
          unit === "in"
            ? `${Math.trunc(value / 12)} feet ${value % 12} inches`
            : value || "";

        setHeightMetric(heightMetric);
        setHeight(heightValue);
      } else {
        setHeight("");
        setHeightMetric("");
      }

      if (data.userMetaData.user_metadata.weight) {
        setWeight(data.userMetaData.user_metadata.weight.value || "");
        setWeightMetric(data.userMetaData.user_metadata.weight.unit || "");
      } else {
        setWeight("");
        setWeightMetric("");
      }
      setDominantArm(data.userMetaData.user_metadata?.dominant_arm || "");
      setDominantLeg(data.userMetaData.user_metadata?.dominant_leg || "");
    }

    if (userId) {
      fetchData(userId, orgId);
    }
  }, [userId]);

  const handleSubmit = async () => {
    setLoading(true);
    const date = toISOLocal(new Date());

    const adminSettings = {
      userId,
      orgId,
      level: value,
      status,
      date: date,
      notes,
    };

    const newHeight =
      heightMetric === "in" && height !== "0" ? feetConversion(height) : height;

    const userData = {
      userId: userId,
      firstName: firstName,
      lastName: lastName,
      emailValue: email,
      date: date,
      dateOfBirth: userMetaData?.dob,
      height: newHeight,
      heightMetric: heightMetric,
      weight: weight,
      weightMetric: weightMetric,
      domarm: dominantArm,
      domleg: dominantLeg,
      country: userMetaData?.country,
      gender: userMetaData?.gender,
    };

    // const [userMetadataResponse, adminSettingsResponse, orgUsersResponse] = await Promise.all([
    //     withTimingAndLogging(() => updateUserMetadata(userData), 'Updating user metadata.', false, 'updateUserMetadata', { user_data: userData }),
    //     withTimingAndLogging(() => updateUserAdminSettings(adminSettings), 'Updating admin settings.', false, 'updateUserAdminSettings', { admin_settings: adminSettings }),
    //     withTimingAndLogging(() => fetchAllOrgUsersInOrg(orgId), 'Fetching all organization users.', false, 'fetchAllOrgUsersInOrg', { org_id: orgId }),
    // ]);

    const [userMetadataResponse, adminSettingsResponse] = await Promise.all([
      updateUserMetadata(userData, "Updating user metadata.", {
        user_data: userData,
      }),
      updateUserAdminSettings(adminSettings, "Updating admin settings.", {
        admin_settings: adminSettings,
      }),
    ]);

    const { statusCode: userStatusCode } = userMetadataResponse;
    const { statusCode, data } = adminSettingsResponse;

    if (
      (statusCode === 200 && userStatusCode === 200) ||
      data?.data?.message == "User organization profile successfully updated!"
    ) {
      setUpdateUserData((prevCount) => !prevCount);
      setLoading(false);
      handleCloseModal();
    } else {
      setLoading(false);
      setMessage("Unsuccesful attempt. Please try again!");
    }
  };

  const closeModal = () => {
    handleCloseModal();
  };

  return (
    <Modal
      open={openModal}
      onClose={closeModal}
      aria-labelledby="modal-modal-title"
    >
      <Box
        gap={1}
        sx={{
          ...editAthleteModalStyle,
          overflowY: "scroll",
          maxHeight: "95vh",
        }}
      >
        {/* Should be a grid */}
        <Stack direction="row" justifyContent="space-between" mb={1}>
          <Typography id="modal-modal-title" variant="h4" whiteSpace="nowrap">
            Edit Profile
          </Typography>
          <IconButton aria-label="close" onClick={handleCloseModal}>
            <CloseIcon />
          </IconButton>
        </Stack>

        <Divider />

        <Stack
          direction="row"
          justifyContent="space-between"
          spacing={2}
          mt={2}
        >
          <Stack sx={{ width: "20%" }}>
            <Avatar sx={{ width: 90, height: 90 }} alt={`${name}`} />
          </Stack>

          <Stack sx={{ width: "75%" }}>
            <Typography variant="h6">{name}</Typography>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
            >
              <Stack sx={{ width: "50%" }} spacing={1} mt={1}>
                <Typography variant="body1" color="rgba(158, 158, 158, 1)">
                  Height
                </Typography>

                <Grid container alignItems="flex-end" columnGap={1}>
                  <Grid item xs={8}>
                    <Select
                      value={height}
                      fullWidth
                      variant="standard"
                      onChange={(e) => {
                        setHeight(e.target.value);
                      }}
                    >
                      {heightMetric === "in"
                        ? heights.map((height, index) => {
                            return (
                              <MenuItem
                                disableRipple
                                key={index}
                                value={height}
                              >
                                {" "}
                                {height}{" "}
                              </MenuItem>
                            );
                          })
                        : cms.map((height, index) => {
                            return (
                              <MenuItem
                                disableRipple
                                key={index}
                                value={height}
                              >
                                {" "}
                                {height}{" "}
                              </MenuItem>
                            );
                          })}
                    </Select>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography variant="body1">{heightMetric}</Typography>
                  </Grid>
                </Grid>
              </Stack>

              <Stack sx={{ width: "50%" }} spacing={1} mt={1}>
                <Typography
                  variant="body1"
                  color="rgba(158, 158, 158, 1)"
                  sx={{ whiteSpace: "nowrap" }}
                >
                  Dominant Leg
                </Typography>

                <Select
                  value={dominantLeg}
                  fullWidth
                  variant="standard"
                  onChange={(e) => {
                    setDominantLeg(e.target.value);
                  }}
                >
                  <MenuItem disableRipple key={0} value={"right"}>
                    Right
                  </MenuItem>
                  <MenuItem disableRipple key={1} value={"left"}>
                    Left
                  </MenuItem>
                </Select>
              </Stack>
            </Stack>

            <Stack direction="row" alignItems="center">
              <Stack sx={{ width: "50%" }} spacing={1} mt={1}>
                <Typography variant="body1" color="rgba(158, 158, 158, 1)">
                  Weight
                </Typography>
                <Grid container alignItems="flex-end" columnGap={1}>
                  <Grid item xs={8}>
                    <Select
                      value={weight}
                      fullWidth
                      variant="standard"
                      onChange={(e) => {
                        setWeight(e.target.value);
                      }}
                    >
                      {generateWeightMenuItems(weightMetric)}
                    </Select>
                  </Grid>

                  <Grid item xs={3}>
                    <Typography variant="body1">
                      {weightMetric && <>{`${weightMetric}s`}</>}
                    </Typography>
                  </Grid>
                </Grid>
              </Stack>

              <Stack sx={{ width: "50%" }} spacing={1} mt={1}>
                <Typography
                  variant="body1"
                  color="rgba(158, 158, 158, 1)"
                  sx={{ whiteSpace: "nowrap" }}
                >
                  Dominant Arm
                </Typography>
                <Select
                  value={dominantArm}
                  fullWidth
                  variant="standard"
                  onChange={(e) => {
                    setDominantArm(e.target.value);
                  }}
                >
                  <MenuItem disableRipple key={0} value={"right"}>
                    Right
                  </MenuItem>
                  <MenuItem disableRipple key={1} value={"left"}>
                    Left
                  </MenuItem>
                </Select>
              </Stack>
            </Stack>

            <Stack sx={{ width: "100%" }}>
              <Stack mt={2}>
                <Typography sx={{ width: "30%" }}>Status</Typography>
                <Select
                  value={status}
                  variant="standard"
                  fullWidth
                  label="Status"
                  onChange={(e) => {
                    setStatus(e.target.value);
                  }}
                >
                  {states.map((state, index) => (
                    <MenuItem key={index} value={state}>
                      {state}
                    </MenuItem>
                  ))}
                </Select>
              </Stack>

              <Stack mt={2} sx={{ width: "100%" }}>
                <Typography>Level of Athlete</Typography>
                <Stack
                  direction="row"
                  spacing={2}
                  justifyContent="space-between"
                >
                  <Slider
                    sx={
                      value < 30
                        ? {
                            "& .MuiSlider-thumb": {
                              color: "red",
                            },
                            "& .MuiSlider-track": {
                              background: "red",
                            },
                            "& .MuiSlider-rail": {
                              background: "red",
                            },
                            "& .MuiSlider-active": {
                              color: "green",
                            },
                          }
                        : value < 50
                        ? {
                            "& .MuiSlider-thumb": {
                              color: "rgba(236, 108, 1, 1)",
                            },
                            "& .MuiSlider-track": {
                              background: "rgba(236, 108, 1, 1)",
                            },
                            "& .MuiSlider-rail": {
                              background: "rgba(236, 108, 1, 1)",
                            },
                            "& .MuiSlider-active": {
                              color: "green",
                            },
                          }
                        : value < 75
                        ? {
                            "& .MuiSlider-thumb": {
                              color: "#EC6C01",
                            },
                            "& .MuiSlider-track": {
                              background: "#EC6C01",
                            },
                            "& .MuiSlider-rail": {
                              background: "#EC6C01",
                            },
                            "& .MuiSlider-active": {
                              color: "green",
                            },
                          }
                        : {
                            "& .MuiSlider-thumb": {
                              color: "green",
                            },
                            "& .MuiSlider-track": {
                              background: "green",
                            },
                            "& .MuiSlider-rail": {
                              background: "green",
                            },
                            "& .MuiSlider-active": {
                              color: "green",
                            },
                          }
                    }
                    size="small"
                    onChange={handleSliderChange}
                    value={value}
                    defaultValue={value}
                    aria-labelledby="input-slider"
                  />
                  <Typography sx={{ width: "10%", whiteSpace: "nowrap" }}>
                    {value} %
                  </Typography>
                </Stack>
              </Stack>

              <Stack mt={2} mb={2}>
                <TextField
                  id="text"
                  margin="normal"
                  fullWidth
                  label="Notes"
                  placeholder="Add notes here"
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                />
              </Stack>

              <Typography>{message}</Typography>
            </Stack>
          </Stack>
        </Stack>

        <Stack mt={2} direction="row" spacing={1} justifyContent="center">
          <LoadingButton
            variant="contained"
            sx={{ width: "7rem" }}
            disableRipple
            loading={loading}
            textAlign="center"
            onClick={handleSubmit}
          >
            Save
          </LoadingButton>

          <Button
            sx={{ width: "7rem", ...whiteButtonStyle }}
            onClick={closeModal}
            disableRipple
          >
            Cancel
          </Button>
        </Stack>
      </Box>
    </Modal>
  );
}
