import React from "react";
// @mui
import { Select, Box, MenuItem, FormControl } from "@mui/material";
// ----------------------------------------------------------------------

export default function TimeFrameSelect({ selectValue, handleSelectChange }) {
    return (
      <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}>
        <FormControl size="small">
          <Select value={selectValue} onChange={handleSelectChange}>
            <MenuItem value={"week"} disableRipple>
             This Past Week
            </MenuItem>
            <MenuItem value={"month"} disableRipple>
              This Past Month
            </MenuItem>
            <MenuItem value={"year"} disableRipple>
              This Past Year
            </MenuItem>
          </Select>
        </FormControl>
      </Box>
    );
  }