import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
// @mui
import {
  Typography,
  InputAdornment,
  IconButton,
  Stack,
  Container,
  TextField,
  Snackbar,
  Alert,
} from "@mui/material";
// @mui-icons
import {
  VisibilityOutlined,
  VisibilityOffOutlined,
  MailOutline,
} from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
// APIs
import { login, loginData } from "../../api/authRoutes";

export default function Login() {
  const location = useLocation();
  const navigate = useNavigate();
  const [emailValue, setEmailValue] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [passwordValue, setPasswordValue] = useState("");
  const [message, setMessage] = useState("");
  const [locationMessage, setLocationMessage] = useState("");
  const [focusOne, setFocusOne] = useState(false);
  const [focusTwo, setFocusTwo] = useState(false);
  const [loginClicked, setLoginClicked] = useState(false);
  const [wasInitiallyAutofilled, setWasInitiallyAutofilled] = useState(false);
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    vertical: "bottom",
    horizontal: "center",
    text: "",
  });
  const { open, vertical, horizontal, text } = snackbarState;
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    const checkAutofilled = () => {
      const autofilled = !!document
        .getElementById("field")
        ?.matches("*:-webkit-autofill");
      setWasInitiallyAutofilled(autofilled);
    };

    setTimeout(checkAutofilled, 300);
    setTimeout(checkAutofilled, 450);
    setTimeout(checkAutofilled, 700);
  }, []);

  useEffect(() => {
    if (location.state) {
      if (location.state.message) {
        setLocationMessage(location.state.message);
      }
      if (location.state.snackbar) {
        setSnackbarState(location.state.snackbar);
      }
    }
  }, []);

  const setEmail = (e) => {
    const newValue = e.target.value;
    const sanitizedValue = newValue.replace(/\s/g, "");
    setEmailValue(sanitizedValue);
  };

  const setPassword = (e) => {
    const newValue = e.target.value;
    const sanitizedValue = newValue.replace(/\s/g, "");
    setPasswordValue(sanitizedValue);
  };

  const handleCloseSnackbar = () => {
    setSnackbarState({ ...snackbarState, open: false });
  };

  const onSignIn = async (e) => {
    e.preventDefault();
    setLoginClicked(true);

    if (emailValue.length === 0 || passwordValue.length === 0) {
      setMessage(
        emailValue.length === 0
          ? "Please enter email!"
          : "Please enter password!"
      );
      setLoginClicked(false);
      return;
    }

    const lowerCaseEmail = emailValue.toLowerCase();
    // const fetchFunction = () => login(lowerCaseEmail, passwordValue)
    const params = { email: lowerCaseEmail, password: passwordValue };
    // const responseUserId = await withTimingAndLogging(fetchFunction, 'Fetching User Id.', false, funcName, params);
    const responseUserId = await login("Fetching User Id.", params);

    const userData = responseUserId.data.data;
    const statusCodeUserId = responseUserId.statusCode;
    const userId = userData.user_id;
    const recent_org = userData.recent_org;

    localStorage.setItem("userID", userId);

    if (statusCodeUserId === 200) {
      // const responseLoginData = await withTimingAndLogging(() => loginData(userId), 'Fetching login data packet.', false, 'loginData', { user_id: userId });
      const responseLoginData = await loginData(
        userId,
        recent_org,
        "Fetching login data packet.",
        { user_id: userId, recent_org }
      );
      const loginDataResponse = responseLoginData.data;
      const orgIds = loginDataResponse.org_ids;
      const orgIdFilter =
        orgIds.length > 1
          ? recent_org ||
            orgIds.filter((org) => org !== "O-NextilesInc-0101")[0]
          : orgIds[0];

      const storedUserData = {
        user_id: userId,
        name: loginDataResponse.data1.name,
        admin: loginDataResponse.org_data.type === "admin",
        org_id: orgIdFilter,
        active_orgs: loginDataResponse.org_schemas,
      };

      localStorage.setItem("myState", JSON.stringify(storedUserData));
      if (userData.incomplete_fields?.length > 0) {
        navigate("/completeregistration", {
          state: {
            userId: userId,
            emailValue: loginDataResponse.data1.email,
            firstName: loginDataResponse.data1.first_name,
            lastName: loginDataResponse.data1.last_name,
            dateOfBirth: loginDataResponse.data1.user_metadata.dob,
            country: loginDataResponse.data1.user_metadata.country,
            userMetaData: loginDataResponse.data1.user_metadata,
          },
        });
      } else {
        navigate("/dashboard/home");
      }
    } else {
      setLoginClicked(false);
      setMessage(userData.message);
      setEmailValue("");
      setPasswordValue("");
    }
  };

  return (
    <Container maxWidth="xl" sx={{ ml: 0 }}>
      <Stack height={"100vh"} alignItems={"center"} component="form">
        <Stack gap={2} sx={{ width: "65%" }} mt={6}>
          <Typography mt={1} fontSize={16} height={"3rem"} color={"#2e7d32"}>
            {locationMessage}
          </Typography>

          <Typography variant="h4">Login</Typography>

          <Stack gap={1}>
            <TextField
              margin="normal"
              onFocus={() => {
                setFocusOne(true);
              }}
              focused={wasInitiallyAutofilled}
              fullWidth
              // error={error}
              id="field"
              type="text"
              autoComplete="username"
              value={emailValue}
              variant="outlined"
              InputProps={{
                style: { backgroundColor: "white" },
                startAdornment: focusOne && (
                  <InputAdornment position="start">
                    {<MailOutline />}
                  </InputAdornment>
                ),
              }}
              label="Email"
              onChange={(e) => setEmail(e)}
            />

            <TextField
              onFocus={() => {
                if (focusTwo) {
                  return;
                } else {
                  setFocusTwo(true);
                }
              }}
              id="password"
              // error={error}
              focused={wasInitiallyAutofilled}
              type={showPassword ? "text" : "password"}
              margin="normal"
              fullWidth
              autoComplete="current-password"
              label="Password"
              value={passwordValue}
              onChange={(e) => setPassword(e)}
              InputProps={{
                startAdornment: focusTwo && (
                  <InputAdornment position="start">
                    <IconButton
                      sx={{
                        "&:hover": { backgroundColor: "transparent" },
                        padding: 0,
                      }}
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {showPassword ? (
                        <VisibilityOffOutlined />
                      ) : (
                        <VisibilityOutlined />
                      )}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>

          <Link
            to={"/forgotpassword"}
            style={{
              color: "black",
              fontWeight: 600,
              cursor: "pointer",
            }}
          >
            Forgot password?
          </Link>

          <Typography fontSize={16} height={"1rem"} color={"error"}>
            {message}
          </Typography>

          <LoadingButton
            sx={{ mt: 4 }}
            fullWidth
            id="login-button"
            type="submit"
            variant="contained"
            disableRipple
            onClick={onSignIn}
            loading={loginClicked}
          >
            Login
          </LoadingButton>
        </Stack>

        <Typography mt={2}>
          Don't have an account? &nbsp;
          <Link
            to={"/createnewaccount"}
            style={{
              color: "black",
              fontWeight: 600,
              cursor: "pointer",
            }}
          >
            Register
          </Link>
        </Typography>
      </Stack>
      <Snackbar
        open={open}
        anchorOrigin={{ vertical, horizontal }}
        autoHideDuration={10000}
        onClose={handleCloseSnackbar}
        key={vertical + horizontal}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          {text}
        </Alert>
      </Snackbar>
    </Container>
  );
}
