import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// @mui
import { 
    Container, Typography, Breadcrumbs, Stack, MenuItem, 
    Button, FormControl, InputLabel, Grid, Select,
} from "@mui/material";
// @mui-icons
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// Utils
import { registerAccountBreadcrumbs, whiteButtonStyle } from "../../utils/styles";
import {
  heights, weights, cms, kgs, countries
} from "../../utils/authData";
// ----------------------------------------------------------------------

export default function FillUserMetadata() {
  const navigate = useNavigate();

  const location = useLocation();

  const [formData, setFormData] = useState({
    email: "",
    firstName: "",
    lastName: "",
    month: "",
    day: "",
    year: "",
    gender: "",
    height: "",
    country: "",
    heightMetric: "",
    weight: "",
    weightMetric: "",
    dominantLeg: "",
    dominantArm: "",
  });

  const [disabled, setDisabled] = useState(true)

  useEffect(() => {
    if (location.state) {
      setFormData(location.state.formData);
      const isValid = Object.values(location.state.formData).every((key)=> key !=="")
      isValid ? setDisabled(false): setDisabled(true)
    }

  }, []);

  const {
    heightMetric, height, weight, weightMetric,
    dominantArm, dominantLeg, country, gender,
  } = formData;


  const checkDisableStatus = (obj) => {
    const isValid = Object.values(obj).every((key)=> key !=="")
    isValid ? setDisabled(false): setDisabled(true)
  }

  const handleFormDataChange = (e) => {
    const { name, value } = e.target;

    if (name === "heightMetric") {
        const convertedHeight = convertHeight(value, height);
        setFormData({
            ...formData,
            [name]: value,
            height: convertedHeight,
        });
        checkDisableStatus({
          ...formData,
          [name]: value,
          height: convertedHeight,
        })
    } else if (name === "weightMetric") {
        const convertedWeight = convertWeight(value, weight);
        setFormData({
            ...formData,
            [name]: value,
            weight: convertedWeight,
        });
        checkDisableStatus({
          ...formData,
          [name]: value,
          weight: convertedWeight,
        })
    } else if (name === "height" && heightMetric === "") {
      setFormData({
        ...formData,
        [name]: value,
        heightMetric: "cm",
      });
      checkDisableStatus({
        ...formData,
        [name]: value,
        heightMetric: "cm",
      })
    } else if (name === "weight" && weightMetric === "") {
      setFormData({
        ...formData,
        [name]: value,
        weightMetric: "kg",
      });
      checkDisableStatus({
        ...formData,
        [name]: value,
        weightMetric: "kg",
      })
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
      checkDisableStatus({
        ...formData,
        [name]: value,
      })
    }
  };

  const convertHeight = (newMetric, currentHeight) => {
    if (newMetric === "cm" && currentHeight !== "0" && currentHeight !== "") {
      // Convert feet and inches to centimeters
      const match = currentHeight.match(/(\d+) feet (\d+) inches/);
      if (match) {
        const feet = parseInt(match[1]);
        const inches = parseInt(match[2]);
        const totalInches = feet * 12 + inches;
        const centimeters = totalInches * 2.54; // 1 inch = 2.54 cm
        return Math.round(centimeters); // Round to the nearest whole number
      }
    } else {
      // Convert centimeters to feet and inches
      const inches = currentHeight / 2.54; // 1 cm = 1/2.54 inches
      const feet = Math.floor(inches / 12);
      const remainingInches = inches % 12;
      return `${feet} feet ${remainingInches.toFixed()} inches`;
    }
  };

  const convertWeight = (newMetric, currentWeight) => {
    if (newMetric === "kg" && currentWeight !== 0) {
      // Convert pounds to kilograms (1 lb = 0.453592 kg)
      const kilograms = currentWeight * 0.453592;
      return Math.round(kilograms); // Round to the nearest whole number
    } else if (newMetric === "lb") {
      // Convert kilograms to pounds (1 kg = 2.20462 lbs)
      const pounds = currentWeight * 2.20462;
      return Math.round(pounds); // Round to the nearest whole number
    }
    // Handle other cases as needed
    return currentWeight;
  };

  const submitAccount = (e) => {
    e.preventDefault()

    navigate('/reviewaccount', {
        state: {
            formData
        }
    })
  };

  return (
    <Container maxWidth="xl" ml={0}>
      <Stack height={"100vh"}>
        <Breadcrumbs separator="›" mt={3} ml={4}>
          {registerAccountBreadcrumbs}
        </Breadcrumbs>

        <Stack sx={{ alignItems: "center" }}>
          <Typography variant="h4" mt={2} mb={1}>
            Enter Your Metadata
          </Typography>

          <Grid
            container
            component="form"
            justifyContent="center"
            sx={{ width: "85%" }}
            rowSpacing={1}
            columnSpacing={2}
          >
            <Grid item xs={6}>
              <Typography variant="body1" mb={1}>
                What's your gender?
              </Typography>
              <FormControl fullWidth>
                <InputLabel required>Gender</InputLabel>
                <Select
                  value={gender}
                  fullWidth
                  required
                  label="Gender"
                  name="gender"
                  placeholder="Gender"
                  variant="outlined"
                  onChange={handleFormDataChange}
                >
                  <MenuItem disableRipple key={0} value={"male"}>
                    Male
                  </MenuItem>
                  <MenuItem disableRipple key={1} value={"female"}>
                    Female
                  </MenuItem>
                  <MenuItem disableRipple key={2} value={"Prefer Not to Say"}>
                    Prefer Not To Say
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
                <Typography variant="body1" mb={1}>
                    Country
                </Typography>
                <FormControl fullWidth>
                <InputLabel required>Country</InputLabel>
                <Select
                    value={country}
                    required
                    variant="outlined"
                    label="Country"
                    name="country"
                    onChange={handleFormDataChange}
                >
                    {countries.map((country, index) => (
                        <MenuItem
                            key={index}
                            value={country}
                            disabled={country === "---------------------------------"}
                        >
                            {country}
                        </MenuItem>
                    ))}
                </Select>
                </FormControl>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="body1" mb={1} mt={1.5}>
                What's your height?
              </Typography>
              <FormControl fullWidth>
                <InputLabel required>Height</InputLabel>
                <Select
                  value={height}
                  required
                  name="height"
                  label="Height"
                  fullWidth
                  variant="outlined"
                  onChange={handleFormDataChange}
                >
                  {heightMetric === "in"
                    ? heights.map((height, index) => {
                        return (
                          <MenuItem disableRipple key={index} value={height}>
                            {height}
                          </MenuItem>
                        );
                      })
                    : cms.map((height, index) => {
                        return (
                          <MenuItem disableRipple key={index} value={height}>
                            {height}
                          </MenuItem>
                        );
                      })}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="body1" mb={1} mt={1.5}>
                Metric
              </Typography>

              <FormControl fullWidth>
                <InputLabel required>Height Metric</InputLabel>
                <Select
                  value={heightMetric}
                  required
                  name="heightMetric"
                  variant="outlined"
                  fullWidth
                  label="Height Metric"
                  onChange={handleFormDataChange}
                >
                  <MenuItem disableRipple key={heightMetric} value={"in"}>
                    ft
                  </MenuItem>
                  <MenuItem disableRipple key={heightMetric} value={"cm"}>
                    cm
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="body1" mb={1} mt={1.5}>
                What's your weight?
              </Typography>

              <FormControl fullWidth>
                <InputLabel required>Weight</InputLabel>
                <Select
                  value={weight}
                  required
                  name="weight"
                  label="Weight"
                  variant="outlined"
                  onChange={handleFormDataChange}
                  fullWidth
                >
                  {weightMetric === "lb"
                    ? weights.map((weight, index) => {
                        return (
                          <MenuItem disableRipple key={index} value={weight}>
                            {weight}
                          </MenuItem>
                        );
                      })
                    : kgs.map((weight, index) => {
                        return (
                          <MenuItem disableRipple key={index} value={weight}>
                            {weight}
                          </MenuItem>
                        );
                      })}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="body1" mb={1} mt={1.5}>
                Metric
              </Typography>

              <FormControl fullWidth>
                <InputLabel required>Weight Metric</InputLabel>
                <Select
                  name="weightMetric"
                  label="Weight Metric"
                  value={weightMetric}
                  required
                  variant="outlined"
                  fullWidth
                  onChange={handleFormDataChange}
                >
                  <MenuItem disableRipple key={weightMetric} value={"lb"}>
                    lbs
                  </MenuItem>
                  <MenuItem disableRipple key={weightMetric} value={"kg"}>
                    kgs
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="body1" mb={1} mt={1.5}>
                Which leg are you dominant in?
              </Typography>

              <FormControl fullWidth>
                <InputLabel required>Dominant Leg</InputLabel>
                <Select
                  value={dominantLeg}
                  required
                  name="dominantLeg"
                  label="Dominant Leg"
                  variant="outlined"
                  onChange={handleFormDataChange}
                  fullWidth
                >
                  <MenuItem disableRipple value={"right"} key={1}>
                    Right
                  </MenuItem>
                  <MenuItem disableRipple value={"left"} key={2}>
                    Left
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="body1" mb={1} mt={1.5}>
                Which arm are you dominant in?
              </Typography>

              <FormControl fullWidth>
                <InputLabel required>Dominant Arm</InputLabel>
                <Select
                  name="dominantArm"
                  value={dominantArm}
                  required
                  variant="outlined"
                  label="Dominant Arm"
                  onChange={handleFormDataChange}
                  fullWidth
                >
                  <MenuItem disableRipple value={"right"}>
                    Right
                  </MenuItem>
                  <MenuItem disableRipple value={"left"}>
                    Left
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Stack direction={"row"} justifyContent="space-between">
                <Button
                  variant="contained"
                  disableRipple
                  onClick={() => navigate("/createnewaccount", {
                    state: {
                        formData
                    }
                  })}
                  size="md"
                  sx={{ ...whiteButtonStyle }}
                  startIcon={<ArrowBackIcon sx={{ color: "#FFC04D", pr: 1 }} />}
                >
                  Back
                </Button>

                <Button
                  onClick={submitAccount}
                  size="md"
                  disabled={disabled}
                  disableRipple
                  variant="contained"
                >
                  Review
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </Stack>
    </Container>
  );
}