import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// @mui
import { 
    Typography, Stack, Button, InputLabel, Grid, TextField, Container, Breadcrumbs
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
// @mui-icons
import { ArrowBack } from "@mui/icons-material";
// APIs
import { registerUser } from "../../api/authRoutes";
// Utils
import { toISOLocal } from "../../utils/utilityFunctions";
import { feetConversion, processNewUserObjectForInvite } from "../../utils/authData";
import { whiteButtonStyle, accountTextFieldStyleTwo, registerAccountBreadcrumbs } from "../../utils/styles";
// ----------------------------------------------------------------------

export default function ReviewAccount() {
  const navigate = useNavigate();

  const location = useLocation();

  const [formData, setFormData] = useState({});

  const [loading, setLoading] = useState(false)

  const [message, setMessage] = useState("");

  useEffect(() => {
    if (location.state) {
        setFormData(location.state.formData);
    }
  }, []);

  const {
    firstName, lastName, email, year, day, month, 
    heightMetric, height, weight, weightMetric,
    dominantArm, dominantLeg, country, gender,
  } = formData;

  const submitAccount = async (e) => {
    e.preventDefault()
    setLoading(true)
    const dateOfBirth = `${year}-${month}-${day}`;
    const date = toISOLocal(new Date());
    const lowerCaseEmail = email.toLowerCase().trim();
    const newInches = heightMetric === "in" ? feetConversion(height) : height;
    const newGender = gender === "Prefer Not to Say" ? null : gender;

    const userData = {
      first: firstName.trim(),
      last: lastName.trim(),
      email: lowerCaseEmail,
      date: date,
      height: newInches,
      heightMetric,
      weight,
      weightMetric,
      dateOfBirth,
      dominantArm,
      dominantLeg,
      country,
      gender: newGender,
      orgId: "O-NextilesInc-0101"
    };

    const processedUserData = processNewUserObjectForInvite(userData);
    const params = { user_data: processedUserData };

    const response = await registerUser(
      processedUserData,
      "New Account registered through invite route.",
      params
    );
    const { data , statusCode } = response;

    statusCode === 200 ? handleSuccessfulInvite() : handleUnsuccessfulInvite(data)
  };

  const handleSuccessfulInvite = () => {
    setLoading(false)
    navigate("/login", {
        state: {
            snackbar: {
                open: true,
                vertical: 'bottom',
                horizontal: 'center',
                text: "Your account has been registered! Please check your email for your password."
            }
        }
    })
  }

  const handleUnsuccessfulInvite = (data) => {
    setLoading(false)
    setMessage("Registration was unsuccessful. Please try again!")
  }

    return (
        <Container maxWidth="xl" ml={0}>
            <Stack height={"100vh"}>
                <Breadcrumbs separator="›" mt={3} ml={4}>
                    {registerAccountBreadcrumbs}
                </Breadcrumbs>


                <Stack sx={{ alignItems: "center" }}>
                    <Typography variant="h4" mb={1} mt={2}>
                        Review
                    </Typography>

                    <Grid
                        container
                        component="form"
                        justifyContent="center"
                        sx={{ width: "85%", overflowY: "scroll", maxHeight: "70vh" }}
                        rowSpacing={1}
                        columnSpacing={2}
                    >
                        <Grid item xs={12}>
                            <InputLabel id="email" sx={{ fontSize: 10 }}>
                                Email
                            </InputLabel>
                            <TextField
                                id="email"
                                labelId="email"
                                sx={{ ...accountTextFieldStyleTwo, width: "100%" }}
                                disabled={true}
                                value={email}
                                variant="standard"
                            />
                        </Grid>

                        <Grid item xs={12} sm={6} >
                            <InputLabel id="first" sx={{ fontSize: 10 }}>
                                First Name
                            </InputLabel>
                            <TextField
                                id="first"
                                labelId="first"
                                sx={{ ...accountTextFieldStyleTwo, width: "100%" }}
                                disabled={true}
                                value={firstName}
                                variant="standard"
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <InputLabel id="last" sx={{ fontSize: 10 }}>
                                Last Name
                            </InputLabel>
                            <TextField
                                id="last"
                                sx={{ ...accountTextFieldStyleTwo, width: "100%" }}
                                disabled={true}
                                value={lastName}
                                labelId="last"
                                variant="standard"
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <InputLabel id="height" sx={{ fontSize: 10 }}>
                                Height
                            </InputLabel>
                            <TextField
                                id="height"
                                labelId="height"
                                sx={{ ...accountTextFieldStyleTwo, width: "100%" }}
                                disabled={true}
                                value={
                                    height === ""
                                    ? ""
                                    : heightMetric === "in"
                                    ? `${height}`
                                    : `${height} ${heightMetric}`
                                }
                                variant="standard"
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <InputLabel id="weight" sx={{ fontSize: 10 }}>
                                Weight
                            </InputLabel>
                            <TextField
                                id="weight"
                                sx={{ ...accountTextFieldStyleTwo, width: "100%" }}
                                disabled={true}
                                value={weight === "" ? `` : `${weight} ${weightMetric}`}
                                labelId="weight"
                                variant="standard"
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <InputLabel id="arm" sx={{ fontSize: 10 }}>
                                Arm Dominance
                            </InputLabel>
                            <TextField
                                value={dominantArm && `${dominantArm
                                    .charAt(0)
                                    .toUpperCase()}${dominantArm.slice(1)}`}
                                sx={{ ...accountTextFieldStyleTwo, width: "100%" }}
                                disabled={true}
                                variant="standard"
                                labelId="arm"
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <InputLabel id="leg" sx={{ fontSize: 10 }}>
                                Leg Dominance
                            </InputLabel>
                            <TextField
                                value={dominantLeg &&`${dominantLeg
                                    .charAt(0)
                                    .toUpperCase()}${dominantLeg.slice(1)}`}
                                sx={{ ...accountTextFieldStyleTwo, width: "100%" }}
                                InputProps={{ readOnly: true }}
                                disabled={true}
                                variant="standard"
                                labelId="leg"
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <InputLabel id="gender" sx={{ fontSize: 10 }}>
                                Gender
                            </InputLabel>
                            <TextField
                                value={gender && `${gender.charAt(0).toUpperCase()}${gender.slice(1)}`}
                                sx={{ ...accountTextFieldStyleTwo, width: "100%" }}
                                disabled={true}
                                variant="standard"
                                labelId="gender"
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <InputLabel id="dateOfBirth" sx={{ fontSize: 10 }}>
                                Date Of Birth
                            </InputLabel>
                            <TextField
                                value={month ? month.concat(`-${day}-${year}`) : ""}
                                sx={{ ...accountTextFieldStyleTwo, width: "100%" }}
                                disabled={true}
                                variant="standard"
                                labelId="yy"
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <InputLabel id="country" sx={{ fontSize: 10 }}>
                                Country
                            </InputLabel>
                            <TextField
                                value={country}
                                sx={{ ...accountTextFieldStyleTwo, width: "100%" }}
                                disabled={true}
                                variant="standard"
                                labelId="country"
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Typography variant="subtitle2" m={1}>
                                NOTE: Your password will be generated and sent to your email on account creation.
                            </Typography>
               
                        </Grid>

                        <Grid item xs={12} mt={1}>
                            <Stack direction="row" spacing={1} justifyContent="space-between">
                                <Button
                                    sx={{ ...whiteButtonStyle}}
                                    onClick={() => navigate("/completeaccount", {
                                        state: {
                                            formData
                                        }
                                    })}
                                    disableRipple
                                    variant="contained"
                                    startIcon={<ArrowBack sx={{ color: "#FFC04D", pr: 1 }} />}
                                >
                                    Back
                                </Button>

                                <LoadingButton
                                    variant="contained"
                                    disableRipple
                                    loading={loading}
                                    textAlign="center"
                                    onClick={submitAccount}
                                >
                                    Create Account
                                </LoadingButton>
                            </Stack>
                        </Grid>

                        <Typography color={"error"} mt={2}>
                            {message}
                        </Typography>
                    </Grid>
                </Stack>
            </Stack>
        </Container>
    )
}