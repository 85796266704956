import React from 'react';
import ReactDOM from 'react-dom/client';
import { datadogRum } from '@datadog/browser-rum';
import { datadogLogs } from '@datadog/browser-logs';
import './index.css';
import './fonts/nasalization/nasalization-rg.ttf';
import App from './App';
// import "./utils/GlobalFun";

const env = process.env.STAGE
const root = ReactDOM.createRoot(document.getElementById('root'));

datadogRum.init({
  applicationId: '21dff07d-da40-46c6-9db7-0ff4c826e1cf',
  clientToken: 'pub772523d2eba11a6c4431ebcbc6d872e5',
  site: 'datadoghq.com',
  service: 'nextiles.web.v1',
  env: env,
  sessionSampleRate: 100,
  sessionReplaySampleRate: 20,
  trackUserInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  defaultPrivacyLevel: 'mask-user-input',
});

datadogLogs.init({
  clientToken: 'pub772523d2eba11a6c4431ebcbc6d872e5',
  site: 'datadoghq.com',
  forwardErrorsToLogs: true,
  env: env,
  sessionSampleRate: 100,
})

root.render(
    <App />
);