import api from "../utils/apiCall";

// Fetch All User Session in an Org
export const fetchAllUserSessionsInOrg = async (
  userId,
  orgId,
  datadogMsg,
  params,
  admin = false,
  funcName = "fetchAllUserSessionsInOrg"
) => {
  return await api({
    path: `/user/usersessions/${userId}/${orgId}`,
    params,
    datadogMsg,
    admin,
    funcName,
  }).then((response) => response);
};

// Fetch All Sessions in an Org
export const fetchAllOrgSessions = async (
  orgId,
  datadogMsg,
  params,
  admin = false,
  funcName = "fetchAllOrgSessions"
) => {
  return await api({
    path: `/org/orgsessions/${orgId}`,
    params,
    datadogMsg,
    admin,
    funcName,
  }).then((response) => response);
};

// Fetch Paginated Sessions
export const fetchPaginatedUserSessions = async (
  userId,
  orgId,
  limit,
  lastKey,
  activityId,
  status,
  page,
  datadogMsg,
  params,
  admin = false,
  funcName = "fetchPaginatedUserSessions"
) => {
  let url = `/user/usersessionspaginated/${userId}/${orgId}/${limit}/${activityId}/${status}/${page}`;
  if (lastKey && lastKey !== "start") {
    url += `/${lastKey}`;
  }
  return await api({
    path: url,
    params,
    datadogMsg,
    admin,
    funcName,
  }).then((response) => response);
};

// Fetch Paginated Org Sessions
export const fetchPaginatedOrgSessions = async (
  orgId,
  limit,
  lastKey,
  activityId,
  status,
  page,
  datadogMsg,
  params,
  admin = false,
  funcName = "fetchPaginatedOrgSessions"
) => {
  let url = `/org/orgsessionspaginated/${orgId}/${limit}/${activityId}/${status}/${page}`;
  if (lastKey && lastKey !== "start") {
    url += `/${lastKey}`;
  }

  return await api({
    path: url,
    params,
    datadogMsg,
    admin,
    funcName,
  }).then((response) => response);
};

//Fetch User Dashboard Summary
export const fetchUserDashboardSummary = async (
  userId,
  orgId,
  stime,
  etime,
  activityId,
  datadogMsg,
  params,
  admin = false,
  funcName = "fetchUserDashboardSummary"
) => {
  return await api({
    path: `/user/usersessiondashboardsummary/${userId}/${orgId}/${stime}/${etime}/${activityId}`,
    params,
    datadogMsg,
    admin,
    funcName,
  }).then((response) => response);
};

//Fetch Org Dashboard Summary

export const fetchOrgDashboardSummary = async (
  orgId,
  stime,
  etime,
  activityId,
  datadogMsg,
  params,
  admin = false,
  funcName = "fetchOrgDashboardSummary"
) => {
  return await api({
    path: `/org/orgsessiondashboardsummary/${orgId}/${stime}/${etime}/${activityId}`,
    params,
    datadogMsg,
    admin,
    funcName,
  }).then((response) => response);
};

// Fetch Org Sessions Summary
export const fetchOrgSessionsSummary = async (
  orgId,
  lastKey,
  datadogMsg,
  params,
  admin = false,
  funcName = "fetchOrgSessionsSummary"
) => {
  return await api({
    path: `/org/orgsessionsmetadatasummary/${orgId}`,
    params,
    datadogMsg,
    admin,
    funcName,
  }).then((response) => response);
};

// Fetch Specific Session Data
export const fetchSpecificSessionData = async (
  userId,
  orgId,
  sessionId,
  datadogMsg,
  params,
  admin = false,
  funcName = "fetchSpecificSessionData"
) => {
  return await api({
    path: `/session/specificsessions/${userId}/${orgId}/${sessionId}`,
    params,
    datadogMsg,
    admin,
    funcName,
  }).then((response) => response);
};

// Download Email Specific Session Data Based on Param
export const downloadSpecificSessionData = async (
  userId,
  orgId,
  sessionId,
  fileType,
  datadogMsg,
  params,
  admin = false,
  funcName = "downloadSpecificSessionData"
) => {
  return await api({
    path: `/session/specificsession${fileType}email/${userId}/${orgId}/${sessionId}`,
    params,
    datadogMsg,
    admin,
    funcName,
  }).then((response) => response);
};
