import React, { useState } from 'react';
import { Card, Box, FormControlLabel, Switch, CardHeader, Typography } from '@mui/material';
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  TimeScale,
  Tooltip,
  LineElement,
  Filler,
  PointElement,
  Legend,
} from "chart.js";
import { Line, Bar } from "react-chartjs-2";
import LineChart from '../../components/chart/LineChart';
import BarChart from '../../components/chart/BarChart';
import { 
  returnData, barChartOptions, lineGraphOptions, 
  calculateSpecificSessionData, getColorForYValue, 
  generateLegendLabels, lollipop, getChartColor 
} from '../../components/chart/chartData';

ChartJS.register(BarElement, LineElement, PointElement, Filler, CategoryScale, LinearScale, TimeScale, Tooltip, Legend);

export default function LineGraph ({ analyzedSession, color, plotKeys }) {
  const [switchChecked, setSwitchChecked] = useState(false);
  const { metric_id, display_name, units } = plotKeys;

  const handleSwitchChange = (event) => {
    setSwitchChecked(event.target.checked);
  };

  const personalized_intensity_zone = metric_id.includes("intensity_zone_ai") 
  const yAxisMax = metric_id.includes("intensity_zone") ? 10 : undefined;
  const cardTitle = units ? `${display_name} (${units})` : `${display_name}`
  const seriesData = calculateSpecificSessionData(analyzedSession, switchChecked, metric_id);
  const area = analyzedSession.length > 10
  const xAxisLabel = switchChecked ? "Time (minutes)" : "Reps"
  const xAxisTooltipTitle = switchChecked ? "Time:" : "Rep: "
  const personalized_intensity_zone_min = switchChecked ? 0 : 1
  const personalized_intensity_zone_max = switchChecked ? seriesData[seriesData.length-1].x : seriesData.length
  const tickLimit = switchChecked ? null : seriesData.length
  const barThickness = switchChecked ? 1 : 28

  const lineGraphData = {
    datasets: [{
      label: display_name,
      data: seriesData,
      fill: true,
      tension: 0.3,
      borderWidth:4,
      pointRadius: 2.2,
      segment: {
        backgroundColor: ctx => getChartColor(ctx, "background"),
        borderColor: ctx => getChartColor(ctx, "border"),
      }
    }]
  }
  
  const options = {
    clip: false,
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: "left",
        reverse: true,
        align: "start",
        labels: {
          boxHeight: 11,
          boxWidth: 11, 
          padding: 15,
          generateLabels: generateLegendLabels,
        },
      },
      tooltip: {
        titleFont: {
          size: 10,
        },
        callbacks: {
          title: (val) => {
            const xVal = switchChecked ? val[0].raw.x.toFixed(2) : val[0].raw.x 
            return `${xAxisTooltipTitle} ${xVal}`
          },
        },
        bodyFont: {
          size: 10,
        },
        mode: "index",
        intersect: false,
        backgroundColor: "rgba(0, 0, 0, 0.8)",
        displayColors: false,
      },
    },
    elements: {
      bar: {
        barThickness: 10,
        backgroundColor: ({ datasetIndex, dataIndex, chart }) => {
          const yValue = chart.data.datasets[datasetIndex].data[dataIndex].y;
          return getColorForYValue(yValue);
        },
      },
      point: {
        backgroundColor: ({ dataIndex, dataset }) => getColorForYValue(dataset.data[dataIndex].y),
        borderColor: ({ dataIndex, dataset }) => getColorForYValue(dataset.data[dataIndex].y),
        hitRadius: 7,
        hoverRadius: 7,
      }
    },
    scales: {
      x: {
        type: "linear", 
        min: personalized_intensity_zone_min,
        max: personalized_intensity_zone_max,
        labels: seriesData.map(data => data.x), 
        title: {
          display: true,
          text: xAxisLabel,
          color: "#A3AED0",
          font: {
            size: "12px",
            weight: "bold",
          },
        },
        grid: {
          color: 'rgba(255, 0, 0, 0.1)', 
          display: false,
        },
        border:{
          display: false,
        },
        ticks: {
          color: "#A3AED0",
          autoSkip: false,
          maxTicksLimit: tickLimit,
          callback: function(val, index) {
            const formattedLabel = switchChecked ? val.toFixed(2) : val
            return formattedLabel;
          },
          font: {
            size: "12px",
            weight: "normal"
          },
        },
      },
      y: {
        border: {
          display: false,
        },
        grid: {
          display: true,
        },
        ticks: {
          callback: function(value, index, ticks) {
              return value.toFixed(1);
          },
          color: "#A3AED0",
          font: {
            size: "12px",
            weight: "normal"
          },
          padding: 8,
          min: 0,
        },
        max: 10,
        beginAtZero: true,
      },
    },
  };

  const barGraphData = {
    labels: seriesData.map(data => data.x),
    datasets: [{
      label: display_name,
      data: seriesData,
      barThickness: barThickness,
    }]
  };

  const personalizedGraph = (
    <Box sx={{ height: "100%", width: "100%" }}>
      {area && !switchChecked ? (
          <Line data={lineGraphData} options={options}/>
        ) : (
          <Bar 
            data={barGraphData} 
            options={options} 
            plugins={[lollipop]}
          />
        )}
    </Box>
  );

  const ChartComponent = area ? (
    <LineChart
      key={JSON.stringify(seriesData)}
      chartData={returnData(seriesData, display_name)}
      chartOptions={lineGraphOptions(color, seriesData, 10, true, xAxisLabel, xAxisTooltipTitle, yAxisMax, units)}
    />
  ) : (
    <BarChart
      key={JSON.stringify(seriesData)}
      chartData={returnData(seriesData.map((session) => session.y), display_name)}
      chartOptions={barChartOptions(color, seriesData.map((data) => data.x), 10, xAxisLabel,  xAxisTooltipTitle, yAxisMax, units)}
    />
  );

  return (
    <Card sx={{ width: "100%" }}>
      <CardHeader 
        title={cardTitle}
        action={
         ( personalized_intensity_zone || area ) ? (
            <FormControlLabel
              control={
                <Switch 
                  checked={switchChecked} 
                  onChange={handleSwitchChange} 
                />
              }
              label="Time"
            />
          ) : (
            <Typography>{analyzedSession.length} reps</Typography>
          )
        }
      />
      <Box sx={{ height: 365, px: 1, pt: 1, pb: 1, width: "100%" }}>
        {personalized_intensity_zone ? (
          personalizedGraph
        ) : (
          ChartComponent
        )}
      </Box>
    </Card>
  );
};