import React, { useState, useRef } from "react";
// @mui
import {
  Typography,
  Stack,
  Button,
  Card,
  CardContent,
  Tooltip,
  Skeleton,
} from "@mui/material";
// @mui-icons
import { DriveFolderUploadOutlined, InfoOutlined } from "@mui/icons-material";
// Utils
import { whiteButtonStyle } from "../../../utils/styles";
// import { withTimingAndLogging } from "../../../utils/logFunctions/logs";
import { registerInviteViaCSV } from "../../../api/authRoutes";

export default function FileReader({
  orgId,
  handleCloseModal,
  checkInviteLeft,
  invite_left,
}) {
  const [csvfile, setCsvFile] = useState("");
  const [uploadDisabled, setUploadDisabled] = useState(true);
  const [selectedFile, setSelectedFile] = useState("No File Selected");
  const filesInputRef = useRef(null);
  const [showInitialUI, setShowInitialUI] = useState(true);
  const [loading, setLoading] = useState(false);
  const [resultants, setResultants] = useState({
    successful: 0,
    unsuccessful: 0,
    faulty: 0,
  });

  // Set CSV File from selected
  const handleChange = (event) => {
    const selectedFile = event.target.files[0];
    setSelectedFile(selectedFile.name);
    setCsvFile(event.target.files[0]);
    setUploadDisabled(false);
  };

  const fileUrl = "/downloadcsv";
  const handleDownload = () => {
    fetch(fileUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "nextiles-template.csv";
        link.click();
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error("Error downloading file:", error);
      });
  };

  // Invite CSV Athletes
  const parseCSVAndInviteAthletes = async () => {
    setShowInitialUI(false);
    setLoading(true);
    const formData = new FormData();
    formData.append("csv_data", csvfile);
    const params = { user_data: formData, org_id: orgId };
    const response = await registerInviteViaCSV(
      formData,
      "Athlete invite registered.",
      params
    );
    const { statusCode, data } = response;
    if (statusCode === 200) {
      // invite_left--;
      invite_left = data.data?.invite_left;
      setResultants({
        successful: data.data?.success_count,
        unsuccessful: data.data?.failure_count,
        faulty: 0,
      });
    }
    ////statusCode 400 is for wrong CSV format////
    // else if (statusCode === 400) {
    //   setResultants({
    //     ...resultants,
    //     faulty: 1,
    //   });
    // }

    setUploadDisabled(true);
    setLoading(false);
    // setResults(updatedResults);
    checkInviteLeft(invite_left);
  };

  return (
    <Stack alignItems={"center"}>
      {showInitialUI ? (
        <>
          <Card
            className="csv-input-button"
            elevation={0}
            onClick={() => {
              filesInputRef.current.click();
            }}
            sx={{ height: "7rem", width: "7rem", cursor: "pointer", p: 1 }}
          >
            <span style={invite_left > 0 ? {} : { cursor: "not-allowed" }}>
              <Stack justifyItems={"center"} alignItems={"center"}>
                <DriveFolderUploadOutlined
                  sx={{ fontSize: 105, color: "grey" }}
                  p={3}
                />
              </Stack>
              <input
                type="file"
                ref={filesInputRef}
                name="file"
                style={{ display: "none" }}
                onChange={handleChange}
                accept=".csv"
                disabled={invite_left <= 0}
              />
            </span>
          </Card>

          <Typography mb={2} variant="subtitle1">
            UPLOAD CSV FILE
          </Typography>
          <Typography variant="body1" height={"1rem"}>
            {selectedFile !== "No File Selected" && (
              <>Uploaded: {selectedFile}</>
            )}
          </Typography>
          <Stack
            mt={2}
            direction="row"
            spacing={1}
            justifyContent="space-evenly"
          >
            <Tooltip
              title={
                invite_left > 0
                  ? ""
                  : "You have surpassed the available invitation count"
              }
              arrow
              placement="top"
            >
              <span style={invite_left > 0 ? {} : { cursor: "not-allowed" }}>
                <Button
                  variant="contained"
                  sx={{ width: "7rem" }}
                  disableRipple
                  disabled={uploadDisabled}
                  onClick={parseCSVAndInviteAthletes}
                >
                  Upload
                </Button>
              </span>
            </Tooltip>

            <Button
              sx={{ width: "7rem", ...whiteButtonStyle }}
              onClick={handleCloseModal}
              disableRipple
            >
              Close
            </Button>
          </Stack>

          <Typography
            mt={3}
            onClick={() => handleDownload()}
            sx={{ textDecoration: "underline", cursor: "pointer" }}
          >
            Download Nextiles CSV Template
          </Typography>
        </>
      ) : (
        <>
          <Card
            elevation={0}
            p={1}
            m="auto"
            mt={0}
            sx={{ maxWidth: "79%", minWidth: "60%", maxHeight: "50vh" }}
          >
            <Typography
              variant="h5"
              fontSize={"1.1rem"}
              p={2}
              pb={0}
              fontWeight="bold"
            >
              {" "}
              {loading ? (
                "Uploading CSV..."
              ) : (
                <>
                  CSV Uploaded&nbsp;
                  {!!resultants.faulty && (
                    <Tooltip
                      arrow
                      title="Uploaded CSV doesn't matches Nextiles CSV Template."
                      placement="top"
                    >
                      <InfoOutlined
                        sx={{ color: "#666666", marginBottom: -0.37 }}
                      />
                    </Tooltip>
                  )}
                </>
              )}
            </Typography>
            <CardContent>
              <>
                <Stack
                  direction="column"
                  mb={1}
                  alignContent={"flex-start"}
                  spacing={2}
                >
                  {loading ? (
                    <>
                      <Skeleton animation="wave" width={"70%"} />
                      <Skeleton animation="wave" width={"70%"} />
                    </>
                  ) : (
                    <>
                      <Typography
                        variant="h6"
                        sx={{ fontSize: "1rem" }}
                        whiteSpace="nowrap"
                      >
                        Successful Invites &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;:{" "}
                        <span style={{ color: "#1b5e20" }}>
                          {resultants.successful}
                        </span>
                      </Typography>
                      <Typography
                        variant="h6"
                        sx={{ fontSize: "1rem" }}
                        whiteSpace="nowrap"
                      >
                        Unsuccessful Invites :{" "}
                        <span style={{ color: "#d50000" }}>
                          {resultants.unsuccessful}
                        </span>
                      </Typography>
                    </>
                  )}
                </Stack>
              </>
            </CardContent>
          </Card>
        </>
      )}
    </Stack>
  );
}
