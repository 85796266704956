import api from "../utils/apiCall";

// Fetch all Org Users in Org
export const createUserAccount = async (
  userData,
  datadogMsg,
  params,
  admin = false,
  funcName = "createUserAccount"
) => {
  return await api({
    path: `/auth/createaccount`,
    method: "POST",
    body: JSON.stringify(userData),
    params,
    datadogMsg,
    admin,
    funcName,
  }).then((response) => response);
};

// Forgot Password
export const forgotPassword = async (
  email,
  datadogMsg,
  params,
  admin = false,
  funcName = "forgotPassword"
) => {
  return await api({
    path: `/auth/forgotpassword/${email}`,
    params,
    datadogMsg,
    admin,
    funcName,
  }).then((response) => response);
};

// Reset Password
export const resetPassword = async (
  code,
  email,
  password,
  datadogMsg,
  params,
  admin = false,
  funcName = "resetPassword"
) => {
  return await api({
    path: `/auth/resetpassword/${code}/${email}/${password}`,
    params,
    datadogMsg,
    admin,
    funcName,
  }).then((response) => response);
};

//Check if Email is Being Used
export const isEmailAvailable = async (
  email,
  datadogMsg,
  params,
  admin = false,
  funcName = "isEmailAvailable"
) => {
  return await api({
    path: `/auth/isemailavailable/${email}`,
    params,
    datadogMsg,
    admin,
    funcName,
  }).then((response) => response);
};

// Update User meta data
export const updateUserMetadata = async (
  userMetaData,
  datadogMsg,
  params,
  admin = false,
  funcName = "updateUserMetadata"
) => {
  return await api({
    path: `/user/updateusermetadata`,
    method: "PUT",
    body: userMetaData,
    params,
    datadogMsg,
    admin,
    funcName,
  }).then((response) => response);
};

// Register Invited User
export const registerInvite = async (
  userData,
  datadogMsg,
  params,
  isFormData = false,
  admin = false,
  funcName = "registerInvite"
) => {
  return await api({
    path: `/auth/registerinvite`,
    body: userData,
    method: "POST",
    params,
    datadogMsg,
    admin,
    funcName,
    isFormData,
  }).then((response) => {
    const expiryDetails = JSON.parse(localStorage.getItem("expiryDetails"));
    if (response.statusCode === 200)
      localStorage.setItem(
        "expiryDetails",
        JSON.stringify({
          ...expiryDetails,
          invite_left: response.data.data?.invite_left
            ? response.data.data.invite_left
            : expiryDetails.invite_left - 1,
        })
      );
    return response;
  });
};

// Register Invited User Via CSV
export const registerInviteViaCSV = async (
  userData,
  datadogMsg,
  params,
  isFormData = true,
  admin = true,
  funcName = "registerInviteViaCSV"
) => {
  return await api({
    path: `/auth/batch-register/${params.org_id}`,
    body: userData,
    method: "POST",
    params,
    datadogMsg,
    admin,
    funcName,
    isFormData,
    isTimeoutEnabled: false,
  }).then((response) => {
    const expiryDetails = JSON.parse(localStorage.getItem("expiryDetails"));
    if (response.statusCode === 200)
      localStorage.setItem(
        "expiryDetails",
        JSON.stringify({
          ...expiryDetails,
          invite_left: response.data.data?.invite_left,
        })
      );
    return response;
  });
};

export const registerUser = async (
  userData,
  datadogMsg,
  params,
  admin = false,
  funcName = "registerInvite"
) => {
  return await api({
    path: `/auth/registerinvite`,
    body: userData,
    method: "POST",
    params,
    datadogMsg,
    admin,
    funcName,
  }).then((response) => {
    return response;
  });
};

// Login Packet
export const login = async (
  datadogMsg,
  params,
  admin = false,
  funcName = "login"
) => {
  return await api({
    path: `/auth/login/${params.email}/${params.password}`,
    params,
    datadogMsg,
    admin,
    funcName,
  }).then((response) => response);
};

// Login Data once User Id is received
export const loginData = async (
  user_id,
  recent_org,
  datadogMsg,
  params,
  admin = false,
  funcName = "loginData"
) => {
  return await api({
    path: `/auth/logindata/${user_id}/${recent_org}`,
    params: params,
    datadogMsg: datadogMsg,
    admin,
    funcName,
  }).then((response) => response);
};

// Admin Status
// we'll hit fetchAdminStatus api to keep an update of user's access expiration details also
export const fetchAdminStatus = async (
  userId,
  orgId,
  datadogMsg,
  params,
  admin = false,
  funcName = "fetchAdminStatus"
) => {
  const user_id = localStorage.getItem("userID");
  const org_id = localStorage.getItem("orgID");
  return await api({
    path: `/user/adminstatus/${userId ? userId : user_id}/${
      orgId ? orgId : org_id
    }`,
    params: params || { user_id, org_id },
    datadogMsg: datadogMsg || "Fetching admin status.",
    admin,
    funcName,
  }).then((response) => {
    const dataChunk = response.data.data;
    const expiryDetails = {
      days_left: dataChunk.days_left,
      expiration_response: dataChunk.expiration_response,
      can_invite: dataChunk.can_invite,
      invite_left: dataChunk.invite.invite_left,
      user_max: dataChunk.invite.user_max,
      //if(days_left == 365) => No Expiration
      download_raw: dataChunk.download_raw,
      download_analyzed: dataChunk.download_analyzed,
    };
    localStorage.setItem("expiryDetails", JSON.stringify(expiryDetails));
    return response;
  });
};

//Check if account is Being Used
export const accountCheck = async (
  orgId,
  email,
  datadogMsg,
  params,
  admin = false,
  funcName = "accountCheck"
) => {
  return await api({
    path: `/auth/accountcheck/${orgId}/${email}`,
    params,
    datadogMsg,
    admin,
    funcName,
  }).then((response) => response);
};
