import { MenuItem } from "@mui/material";
import validator from "validator";
// ----------------------------------------
export const days = [
    "01","02","03","04","05","06",
    "07","08","09","10","11","12",
    "13","14","15","16","17","18",
    "19","20","21","22","23","24",
    "25","26","27","28"
]

export const months = 
    [ 
        "01","02","03","04","05",
        "06","07","08","09","10",
        "11","12"
]

export const years = Array.from(Array(new Date().getFullYear() - 1949), (_, i) => (i + 1950).toString()).reverse()

export const countries = [
    "United States","United Kingdom","Canada","---------------------------------",
    "Afghanistan","Albania","Algeria","Andorra","Angola","Anguilla","Antigua and Barbuda",
    "Argentina","Armenia","Aruba","Australia","Austria","Azerbaijan","Bahamas","Bahrain","Bangladesh",
    "Barbados","Belarus","Belgium","Belize","Benin","Bermuda","Bhutan","Bolivia","Bosnia and Herzegovina","Botswana","Brazil","British Virgin Islands",
    "Brunei","Bulgaria","Burkina Faso","Burundi","Cambodia","Cameroon","Cape Verde","Cayman Islands","Chad","Chile","China","Colombia","Congo","Cook Islands",
    "Costa Rica","Cote D Ivoire","Croatia","Cruise Ship","Cuba","Cyprus","Czech Republic","Denmark","Djibouti","Dominica","Dominican Republic","Ecuador","Egypt",
    "El Salvador","Equatorial Guinea","Estonia","Ethiopia","Falkland Islands","Faroe Islands","Fiji","Finland","France","French Polynesia","French West Indies","Gabon",
    "Gambia","Georgia","Germany","Ghana","Gibraltar","Greece","Greenland","Grenada","Guam","Guatemala","Guernsey","Guinea","Guinea Bissau","Guyana","Haiti","Honduras","Hong Kong",
    "Hungary","Iceland","India","Indonesia","Iran","Iraq","Ireland","Isle of Man","Israel","Italy","Jamaica","Japan","Jersey","Jordan","Kazakhstan","Kenya","Kuwait","Kyrgyz Republic","Laos","Latvia","Lebanon",
    "Lesotho","Liberia","Libya","Liechtenstein","Lithuania","Luxembourg","Macau","Macedonia","Madagascar","Malawi","Malaysia","Maldives","Mali","Malta","Mauritania","Mauritius","Mexico","Moldova",
    "Monaco","Mongolia","Montenegro","Montserrat","Morocco","Mozambique","Namibia","Nepal","Netherlands","Netherlands Antilles","New Caledonia","New Zealand","Nicaragua","Niger","Nigeria","Norway","Oman",
    "Pakistan","Palestine","Panama","Papua New Guinea","Paraguay","Peru","Philippines","Poland","Portugal","Puerto Rico","Qatar","Reunion","Romania","Russia","Rwanda","Saint Pierre and Miquelon","Samoa",
    "San Marino","Saudi Arabia","Senegal","Serbia","Seychelles","Sierra Leone","Singapore","Slovakia","Slovenia","South Africa","South Korea","Spain","Sri Lanka","St Kitts and Nevis","St Lucia","St Vincent","St. Lucia","Sudan","Suriname",
    "Swaziland","Sweden","Switzerland","Syria","Taiwan","Tajikistan","Tanzania","Thailand","Timor L'Este","Togo","Tonga","Trinidad and Tobago","Tunisia","Turkey","Turkmenistan","Turks and Caicos","Uganda","Ukraine","United Arab Emirates",
    "Uruguay","Uzbekistan","Venezuela","Vietnam","Virgin Islands (US)","Yemen","Zambia","Zimbabwe"
];

export const heights = [
    "4 feet 0 inches",  "4 feet 1 inches",  "4 feet 2 inches",  "4 feet 3 inches",   "4 feet 4 inches",  "4 feet 5 inches",  "4 feet 6 inches",  "4 feet 7 inches",  "4 feet 8 inches", "4 feet 9 inches",  "4 feet 10 inches", "4 feet 11 inches", 
    "5 feet 0 inches", "5 feet 1 inches", "5 feet 2 inches", "5 feet 3 inches", "5 feet 4 inches", "5 feet 5 inches", "5 feet 6 inches", "5 feet 7 inches", "5 feet 8 inches", "5 feet 9 inches", "5 feet 10 inches","5 feet 11 inches", 
    "6 feet 0 inches", "6 feet 1 inches", "6 feet 2 inches", "6 feet 3 inches", "6 feet 4 inches", "6 feet 5 inches", "6 feet 6 inches", "6 feet 7 inches",
    "6 feet 8 inches", "6 feet 9 inches", "6 feet 10 inches", "6 feet 11 inches",
    "7 feet 0 inches", "7 feet 1 inches",  "7 feet 2 inches",  "7 feet 3 inches",  "7 feet 4 inches",  "7 feet 5 inches", "7 feet 6 inches", "7 feet 7 inches", "7 feet 8 inches", "7 feet 9 inches", "7 feet 10 inches",  "7 feet 11 inches", 
]

export const weights = Array.from({ length: 321 }, (_, index) => 80 + index);

// Fix correspondin values
export const cms = Array.from({ length: 123 }, (_, index) => 122 + index);

export const kgs = Array.from({ length: 147 }, (_, index) => 36 + index);

export  const convertHeight = (newMetric, currentHeight) => {
    if (newMetric === 'cm' && currentHeight !== '0') {
        // Convert feet and inches to centimeters
        const match = currentHeight.match(/(\d+) feet (\d+) inches/);
        if (match) {
            const feet = parseInt(match[1]);
            const inches = parseInt(match[2]);
            const totalInches = feet * 12 + inches;
            const centimeters = totalInches * 2.54; // 1 inch = 2.54 cm
            return Math.round(centimeters); // Round to the nearest whole number
        }
    } else {
        if(currentHeight !== ""){
            // Convert centimeters to feet and inches
            const inches = currentHeight / 2.54; // 1 cm = 1/2.54 inches
            const feet = Math.floor(inches / 12);
            const remainingInches = inches % 12;
            return `${feet} feet ${remainingInches.toFixed()} inches`;
        } else {
            return ""
        }
    }
};

export const convertWeight = (newMetric, currentWeight) => {
    if (newMetric === 'kg' && currentWeight !== 0) {
        // Convert pounds to kilograms (1 lb = 0.453592 kg)
        const kilograms = currentWeight * 0.453592;
        return Math.round(kilograms); // Round to the nearest whole number
    } else if (newMetric === 'lb') {
        // Convert kilograms to pounds (1 kg = 2.20462 lbs)
        const pounds = currentWeight * 2.20462;
        return Math.round(pounds); // Round to the nearest whole number
    }
    // Handle other cases as needed
    return currentWeight;
};

export const generateWeightMenuItems = (metric) => {
    const weightArray = metric === "lb" ? weights : kgs;
    return weightArray.map((weight, index) => (
        <MenuItem disableRipple key={index} value={weight}>
            {weight}
        </MenuItem>
    ));
};

export const validateEmail = (email) => {
    return validator.isEmail(email);
};
  
export const validatePassword = (password) => {
    return validator.isStrongPassword(password, {
        minLength: 8, minLowercase: 1,
        minUppercase: 1, minNumbers: 1,  minSymbols: 0
    })
};

export const validatePasswordMatch = (password, secondPassword) => {
    return password === secondPassword;
};

export const isFormValid = (form) => {
    for (const key in form) {
        if (!form[key]) {
        return false;
        }
    }
    return true;
};

export const isUpdateFormValid = (form) => {
    for (const key in form) {
        if (form[key] !== false) {
        return false;
        }
    }
    return true;
};

export const isInviteFormValid = (form) => {
    const requiredFields = ['first', 'last', 'email'];

    for (const key of requiredFields) {
        if (!form[key]) {
            return false;
        }
    }

    return true;
};

const sanitizeName = (name) => {
    if (name === null || name === undefined || name === "") {
        return null;
    }

    const sanitized = name.trim();
    return sanitized;
};

export function isStringLongEnough(str) {
    return str.length >= 2;
}

export const isWithinCharacterLimit = (value) => {
    return value.length <= 30;
};

const validateAndCorrectMetrics = (metric) => {
    if (metric === null) {
        return null;
    }

    const lowerCaseMetric = metric.toLowerCase().trim();

    if (lowerCaseMetric.includes("lb")) {
        return "lb"; 
    } else if (lowerCaseMetric.includes("kg")) {
        return "kg"; 
    } else if (lowerCaseMetric.includes("in")) {
        return "in";
    } else if (lowerCaseMetric.includes("cm")) {
        return "cm"; 
    } else {
        return null;
    }
};

const validateAndCorrectDominant = (dominantValue) => {
    if (dominantValue === null) {
        return null; // Return null if the input is null
    }
    const lowerCaseValue = dominantValue.toLowerCase().trim();

    if (lowerCaseValue.includes("left")) {
        return "left"; 
    } else if (lowerCaseValue.includes("right")) {
        return "right"; 
    } else {
        return null;
    }
};

const validateAndCorrectGender = (genderValue) => {
if (genderValue === null) {
    return null; // Return null if the input is null
}
const lowerCaseValue = genderValue.toLowerCase().trim();

if (lowerCaseValue.includes("male")) {
    return "male"; 
} else if (lowerCaseValue.includes("female")) {
    return "female"; 
} else {
    return null;
}
};

function roundToWholeNumber(number) {
if (number === null) {
    return null; // Return null if the input is null
}
// Check if the input is a valid number
if (typeof number !== 'number' || isNaN(number)) {
    return null; // Return null for invalid input
}

return Math.round(number); // Round to the nearest whole number
}

export const sanitizeUserData = (userData) => {
const sanitizedUserData = {
    ...userData,
    first: sanitizeName(userData.first),
    last: sanitizeName(userData.last),
    height: roundToWholeNumber(parseFloat(userData.height)),
    weight: roundToWholeNumber(parseFloat(userData.weight)),
    heightMetric: validateAndCorrectMetrics(userData.heightMetric),
    weightMetric: validateAndCorrectMetrics(userData.weightMetric),
    dominantArm: validateAndCorrectDominant(userData.dominantArm),
    dominantLeg: validateAndCorrectDominant(userData.dominantLeg),
    gender: validateAndCorrectGender(userData.gender)
};

return sanitizedUserData;
};

export const feetAndInchesConversion = (feet, inches) => {
let newinches
newinches = feet * 12
newinches = parseInt(newinches + parseInt(inches))
return newinches
};

let newinches
export const feetConversion = (height) => {
  let array =  height.split(" ")
  let feet = array[0]
    let inches = array[2]
    newinches = feet * 12
    newinches = parseInt(newinches + parseInt(inches))
    return newinches
};

// ----------------------------------------


export function processNewUserObjectForInvite(userObject) {
    // Check for required fields
    if (!userObject.orgId || !userObject.email || !userObject.first || !userObject.last) {
      throw new Error("Required fields missing");
    }
  
    // Check and update dateOfBirth
    if (userObject.dateOfBirth === "--") {
      userObject.dateOfBirth = null;
    }
  
    // Convert empty strings to null for other fields
    for (const key in userObject) {
      if (userObject[key] === "") {
        userObject[key] = null;
      }
    }
  
    return userObject;
  }