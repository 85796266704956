import React from "react";
import PropTypes from 'prop-types';
// @mui
import { Drawer, Divider, Box } from "@mui/material/";
// Hooks
import useResponsive from "../../hooks/useResponsive";
// Components
import OrgDropDownList from "./OrgDropDownList";
import List from "./ListItems";
import SmallNextilesLogo from "../../assets/img/SmallNextilesLogo.svg";
//Utils
import { navDrawerStyle } from "../../utils/styles";
// ----------------------------------------------------------------------

const drawerWidth = "15rem";

export default function Nav({
  open,
  onCloseNav,
  admin,
  activeOrgs,
  orgId,
  setOrgId,
  setActiveOrgs,
}) {
  const isDesktop = useResponsive("up", "lg");

  const renderContent = (
    <>
      <Box 
        pl={1}
        component="img" 
        src={SmallNextilesLogo}
      />

      <OrgDropDownList
        activeOrgs={activeOrgs}
        setActiveOrgs={setActiveOrgs}
        orgId={orgId}
        setOrgId={setOrgId}
      />

      <Divider />

      <List admin={admin} onCloseNav={onCloseNav}/>

    </>
  );

  return (
    <Box
      component="nav"
      sx={{
          flexShrink: { lg: 0 },
          width: { lg: drawerWidth },
      }}
    >
      <Drawer
        PaperProps={{
          sx: { 
          ...(isDesktop ?
            {
              ...navDrawerStyle,
              width: drawerWidth
            }
            : 
            {
              border: "none",
              boxSizing: "border-box",
              gap: 0.5,
              width: drawerWidth
            }),
          },
        }}
        open={isDesktop || open}
        onClose={!isDesktop ? onCloseNav : undefined}
        variant={isDesktop ? "permanent" : "temporary"}
        anchor="left"
        ModalProps={{ keepMounted: !isDesktop }}
      >
        {renderContent}
      </Drawer>
    </Box>
  );
}
