import React from "react";
import { Link } from "react-router-dom";
import {
  fancyTimeFormat,
  createActiveChip,
  createAthleteAvatar,
  createProgressBar,
  formatSessionDate,
  createChips,
  createSessionTypeChips,
} from "./utilityFunctions";
import {
  Stack,
  Avatar,
  Typography,
  IconButton,
  Checkbox,
  Tooltip,
  Chip,
  Box,
} from "@mui/material";
import {
  MoreVert,
  EditOutlined,
  InfoOutlined,
  FileDownloadOutlined,
  FileDownloadOffOutlined,
} from "@mui/icons-material";
//images
import adminImage from "../assets/img/admin.jpg";
import flagImage from "../assets/img/Flag.png";
import unflagImage from "../assets/img/unflag.jpg";
// Sorting for Tables
// -----------------------------
export function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

export function getComparator(order, orderBy) {
  return order === "asc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export const handleCheckBoxClick = (
  event,
  name,
  sessionId,
  userId,
  session,
  selected,
  containsTestSession,
  isValidSession
) => {
  const selectedIndex = selected.length
    ? selected?.map((item) => item.id).indexOf(name)
    : -1;
  let newSelected = [];
  let sessionDetails = [];

  if (selectedIndex === -1) {
    newSelected = newSelected.concat(selected, {
      id: name,
      containsTestSession: containsTestSession,
      isValidSession,
    });
    sessionDetails = sessionDetails.concat(session, {
      id: sessionId,
      userid: userId,
    });
  } else if (selectedIndex === 0) {
    newSelected = newSelected.concat(selected.slice(1));
    sessionDetails = sessionDetails.concat(session.slice(1));
  } else if (selectedIndex === selected.length - 1) {
    newSelected = newSelected.concat(selected.slice(0, -1));
    sessionDetails = sessionDetails.concat(session.slice(0, -1));
  } else if (selectedIndex > 0) {
    newSelected = newSelected.concat(
      selected.slice(0, selectedIndex),
      selected.slice(selectedIndex + 1)
    );
    sessionDetails = sessionDetails.concat(
      session.slice(0, selectedIndex),
      session.slice(selectedIndex + 1)
    );
  }

  return { newSelected, sessionDetails };
};

// Org Athlete Page (Table)
//--------------------------------------------
export const orgAthleteOptions = [
  <Stack direction="row">
    <Typography pr={1}>
      <EditOutlined />
    </Typography>
    <Typography variant="body1">Edit</Typography>
  </Stack>,
];

export const OrgAthleteColumns = [
  {
    id: "athletes",
    numeric: false,
    disablePadding: true,
    label: "Athletes Name",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "Status",
  },
  {
    id: "totalHours",
    numeric: false,
    disablePadding: false,
    label: "Total Time",
  },
  {
    id: "totalSessions",
    numeric: true,
    disablePadding: false,
    label: "Total Sessions",
  },
  {
    id: "progress",
    numeric: true,
    disablePadding: false,
    label: "Progress",
  },
  {
    id: "icon",
    numeric: false,
    disablePadding: false,
    label: "Action",
  },
];

export function createOrgAthleteData(
  users,
  activities,
  orgId,
  handleButtonClick,
  params
) {
  const userCards = users.map((user, index) => {
    const activeStatus = user.admin_settings.status;
    const totalHours = fancyTimeFormat(
      user.session_history_metadata.total_duration
    );
    const level = user.admin_settings.level;
    const missingMetaData = user.incomplete_fields?.length ? true : false;
    return {
      userId: `${user.user_id}`,
      athletes: (
        <Stack>
          <Stack direction="row" alignItems="center">
            <Typography pr={1}>
              {user.type === "admin" ? (
                <Box
                  component="img"
                  src={adminImage}
                  sx={{ height: 35, width: 35 }}
                />
              ) : (
                <Avatar sx={{ height: 30, width: 30 }} />
              )}
            </Typography>

            {/* <Tooltip
              id={`tooltip-${user.name}`}
              placement="top"
              title={user.email}
            > */}
            <Stack direction="column">
              <Typography variant="body1" fontWeight={600} pl={0}>
                <Link
                  to={`/dashboard/athletepage/${user.name}`}
                  state={{
                    userId: user.user_id,
                    orgId: orgId,
                    name: user.name,
                    activities: activities,
                    params,
                  }}
                  style={{
                    textDecoration: "none",
                    color: "rgba(33, 32, 33, 1)",
                  }}
                >
                  {user.name}&nbsp;&nbsp;
                  {!!missingMetaData && (
                    <Chip
                      label="Missing Metadata"
                      color="warning"
                      variant="outlined"
                      size="small"
                    />
                  )}
                </Link>
              </Typography>
              <div
                style={{
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  width: "13rem",
                }}
              >
                <Typography variant="subtitle1" noWrap color="#37474f">
                  {user.email}
                </Typography>
              </div>
            </Stack>
            {/* </Tooltip> */}
          </Stack>
        </Stack>
      ),
      activeStatus: activeStatus,
      status: createActiveChip(activeStatus),
      totalHours: totalHours,
      totalSessions: user.session_history_metadata.total_sessions,
      level: level,
      progress: createProgressBar(level),
      email: user.email,
      icon: (
        <IconButton>
          <MoreVert onClick={(e) => handleButtonClick(e, `${user.user_id}`)} />
        </IconButton>
      ),
      missingMetaData,
    };
  });

  return userCards;
}
//--------------------------------------------

// Athlete Session View Page (Table)
//--------------------------------------------
export const athleteSessionViewColumns = [
  {
    id: "session",
    numeric: false,
    disablePadding: true,
    label: "Session",
  },
  {
    id: "activities",
    numeric: false,
    disablePadding: false,
    label: "Activities",
  },
  {
    id: "totalTime",
    numeric: false,
    disablePadding: false,
    label: "Total Time",
  },
  {
    id: "totalReps",
    numeric: true,
    disablePadding: false,
    label: "Total Reps",
  },
  // {
  //   id: "sessionType",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Session Type",
  // },
  {
    id: "icon",
    numeric: false,
    disablePadding: true,
  },
];

export const createAthleteData = (
  session,
  activities,
  index,
  handleButtonClick,
  handleFlagClick
) => {
  const {
    session_number,
    date,
    user_id,
    org_id,
    session_id,
    name,
    session_metrics,
    activity_list,
    session_description,
    activity_metadata,
    session_status,
    flagged,
  } = session;
  const formattedDate = formatSessionDate(date);
  const activityName = activity_list[0]?.activity?.activity_name || "";

  const displayName = activity_list[0]?.activity?.display_name || "";
  const totalTime = fancyTimeFormat(`${session_metrics.duration}`);
  const totalReps = session_metrics.reps;
  const session_number_formatted = `Session #${session_number}`;
  let activityMetadata = "";

  if (
    activity_metadata?.additional_metadata?.[0]?.value &&
    activity_metadata?.additional_metadata?.[0]?.units
  ) {
    const activityMetadataValue =
      activity_metadata.additional_metadata[0].value;
    const activityMetadataUnits =
      activity_metadata.additional_metadata[0].units;
    activityMetadata = `${activityMetadataValue} ${activityMetadataUnits}`;
  }

  return {
    checkbox: <Checkbox color="primary" />,
    sessionId: session_id,
    session: (
      <>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Link
            to={`/dashboard/specificsession/${session_number_formatted}`}
            state={{
              sessionId: session_id,
              index: index,
              userId: user_id,
              orgId: org_id,
              date: formattedDate,
              sessionNumber: session_number,
              name: name,
              activity: activityName,
              display: displayName,
              athletePage: false,
            }}
            style={{ textDecoration: "none", color: "rgba(33, 32, 33, 1)" }}
          >
            <Typography fontWeight={600} variant="body1">
              {session_number_formatted}
            </Typography>
          </Link>
          {!!flagged && (
            <Box
              component="img"
              src={flagImage}
              sx={{ height: 18, width: 18 }}
              // onClick={() => handleFlagClick(session_id, user_id, !!flagged)}
            />
          )}
          {session_description.trim() !== "" && (
            <Tooltip title={session_description} placement="top">
              <InfoOutlined />
            </Tooltip>
          )}
        </Stack>
        <Typography variant="body2">{formattedDate} </Typography>
      </>
    ),
    date: formattedDate,
    userId: user_id,
    orgId: org_id,
    name: name,
    activity: activityName,
    display: displayName,
    activities: createChips(displayName, activities),
    totalTime: totalTime,
    totalReps: totalReps,
    sessionDescription: session_description,
    activityMetadata: activityMetadata,
    icon: (
      <>
        <IconButton
          id="long-button"
          onClick={(e) => {
            if (!totalReps) {
              return;
            }
            handleButtonClick(e, `${session_id}`, `${user_id}`, activityName);
          }}
        >
          <span style={!totalReps ? { cursor: "not-allowed" } : {}}>
            <Tooltip
              title={
                !totalReps
                  ? "Data can't be downloaded for an invalid session"
                  : ""
              }
              arrow
              placement="top"
            >
              {/* <MoreVert sx={{ color: "rgba(0, 0, 0, 0.54)" }} /> */}
              {!totalReps ? (
                <FileDownloadOffOutlined />
              ) : (
                <FileDownloadOutlined />
              )}
            </Tooltip>
          </span>
        </IconButton>
        <IconButton>
          <MoreVert
            onClick={(e) => handleFlagClick(e, session_id, user_id, flagged)}
          />
        </IconButton>
      </>
    ),
    // sessionType: createSessionTypeChips(session_status, flagged),
  };
};

// Coach Session View Page (Table)
//--------------------------------------------
export const adminSessionViewColumns = [
  {
    id: "session",
    numeric: false,
    disablePadding: true,
    label: "Sessions",
  },
  {
    id: "athlete",
    numeric: false,
    disablePadding: false,
    label: "Athlete",
  },
  {
    id: "activities",
    numeric: false,
    disablePadding: false,
    label: "Activities",
  },
  {
    id: "totalTime",
    numeric: true,
    disablePadding: false,
    label: "Total Time",
  },
  {
    id: "totalReps",
    numeric: true,
    disablePadding: false,
    label: "Total Reps",
  },
  // {
  //   id: "sessionType",
  //   numeric: false,
  //   disablePadding: false,
  //   label: "Session Type",
  // },
  {
    id: "icon",
    numeric: false,
    disablePadding: false,
  },
];

export const createCoachData = (
  session,
  activities,
  index,
  handleButtonClick,
  handleFlagClick
) => {
  const {
    session_number,
    date,
    user_id,
    org_id,
    session_id,
    name,
    session_metrics,
    activity_list,
    session_description,
    activity_metadata,
    session_status,
    flagged,
  } = session;
  const formattedDate = formatSessionDate(date);
  const activityName = activity_list?.[0]?.activity?.activity_name || "";
  const displayName = activity_list?.[0]?.activity?.display_name || "";
  const totalTime = fancyTimeFormat(`${session_metrics.duration}`);
  const totalReps = session_metrics.reps;
  const session_number_formatted = `Session #${session_number}`;
  let activityMetadata = "";

  if (
    activity_metadata?.additional_metadata?.[0]?.value &&
    activity_metadata?.additional_metadata?.[0]?.units
  ) {
    const activityMetadataValue =
      activity_metadata.additional_metadata[0].value;
    const activityMetadataUnits =
      activity_metadata.additional_metadata[0].units;
    activityMetadata = `${activityMetadataValue} ${activityMetadataUnits}`;
  }

  return {
    checkbox: <Checkbox color="primary" />,
    sessionId: session_id,
    session: (
      <>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Link
            to={`/dashboard/specificsession/${session_number_formatted}`}
            state={{
              sessionId: session_id,
              index: index,
              userId: user_id,
              orgId: org_id,
              date: formattedDate,
              sessionNumber: session_number,
              name: name,
              activity: activityName,
              display: displayName,
              athletePage: false,
            }}
            style={{ textDecoration: "none", color: "rgba(33, 32, 33, 1)" }}
          >
            <Typography fontWeight={600} variant="body1">
              {session_number_formatted}
            </Typography>
          </Link>
          {!!flagged && (
            <Box
              component="img"
              src={flagImage}
              sx={{ height: 18, width: 18 }}
              // onClick={() => handleFlagClick(session_id, user_id, !!flagged)}
            />
          )}
          {session_description.trim() !== "" && (
            <Tooltip title={session_description} placement="top">
              <InfoOutlined />
            </Tooltip>
          )}
        </Stack>
        <Typography variant="body2">{formattedDate} </Typography>
      </>
    ),
    date: formattedDate,
    userId: user_id,
    orgId: org_id,
    name: name,
    athlete: createAthleteAvatar(name),
    activity: activityName,
    displayName: displayName,
    activities: createChips(displayName, activities),
    totalTime: totalTime,
    totalReps: totalReps,
    sessionDescription: session_description,
    activityMetadata: activityMetadata,
    icon: (
      <>
        <IconButton
          id="long-button"
          onClick={(e) => {
            if (!totalReps) {
              return;
            }
            handleButtonClick(e, `${session_id}`, `${user_id}`, activityName);
          }}
        >
          <span style={!totalReps ? { cursor: "not-allowed" } : {}}>
            <Tooltip
              title={
                !totalReps
                  ? "Data can't be downloaded for an invalid session"
                  : ""
              }
              arrow
              placement="top"
            >
              {/* <MoreVert sx={{ color: "rgba(0, 0, 0, 0.54)" }} /> */}
              {!totalReps ? (
                <FileDownloadOffOutlined />
              ) : (
                <FileDownloadOutlined />
              )}
            </Tooltip>
          </span>
        </IconButton>
        <IconButton>
          <MoreVert
            onClick={(e) => handleFlagClick(e, session_id, user_id, flagged)}
          />
        </IconButton>
      </>
    ),
    // sessionType: createSessionTypeChips(session_status, flagged),
  };
};
//--------------------------------------------
