import React from "react";
import {
  Box,
  Chip,
  Stack,
  Avatar,
  Typography,
  LinearProgress,
} from "@mui/material";
import { linearProgressClasses } from "@mui/material/LinearProgress";
import { downloadSpecificSessionData } from "../api/sessionRoutes";
import validator from "validator";
//assets
import flagImage from "../assets/img/Flag.png";
// -----------------------------
export function fancyTimeFormat(duration) {
  const hrs = ~~(duration / 3600);
  const mins = ~~((duration % 3600) / 60);
  const secs = ~~duration % 60;

  let ret = "";

  if (hrs > 0) {
    ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
  }
  if (hrs === 0) {
    ret += "00" + ":" + (mins < 10 ? "0" : "");
  }

  ret += "" + mins + ":" + (secs < 10 ? "0" : "");
  ret += "" + secs;

  return ret;
}

export const getWeekDates = () => {
  const week = [];
  const curr = new Date();
  for (let i = 6; i >= 0; i--) {
    const day = new Date(curr);
    day.setDate(curr.getDate() - i);
    const [year, month, date] = [
      day.getFullYear(),
      (day.getMonth() + 1).toString().padStart(2, "0"),
      day.getDate().toString().padStart(2, "0"),
    ];
    week.push(`${year}-${month}-${date}`);
  }
  return week;
};

export const getMonthDates = () => {
  const dates = [];
  const curr = new Date();
  const endDate = new Date(curr);
  endDate.setDate(curr.getDate()); // Include the current day
  const startDate = new Date(endDate);
  startDate.setDate(endDate.getDate() - 30);

  while (startDate <= endDate) {
    const [year, month, date] = [
      startDate.getFullYear(),
      (startDate.getMonth() + 1).toString().padStart(2, "0"),
      startDate.getDate().toString().padStart(2, "0"),
    ];
    dates.push(`${year}-${month}-${date}`);
    startDate.setDate(startDate.getDate() + 1);
  }

  return dates;
};

export const getYearDates = () => {
  const dates = [];
  const curr = new Date();
  let year = curr.getFullYear();
  let month = curr.getMonth() + 1; // Months are zero-indexed

  for (let i = 0; i < 12; i++) {
    const formattedMonth = month.toString().padStart(2, "0");
    dates.push(`${year}-${formattedMonth}`);

    // Decrement the month for the next iteration
    month--;

    // Adjust the year when the month goes below 1 (January)
    if (month === 0) {
      month = 12;
      year--;
    }
  }

  return dates.reverse();
};

// ISO Time
export function toISOLocal(d) {
  var z = (n) => ("0" + n).slice(-2);
  var zz = (n) => ("00" + n).slice(-3);
  var off = d.getTimezoneOffset();
  var sign = off > 0 ? "-" : "+";
  off = Math.abs(off);

  return (
    d.getFullYear() +
    "-" +
    z(d.getMonth() + 1) +
    "-" +
    z(d.getDate()) +
    "T" +
    z(d.getHours()) +
    ":" +
    z(d.getMinutes()) +
    ":" +
    z(d.getSeconds()) +
    "." +
    zz(d.getMilliseconds()) +
    sign +
    z((off / 60) | 0) +
    ":" +
    z(off % 60)
  );
}

// Create Status Chip
export function createActiveChip(status) {
  if (status === "Active") {
    return (
      <Chip
        label={
          <Box fontWeight={400} fontSize={14}>
            {status}
          </Box>
        }
        sx={{
          width: "auto",
          backgroundColor: "rgba(47, 124, 50, 0.04)",
          border: "1px solid rgba(75, 175, 80, 1) ",
        }}
        variant="outlined"
      />
    );
  }
  if (status === "Risk of Injury") {
    return (
      <Chip
        label={
          <Box fontWeight={400} fontSize={14}>
            {status}
          </Box>
        }
        sx={{
          width: "auto",
          backgroundColor: "rgba(237, 109, 2, 0.04)",
          border: "1px solid rgba(255, 152, 0, 1)",
        }}
        variant="outlined"
      />
    );
  }
  if (status === "Injured" || "Inactive") {
    return (
      <Chip
        label={
          <Box fontWeight={400} fontSize={14}>
            {status}
          </Box>
        }
        sx={{
          width: "auto",
          backgroundColor: "rgba(212, 47, 48, 0.04)",
          border: "1px solid rgba(239, 84, 80, 1)",
        }}
        variant="outlined"
      />
    );
  }
}

//Athlete Progress Bar
export function createProgressBar(number) {
  let num = number;

  if (num <= 30) {
    return (
      <Box sx={{ flexGrow: 1 }}>
        <LinearProgress
          variant="determinate"
          value={num}
          sx={{
            height: 4,
            [`&.${linearProgressClasses.colorPrimary}`]: {
              backgroundColor: "#ffbfbf",
            },
            "& .MuiLinearProgress-bar": {
              backgroundColor: "rgba(212, 47, 48, 1)",
            },
          }}
        />
      </Box>
    );
  }

  if (num <= 50) {
    return (
      <Box sx={{ flexGrow: 1 }}>
        <LinearProgress
          variant="determinate"
          value={num}
          sx={{
            height: 4,
            [`&.${linearProgressClasses.colorPrimary}`]: {
              backgroundColor: "#fceacf",
            },
            "& .MuiLinearProgress-bar": {
              backgroundColor: "rgba(236, 108, 1, 1)",
            },
          }}
        />
      </Box>
    );
  }

  if (num <= 75) {
    return (
      <Box sx={{ flexGrow: 1 }}>
        <LinearProgress
          variant="determinate"
          value={num}
          sx={{
            height: 4,
            [`&.${linearProgressClasses.colorPrimary}`]: {
              backgroundColor: "#fceacf",
            },
            "& .MuiLinearProgress-bar": {
              backgroundColor: "#EC6C01",
            },
          }}
        />
      </Box>
    );
  }

  if (num <= 100) {
    return (
      <Box sx={{ flexGrow: 1 }}>
        <LinearProgress
          variant="determinate"
          value={num}
          sx={{
            height: 4,
            [`&.${linearProgressClasses.colorPrimary}`]: {
              backgroundColor: "#d4ffd6",
            },
            "& .MuiLinearProgress-bar": {
              backgroundColor: "rgba(47, 124, 50, 1)",
            },
          }}
        />
      </Box>
    );
  }
}

// Create Activity Chips
export function createChips(label, activities) {
  if (!activities) return null;

  const { display_color, display_name } =
    activities.find((activity) => activity.display_name === label) || {};
  const color = display_color || "#e4e4e4";

  return (
    <Chip
      label={
        <Box fontWeight={500} fontSize={14}>
          {label}
        </Box>
      }
      sx={{ width: "auto", pl: 1 }}
      variant="outlined"
      icon={
        <svg width="4" height="14" viewBox="0 0 4 14" fill="none">
          <path d="M2 0.5V13.5" stroke={color} strokeWidth="3" />
        </svg>
      }
    />
  );
}

// Create SessionType Chips
export function createSessionTypeChips(label, isFlagged) {
  return (
    <Chip
      color={label === "Valid" ? "success" : "error"}
      label={
        <Box fontWeight={550} fontSize={14}>
          {label}
        </Box>
      }
      sx={{ width: "auto", pl: isFlagged ? 1 : 0 }}
      variant="outlined"
      icon={
        !!isFlagged && (
          <Box component="img" src={flagImage} sx={{ height: 20, width: 20 }} />
        )
      }
    />
  );
}

// Activity Color
export function getActivityColor(display, activityName, activities) {
  let color;
  if (activities) {
    const activityObj = activities.find(
      (activity) => activity.activity_id === activityName
    );
    color = activityObj ? activityObj.display_color : "#e4e4e4";
  }
  return color;
}

// Status Bar Style
export const getLinearProgressStyles = (level, linearProgressClasses) => {
  if (level < 30) {
    return {
      [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: "#ffbfbf",
      },
      "& .MuiLinearProgress-bar": {
        backgroundColor: "rgba(212, 47, 48, 1)",
      },
    };
  } else if (level < 50) {
    return {
      [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: "#fceacf",
      },
      "& .MuiLinearProgress-bar": {
        backgroundColor: "rgba(236, 108, 1, 1)",
      },
    };
  } else if (level < 75) {
    return {
      [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: "#fceacf",
      },
      "& .MuiLinearProgress-bar": {
        backgroundColor: "#EC6C01",
      },
    };
  } else {
    return {
      [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: "#d4ffd6",
      },
      "& .MuiLinearProgress-bar": {
        backgroundColor: "rgba(47, 124, 50, 1)",
      },
    };
  }
};

// Get Activity Id
export function getActivityId(activityType, activities) {
  return activityType === "All Activities"
    ? activityType
    : activities.find((activity) => activity.display_name === activityType)
        .activity_id;
}

// Calculate Start Time and End Time Function
export function getFormattedDateWithTimezone(period) {
  const today = new Date();
  let startTime;

  if (period === "week") {
    startTime = new Date(today);
    startTime.setDate(today.getDate() - 6);
  } else if (period === "month") {
    startTime = new Date(
      today.getFullYear(),
      today.getMonth() - 1,
      today.getDate()
    );
  } else if (period === "year") {
    startTime = new Date(
      today.getFullYear() - 1,
      today.getMonth(),
      today.getDate()
    );
  } else {
    throw new Error("Invalid period. Valid values are: week, month, year");
  }

  const formatTime = (time) => {
    return String(time).padStart(2, "0");
  };

  const timeZoneOffset = new Date().getTimezoneOffset();
  const timeZoneOffsetHours = Math.floor(Math.abs(timeZoneOffset) / 60);
  const timeZoneOffsetMinutes = Math.abs(timeZoneOffset) % 60;
  const timeZoneSign = timeZoneOffset < 0 ? "+" : "-";

  const formattedStartDate = `${startTime.getFullYear()}-${formatTime(
    startTime.getMonth() + 1
  )}-${formatTime(startTime.getDate())}T00:00:00.000${timeZoneSign}${formatTime(
    timeZoneOffsetHours
  )}:${formatTime(timeZoneOffsetMinutes)}`;
  const formattedEndDate = `${today.getFullYear()}-${formatTime(
    today.getMonth() + 1
  )}-${formatTime(today.getDate())}T${formatTime(
    today.getHours()
  )}:${formatTime(today.getMinutes())}:${formatTime(
    today.getSeconds()
  )}.000${timeZoneSign}${formatTime(timeZoneOffsetHours)}:${formatTime(
    timeZoneOffsetMinutes
  )}`;

  const encodedStartDate = encodeURIComponent(formattedStartDate);
  const encodedEndDate = encodeURIComponent(formattedEndDate);

  return {
    stime: encodedStartDate,
    etime: encodedEndDate,
  };
  // return {
  //   stime: formattedStartDate,
  //   etime: formattedEndDate
  // };
}

// Generate Chart Labels
export function generateChartLabels(time) {
  const currentDate = new Date();
  let chartLabels = [];

  if (time === "year") {
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    for (let i = 11; i >= 0; i--) {
      const date = new Date(currentDate);
      date.setMonth(currentDate.getMonth() - i); // Add i months to the current date
      const monthIndex = date.getMonth();
      chartLabels.push(months[monthIndex]); // Add the month label to the array
    }
  } else if (time == "week") {
    const daysOfWeek = ["Sun", "Mon", "Tues", "Wed", "Thurs", "Fri", "Sat"];
    for (let i = 0; i < 7; i++) {
      const date = new Date(currentDate);
      date.setDate(currentDate.getDate() - i); // Subtract i days from the current date
      const dayOfWeekIndex = date.getDay();
      chartLabels.unshift(daysOfWeek[dayOfWeekIndex]); // Add the day of week label to the beginning of the array
    }
  } else {
    for (let i = 30; i >= 0; i--) {
      const date = new Date(currentDate);
      date.setDate(currentDate.getDate() - i); // Subtract i days from the current date
      const month = date.getMonth() + 1; // Months are zero-indexed, so add 1
      const day = date.getDate();
      chartLabels.push(`${month}/${day}`);
    }
  }
  return chartLabels;
}

// Format Session Date
export const formatSessionDate = (date) => {
  const hours = date.slice(11, 19);
  const formattedDate = date.slice(0, 10).concat(` ${hours}`);
  return formattedDate;
};

// Create Athlete Avatar
export function createAthleteAvatar(athleteName) {
  return (
    <Stack direction="row" alignItems="center" spacing={1}>
      <Avatar sx={{ width: 26, height: 26 }} />
      <Typography variant="body1" fontWeight={600}>
        {athleteName}
      </Typography>
    </Stack>
  );
}

// Downloading - move this to its on files
// Download CSV File depending on file type
export const downloadFiles = async (
  userId,
  orgId,
  sessionId,
  fileType,
  datadogMsg,
  params
) => {
  try {
    const sessionData = await downloadSpecificSessionData(
      userId,
      orgId,
      sessionId,
      fileType,
      datadogMsg,
      params
    );
    const link = document.createElement("a");
    link.href = sessionData.data.download_url;
    link.download = "data.csv";
    link.click();
    return { success: true };
  } catch (err) {
    console.error("Error downloading file:", err);
    return { success: false };
  }
};

// Download Multiple CSV Files
export async function fetchAndDownloadSessionData(
  userId,
  orgId,
  sessionIds,
  type
) {
  try {
    // should fetch function
    const response = await fetch(
      `/session/specificsessiondata/${userId}/${orgId}/${sessionIds}/${type}`
    );
    if (response.status === 200) {
      const sessionData = await response.json();
      const { download_url } = sessionData.data;
      const link = document.createElement("a");
      link.href = download_url;
      link.download = "data.csv";
      link.click();
      return true;
    } else {
      throw new Error(`Error: HTTP status ${response.status}`);
    }
  } catch (error) {
    console.error(error);
    return false;
  }
}

let newinches;
export const feetConversion = (height) => {
  let array = height.split(" ");
  let feet = array[0];
  let inches = array[2];
  newinches = feet * 12;
  newinches = parseInt(newinches + parseInt(inches));
  return newinches;
};

export const validateEmail = (email) => {
  return validator.isEmail(email);
};

//check if date is valid or not
export const isValidDate = (d) => {
  return d instanceof Date && !isNaN(d);
};
