import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
// @mui
import {
  Menu,
  Container,
  Typography,
  Stack,
  Button,
  Box,
  Chip,
  Grid,
  Breadcrumbs,
  Tooltip,
  Popover,
  Skeleton,
} from "@mui/material";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";
// @mui-icons
import {
  FormatListBulleted,
  LeaderboardOutlined,
  AccountTreeOutlined,
  ArrowForwardIos,
  ArrowBackIos,
  FileDownloadOutlined,
} from "@mui/icons-material";
// APIs
import {
  fetchAllOrgSessions,
  fetchAllUserSessionsInOrg,
  fetchSpecificSessionData,
} from "../../api/sessionRoutes";
// Components
import LineGraph from "../../sections/specificsession/LineGraph";
import SessionGraphCards from "../../sections/specificsession/SessionGraphCards";
// Utils
import { whiteButtonStyle } from "../../utils/styles";
import { getActivityColor, downloadFiles } from "../../utils/utilityFunctions";
import DownloadSelect from "../../components/selects/DownloadSelect";
// import { withTimingAndLogging } from '../../utils/logFunctions/logs';
// ----------------------------------------------------------------------

export default function SpecificSessionPage({ admin, activities, orgId }) {
  const location = useLocation();
  const navigate = useNavigate();
  const { download_raw, download_analyzed } = JSON.parse(
    localStorage.getItem("expiryDetails")
  );
  const [analyzedSession, setAnalyzedSession] = useState(null);
  const [plotKeys, setPlotKeys] = useState([]);
  const [sessions, setSessions] = useState("");
  const [userId, setUserId] = useState(location.state?.userId);
  const [currentIndex, setCurrentIndex] = useState(location.state?.index);
  const [metaSessionData, setMetaSessionData] = useState(null);
  const [activityMetaData, setActivityMetaData] = useState(null);
  const [sessionId, setSessionId] = useState(location.state?.sessionId);
  const [athleteStatus, setAthleteStatus] = useState(
    location.state?.athletePage
  );
  const [activity, setActivity] = useState(location.state?.activity);
  const [loading, setLoading] = useState(true);
  const [loadingAnalyzed, setLoadingAnalyzed] = useState(false);
  const [loadingRaw, setLoadingRaw] = useState(false);
  const [clickCount, setClickCount] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [disableAnalyzedButton, setDisableAnalyzedButton] = useState(false);
  const [selectedGraphCardIndex, setSelectedGraphCardIndex] = useState([]);
  const [sessionInfo, setSessionInfo] = useState({
    name: "",
    session_number: "",
    date: "",
    session_description: "",
    capitalizedDeviceType: "",
    capitalizedDevicePlacement: "",
    color: "",
    isTestSession: false,
    length: 0,
    dateAndTime: "",
  });
  const [state, setState] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const open = Boolean(anchorEl2);

  const handleSessionClick = () => {
    setClickCount((prevCount) => prevCount + 1);
  };

  const enableCopy = async () => {
    const textToCopy = location.state.sessionId;

    try {
      await navigator.clipboard.writeText(textToCopy);
      setAnchorEl(true);
    } catch (err) {
      console.error("Unable to copy session id to clipboard", err);
    }

    setClickCount(0);

    setTimeout(() => {
      setAnchorEl(false);
    }, 4000);
  };

  useEffect(() => {
    if (clickCount === 3) {
      enableCopy();
    }
  }, [clickCount]);

  const handleNewerClick = (value) => {
    const prevIndex = currentIndex - 1;
    let sessionList = sessions.data;
    if (prevIndex >= 0 && prevIndex < sessionList.length) {
      const prevSession = sessionList[prevIndex];
      setCurrentIndex(prevIndex);
      setUserId(prevSession.user_id);
      setSessionId(prevSession.session_id);
    }
  };

  const handlePreviousClick = (value) => {
    const prevIndex = currentIndex + 1;
    let sessionList = sessions.data;
    if (prevIndex >= 0 && prevIndex < sessionList.length) {
      const prevSession = sessionList[prevIndex];
      setCurrentIndex(prevIndex);
      setUserId(prevSession.user_id);
      setSessionId(prevSession.session_id);
    }
  };

  const commonStyles = {
    borderRadius: 1,
    border: 1,
    maxWidth: "30rem",
    maxHeight: "4rem",
    width: "fit-content",
    minWidth: "fit-content",
  };

  useEffect(() => {
    if (!location.state?.sessionId) {
      navigate("/dashboard/sessions");
    }

    async function fetchData() {
      try {
        let data;

        if (admin && !athleteStatus) {
          data = await fetchAllOrgSessions(orgId);
        } else {
          data = await fetchAllUserSessionsInOrg(userId, orgId);
        }

        setSessions(data.data);
      } catch (err) {
        console.log(err);
      }
    }

    fetchData();
  }, [orgId]);

  const deviceMap = {
    "P-ARM_SLEEVE": "Arm Sleeve",
    "P-SURFACE_FABRIC": "Fabric Force Plate",
    "P-KNEE": "Knee Sleeve",
    "P-FACEMASK": "Facemask",
  };

  useEffect(() => {
    setLoading(true);
    setAnalyzedSession(null);
    setActivity("");

    const fetchData = async (userId, orgId, sessionId) => {
      const fetchFunction = () =>
        fetchSpecificSessionData(userId, orgId, sessionId);
      const params = { user_id: userId, org_id: orgId, session_id: sessionId };
      const funcName = "fetchSpecificSessionData";
      const response = await fetchSpecificSessionData(
        userId,
        orgId,
        sessionId,
        "Specific session data has been fetched.",
        params
      );

      const { metaSessionData, analyzedSessionData } = response.data;
      setMetaSessionData(metaSessionData);
      setAnalyzedSession(analyzedSessionData.events);
      setPlotKeys(analyzedSessionData.plot_keys);
      setActivity(metaSessionData.activity_list[0].activity.activity_name);

      if (
        metaSessionData.activity_metadata?.additional_metadata?.[0]?.value &&
        metaSessionData.activity_metadata?.additional_metadata?.[0]?.units
      ) {
        const units =
          metaSessionData.activity_metadata.additional_metadata[0].units;
        const value =
          metaSessionData.activity_metadata.additional_metadata[0].value;
        setActivityMetaData(`${value} ${units}`);
      }

      const { name, session_number, date, session_description, activity_list } =
        metaSessionData;

      const device_info = activity_list[0].device_list[0];
      const activity_name = activity_list[0].activity.activity_name;
      const device_type = device_info.device_type;
      const capitalizedDeviceType = deviceMap[device_type];
      const device_placement = device_info.placement.split("_").join(" ");
      const capitalizedDevicePlacement =
        device_placement.charAt(0).toUpperCase() + device_placement.slice(1);

      const color = getActivityColor(
        activity_list[0].activity.display_name,
        activity,
        activities
      );
      const isTestSession = activity_name.includes("A-TEST");
      setDisableAnalyzedButton(isTestSession);
      const length = analyzedSessionData.length;
      const dateAndTime = date.slice(0, 19).split("T").join(" ");

      setSessionInfo({
        name,
        session_number,
        date: dateAndTime,
        session_description,
        capitalizedDeviceType,
        capitalizedDevicePlacement,
        color,
        isTestSession,
        length,
      });
      setLoading(false);
    };

    if (userId && orgId && sessionId) {
      fetchData(userId, orgId, sessionId);
    }
  }, [sessionId]);

  const {
    name,
    session_number,
    date,
    session_description,
    capitalizedDeviceType,
    capitalizedDevicePlacement,
    color,
    isTestSession,
    length,
  } = sessionInfo;

  const handleClick = async (id, org, session, type, setLoading) => {
    setLoading(true);
    const fetchFunction = () => downloadFiles(id, org, session, type);
    const params = {
      user_id: id,
      org_id: org,
      session_id: session,
      type: type,
    };
    const funcName = "downloadFiles";

    // const response = await withTimingAndLogging(fetchFunction, 'Specific Session Data has been downloaded.', false, funcName, params);
    const response = await downloadFiles(
      id,
      org,
      session,
      type,
      "Specific Session Data has been downloaded.",
      params
    );
    if (!(download_raw && download_analyzed)) {
      setLoading(false);
      setState({ ...state, open: true });
      return;
    }
    if (response.success) {
      setLoading(false);
    }
  };

  const breadcrumbs = [
    <Stack direction={"row"} alignItems={"center"} spacing={0.5}>
      <FormatListBulleted />
      <Link
        to={"/dashboard/sessions"}
        style={{ textDecoration: "none", color: "inherit", cursor: "pointer" }}
      >
        <Typography underline="hover" key="1" color="inherit">
          Sessions
        </Typography>
      </Link>
    </Stack>,
    <Typography key="3" color="text.primary">
      {`Session #${session_number}`}
    </Typography>,
  ];

  function customSort(a, b) {
    const nameA = a.display_name.toLowerCase();
    const nameB = b.display_name.toLowerCase();
    // Check if both names are "Peak Varus Torque" and "Peak Torque Percentile"
    if (nameA === "peak varus torque" && nameB === "peak torque percentile") {
      return -1; // "Peak Varus Torque" comes before "Peak Torque Percentile"
    } else if (
      nameA === "peak torque percentile" &&
      nameB === "peak varus torque"
    ) {
      return 1; // "Peak Torque Percentile" comes after "Peak Varus Torque"
    }

    return nameA.localeCompare(nameB);
  }

  const handleCardClick = (index) => {
    // Check if the clicked index is already selected
    const isSelected = selectedGraphCardIndex.includes(index);

    // If it's selected, remove it from the selected array
    if (isSelected) {
      setSelectedGraphCardIndex(
        selectedGraphCardIndex.filter((idx) => idx !== index)
      );
    }
    // If it's not selected, add it to the selected array
    else {
      setSelectedGraphCardIndex([...selectedGraphCardIndex, index]);
    }
  };

  if (loading) {
    return (
      <Container maxWidth="xl" ml={0} pb={2}>
        <Typography variant="body1" mt={1}>
          <Skeleton width={"40%"} sx={{ bgcolor: "grey.200" }} />
        </Typography>

        <Typography variant="h4" mb={1}>
          <Skeleton width={"45%"} sx={{ bgcolor: "grey.200" }} />
        </Typography>
        <Typography variant="body1" mb={5.5}>
          <Skeleton sx={{ bgcolor: "grey.200" }} />
        </Typography>

        <Skeleton height={375} variant="rounded" sx={{ bgcolor: "grey.200" }} />
      </Container>
    );
  } else {
    const plotKeysWithIntensity = plotKeys.filter((item) =>
      item.metric_id.includes("intensity_zone")
    );
    const plotKeysWithoutIntensity = plotKeys.filter(
      (item) => !item.metric_id.includes("intensity_zone")
    );

    let initialPlotKeys;
    let remainingPlotKeys;

    if (plotKeysWithIntensity.length === 0) {
      // If there are plotKeys without intensity, send the first one
      initialPlotKeys = plotKeys[0];
      // remainingPlotKeys = plotKeys.slice(1).sort(customSort);
      remainingPlotKeys = plotKeys.slice(1);
    } else if (plotKeysWithIntensity.length > 0) {
      // If there are only plotKeys with intensity, send the first one
      initialPlotKeys = plotKeysWithIntensity[0];
      // remainingPlotKeys = plotKeysWithoutIntensity.sort(customSort);
      remainingPlotKeys = plotKeysWithoutIntensity;
    }

    return (
      <Container maxWidth="xl" ml={0} pb={2}>
        <Grid container spacing={2}>
          <Grid item xs={9}>
            <Stack spacing={1}>
              <Breadcrumbs
                separator={<ArrowForwardIos sx={{ height: 12 }} />}
                aria-label="breadcrumb"
                mb={2}
              >
                {breadcrumbs}
              </Breadcrumbs>

              <Typography variant="h4" mb={1} onClick={handleSessionClick}>
                {`Session #${session_number}`}
              </Typography>

              {anchorEl && (
                <Popover
                  open={true}
                  anchorEl={anchorEl}
                  onClose={() => setAnchorEl(false)}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <Box p={2}>
                    <Typography>Session Id Successfully Copied!</Typography>
                  </Box>
                </Popover>
              )}
              {session_description && (
                <Box
                  sx={{
                    ...commonStyles,
                    borderColor: "primary.main",
                    overflowY: "auto",
                    maxHeight: "15vh",
                  }}
                  p={1}
                >
                  <Typography variant="body1">{session_description}</Typography>
                </Box>
              )}

              <Stack
                direction={"row"}
                sx={{ alignItems: "center", flexWrap: "wrap" }}
                gap={1}
                mb={4}
              >
                {admin && (
                  <>
                    <Stack direction="row" alignItems="center" spacing={1}>
                      <Typography fontWeight={600}>{name} </Typography>
                    </Stack>

                    <Typography height={20} color={"rgba(0, 0, 0, 0.26)"}>
                      {" "}
                      |{" "}
                    </Typography>
                  </>
                )}

                <Typography>{date}</Typography>

                <Typography height={20} color={"rgba(0, 0, 0, 0.26)"}>
                  {" "}
                  |{" "}
                </Typography>

                <Chip
                  label={
                    <Box fontSize={14}>
                      {metaSessionData.activity_list[0].activity.display_name}
                    </Box>
                  }
                  sx={{ minWidth: 0, pl: 1 }}
                  variant="outlined"
                  icon={
                    <svg width="4" height="14" viewBox="0 0 4 14" fill="none">
                      <path
                        d="M2 0.5V13.5"
                        stroke={`${color}`}
                        strokeWidth="3"
                      />
                    </svg>
                  }
                />

                <Typography height={20} color={"rgba(0, 0, 0, 0.26)"}>
                  {" "}
                  |{" "}
                </Typography>

                {activityMetaData && (
                  <>
                    <Chip
                      label={
                        <Typography fontSize={14}>
                          {activityMetaData}
                        </Typography>
                      }
                      variant="outlined"
                    />

                    <Typography height={20} color={"rgba(0, 0, 0, 0.26)"}>
                      {" "}
                      |{" "}
                    </Typography>
                  </>
                )}

                <Chip
                  label={
                    <Typography fontSize={14}>
                      {capitalizedDevicePlacement}
                    </Typography>
                  }
                  variant="outlined"
                />

                <Typography height={20} color={"rgba(0, 0, 0, 0.26)"}>
                  {" "}
                  |{" "}
                </Typography>

                <Chip
                  label={
                    <Typography fontSize={14}>
                      {capitalizedDeviceType}
                    </Typography>
                  }
                  variant="outlined"
                />
              </Stack>
            </Stack>
          </Grid>

          <Grid item xs={3}>
            <Stack spacing={2}>
              <Stack
                direction="row"
                justifyContent={"flex-end"}
                alignContent={"center"}
              >
                <Tooltip title="Newer Session">
                  <ArrowBackIos
                    sx={{ height: 15 }}
                    onClick={handleNewerClick}
                  />
                </Tooltip>

                <Tooltip title="Older Session">
                  <ArrowForwardIos
                    sx={{ height: 15 }}
                    onClick={handlePreviousClick}
                  />
                </Tooltip>
              </Stack>

              <Tooltip
                title={
                  !metaSessionData?.session_metrics?.reps
                    ? "Data can't be downloaded for an invalid session"
                    : ""
                }
                arrow
                placement="top"
              >
                <span
                  style={
                    !metaSessionData?.session_metrics?.reps
                      ? { cursor: "not-allowed" }
                      : {}
                  }
                >
                  <Button
                    size="medium"
                    variant="contained"
                    sx={{ width: 225 }}
                    onClick={(event) => setAnchorEl2(event.target)}
                    disabled={!metaSessionData?.session_metrics?.reps}
                  >
                    <FileDownloadOutlined />
                    {"  "}Download Session
                  </Button>
                </span>
              </Tooltip>
              <Menu id="long-menu" open={open} anchorEl={anchorEl2}>
                <ClickAwayListener onClickAway={() => setAnchorEl2(null)}>
                  <div role="presentation">
                    <DownloadSelect
                      ids={sessionId}
                      userId={userId}
                      orgId={orgId}
                      onClose={() => setAnchorEl2(null)}
                      isTestSession={isTestSession}
                      allValidSession={!!metaSessionData?.session_metrics?.reps}
                    />
                  </div>
                </ClickAwayListener>
              </Menu>
            </Stack>
          </Grid>

          {isTestSession ? (
            <Typography variant="h6" pl={2}>
              Please feel free to download the raw data!
            </Typography>
          ) : (
            <>
              <Grid item xs={12}>
                <LineGraph
                  analyzedSession={analyzedSession}
                  color={color}
                  plotKeys={initialPlotKeys}
                />
              </Grid>

              {remainingPlotKeys.map((plotKey, index) => (
                <Grid
                  item
                  xs={12}
                  sm={selectedGraphCardIndex.includes(index) ? 12 : 6}
                  key={index}
                >
                  <SessionGraphCards
                    subheader={`For ${analyzedSession.length} reps`}
                    plotKey={plotKey}
                    analyzedSession={analyzedSession}
                    color={color}
                    handleCardClick={handleCardClick}
                    index={index}
                    isSelected={selectedGraphCardIndex.includes(index)}
                  />
                </Grid>
              ))}
            </>
          )}
        </Grid>
      </Container>
    );
  }
}
