import React, { useState } from "react";
import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel,
  Checkbox,
  Typography,
} from "@mui/material";
import {
  stableSort,
  getComparator,
  handleCheckBoxClick,
} from "../../utils/tableFunctions";

const GenericTable = ({
  data,
  columns,
  emptyRows,
  loading,
  rowsPerPage,
  page,
  order,
  orderBy,
  onRequestSort,
  hasCheckbox,
  numSelected,
  rowCount,
  setSession,
  selected,
  session,
  setSelected,
}) => {
  const dataAfterSlicing = (data) => {
    if (hasCheckbox)
      return data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);
    else return data;
  };

  const isSelected = (id) =>
    selected?.map((item) => item.id)?.indexOf(id) !== -1;

  const deselectAll = () => {
    setSelected([]);
    setSession([]);
  };
  function EnhancedTableHead({ order, orderBy, onRequestSort, columns }) {
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          {hasCheckbox && (
            <TableCell padding="checkbox">
              <Checkbox
                color="primary"
                indeterminate={numSelected > 0 && numSelected < rowCount}
                checked={rowCount > 0 && numSelected === rowCount}
                inputProps={{
                  "aria-label": `select-all`,
                }}
                id={"de-select-all"}
                onClick={deselectAll}
                disabled={numSelected === 0}
              />
            </TableCell>
          )}
          {columns.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.disablePadding ? "left" : "left"}
              padding={headCell.disablePadding ? "none" : "normal"}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }

  const handleClick = (
    event,
    name,
    sessionId,
    userId,
    session,
    selected,
    containsTestSession,
    isValidSession
  ) => {
    const { newSelected, sessionDetails } = handleCheckBoxClick(
      event,
      name,
      sessionId,
      userId,
      session,
      selected,
      containsTestSession,
      isValidSession
    );
    setSession(sessionDetails);
    setSelected(newSelected);
  };

  if (loading) {
    return (
      <TableContainer sx={{ pl: 0 }}>
        <Table>
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={onRequestSort}
            columns={columns}
          />
          <TableBody>
            {Array.from({ length: rowsPerPage }).map((_, index) => (
              <TableRow key={index}>
                {columns.map((column) => (
                  <TableCell key={column.id}>
                    <Skeleton sx={{ bgcolor: "grey.300" }} />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  if (data.length === 0 && loading === false) {
    return (
      <TableContainer sx={{ pl: 0 }}>
        <Table>
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={onRequestSort}
            columns={columns}
          />
        </Table>
        {hasCheckbox ? (
          <Typography variant="h6" p={1}>
            There are no sessions yet!
          </Typography>
        ) : (
          <Typography variant="h6" p={1}>
            There are no athletes yet!
          </Typography>
        )}
      </TableContainer>
    );
  }

  return (
    <TableContainer sx={{ pl: 0 }}>
      <Table>
        <EnhancedTableHead
          order={order}
          orderBy={orderBy}
          onRequestSort={onRequestSort}
          columns={columns}
        />
        <TableBody>
          {/* {stableSort(data, getComparator(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => ( */}
          {dataAfterSlicing(
            stableSort(data, getComparator(order, orderBy))
          ).map((row, index) => (
            <TableRow
              key={row.sessionId}
              role={hasCheckbox ? "checkbox" : "row"}
              tabIndex={-1}
              selected={hasCheckbox ? isSelected(row.sessionId) : null}
              aria-checked={hasCheckbox ? isSelected(row.sessionId) : null}
              checkboxSelection
              id={`enhanced-table-row-${index}`}
            >
              {hasCheckbox && (
                <TableCell padding="checkbox">
                  <Checkbox
                    color="primary"
                    checked={isSelected(row.sessionId)}
                    onClick={(event) =>
                      hasCheckbox &&
                      handleClick(
                        event,
                        row.sessionId,
                        row.sessionId,
                        row.userId,
                        session,
                        selected,
                        row.activity?.includes("A-TEST"),
                        !!row.totalReps
                      )
                    }
                    inputProps={{
                      "aria-labelledby": `enhanced-table-checkbox-${index}`,
                    }}
                    id={`enhanced-table-checkbox-${index}`}
                  />
                </TableCell>
              )}
              {columns.map((column) => (
                <TableCell key={column.id}>{row[column.id]}</TableCell>
              ))}
            </TableRow>
          ))}
          {emptyRows > 0 && (
            <TableRow style={{ height: 55 }}>
              <TableCell colSpan={6} />
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default GenericTable;
