import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import validator from "validator";
// @mui
import {
  Typography,
  Container,
  InputAdornment,
  Stack,
  Button,
  TextField,
} from "@mui/material";
// @mui-icons
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
// APIs
import { forgotPassword } from "../../api/authRoutes";
// Utils
import { whiteButtonStyle } from "../../utils/styles";
// import { withTimingAndLogging } from '../../utils/logFunctions/logs';
import { LoadingButton } from "@mui/lab";
// ----------------------------------------------------------------------

export default function ForgotPassword() {
  const [emailValue, setEmailValue] = useState("");
  const [message, setMessage] = useState("");
  const [focusOne, setFocusOne] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const submitForget = async () => {
    const lowerCaseEmail = emailValue.toLowerCase();
    setLoading(true);

    const fetchFunction = () => forgotPassword(lowerCaseEmail);
    const funcName = "forgotPassword";
    const params = { email: lowerCaseEmail };
    // const response = await withTimingAndLogging(fetchFunction, 'User has successfully received password code.', false, funcName, params);
    const response = await forgotPassword(
      lowerCaseEmail,
      "User has successfully received password code.",
      params
    );
    const { data, statusCode } = response;

    if (statusCode === 200) {
      setLoading(false);
      navigate("/createnewpassword", {
        state: {
          email: lowerCaseEmail,
        },
      });
    } else {
      setLoading(false);
      setMessage(data.data.message);
    }
  };

  const validateEmail = (e) => {
    const email = e.target.value;
    const sanitizedValue = email.replace(/\s/g, "");
    setEmailValue(sanitizedValue);

    if (validator.isEmail(sanitizedValue)) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  };

  return (
    <Container maxWidth="xl" ml={0}>
      <Stack alignItems="center" height={"100vh"} sx={{ width: "100%" }}>
        <Typography variant="h4" mb={5.2} mt={15}>
          Forgot Password
        </Typography>

        <Stack justifyContent="center" sx={{ width: "62%" }}>
          <TextField
            type="text"
            id="field1"
            fullWidth
            onFocus={() => setFocusOne(true)}
            value={emailValue}
            label="Enter Email"
            variant="outlined"
            onChange={(e) => validateEmail(e)}
            InputProps={{
              startAdornment: focusOne && (
                <InputAdornment position="start">
                  <MailOutlineIcon />
                </InputAdornment>
              ),
            }}
          />

          <Typography fontSize={16} mt={1} color="error">
            {message}
          </Typography>

          <Stack
            direction="row"
            alignItems="end"
            sx={{ height: "6rem", justifyContent: "space-between" }}
          >
            <Button
              disableRipple
              variant="contained"
              href="/login"
              sx={{ ...whiteButtonStyle }}
              width="md"
              startIcon={<ArrowBackIcon sx={{ color: "#FFC04D", pr: 1 }} />}
            >
              Back
            </Button>

            <LoadingButton
              variant="contained"
              onClick={submitForget}
              disableRipple
              loading={loading}
              disabled={disabled}
              width="md"
            >
              Confirm
            </LoadingButton>
          </Stack>
        </Stack>
      </Stack>
    </Container>
  );
}
