import React, { useState, useEffect } from "react";
import { debounce } from "lodash";
// @mui
import {
  Stack,
  Button,
  Tooltip,
  Grid,
  FormControl,
  MenuItem,
  InputLabel,
  TextField,
  Typography,
  Select,
  Collapse,
  FormHelperText,
} from "@mui/material/";
import ArrowForwardIosOutlinedIcon from "@mui/icons-material/ArrowForwardIosOutlined";
// APIs
import { registerInvite, accountCheck } from "../../../api/authRoutes";
import { inviteAthleteToOrg } from "../../../api/userRoutes";
// Utils
import {
  whiteButtonStyle,
  accountTextFieldStyleTwo,
} from "../../../utils/styles";
import { toISOLocal } from "../../../utils/utilityFunctions";
// import { withTimingAndLogging } from '../../../utils/logFunctions/logs';
import {
  months,
  years,
  countries,
  heights,
  weights,
  kgs,
  cms,
  convertHeight,
  convertWeight,
  feetConversion,
  validateEmail,
  isInviteFormValid,
  processNewUserObjectForInvite,
  isWithinCharacterLimit,
} from "../../../utils/authData";
import { LoadingButton } from "@mui/lab";

// ----------------------------------------------------------------------

export default function EmailInvite({
  orgId,
  handleCloseModal,
  handleClick,
  checkInviteLeft,
  invite_left,
}) {
  const [message, setMessage] = useState("");
  const [firstNameMessage, setFirstNameMessage] = useState("");
  const [secondNameMessage, setSecondNameMessage] = useState("");
  const [showInitialUI, setShowInitialUI] = useState(true);
  const [showReviewPage, setShowReviewPage] = useState(false);
  const [disable, setDisable] = useState(true);
  const [emailHelperText, setEmailHelperText] = useState("");
  const [loading, setLoading] = useState(false);
  const [color, setColor] = useState("");
  const [buttonText, setButtonText] = useState("Next");
  const [days, setDays] = useState([]);
  const [heightHelperText, setHeightHelperText] = useState("");
  const [weightHelperText, setWeightHelperText] = useState("");
  const [dateOfBirthHelperText, setDateOfBirthHelperText] = useState("");
  const [inviteNewAthlete, setInviteNewAthlete] = useState(false);
  const [dateOfBirthError, setDateOfBirthError] = useState(false);
  const [heightError, setHeightError] = useState(false);
  const [weightError, setWeightError] = useState(false);
  const [formData, setFormData] = useState({
    first: "",
    last: "",
    country: "",
    email: "",
    month: "",
    day: "",
    year: "",
    gender: "",
    height: "",
    heightMetric: "",
    weight: "",
    weightMetric: "",
    dominantArm: "",
    dominantLeg: "",
  });

  const {
    first,
    last,
    email,
    year,
    day,
    gender,
    month,
    country,
    dominantArm,
    dominantLeg,
    height,
    heightMetric,
    weight,
    weightMetric,
  } = formData;

  useEffect(() => {
    const daysInMonth = new Date(year, month, 0).getDate();
    const newDays = Array.from({ length: daysInMonth }, (_, index) => {
      const dayNumber = index + 1;
      return dayNumber < 10 ? `0${dayNumber}` : dayNumber.toString();
    });
    setDays(newDays);
    if (!newDays.includes(day)) {
      setFormData((prevState) => ({
        ...prevState,
        day: "",
      }));
    }
  }, [month, year]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      const isValidEmail = validateEmail(email);
      if (isValidEmail) {
        const response = await accountCheck(orgId, email.toLowerCase());
        const { data, statusCode } = response;
        if (statusCode === 200) {
          // if user doesnt exist
          if (!data.data.existing_user) {
            setInviteNewAthlete(true);
            setDisable(true);
          } else if (data.data.in_org) {
            // if user exists and is in org
            setInviteNewAthlete(false);
            setEmailHelperText(
              "User account already exists in this organization!"
            );
            setColor("error");
          } else {
            // if user profile exists but isnt in current org
            setInviteNewAthlete(false);
            setEmailHelperText(
              "User exists. Feel free to invite them to this organization!"
            );
            setDisable(false);
            setButtonText("Invite");
            setColor("success");
          }
        }
      } else {
        setInviteNewAthlete(false);
      }
    }, 600);

    return () => clearTimeout(delayDebounceFn);
  }, [email]);

  const handleFormDataChange = (e) => {
    const { name, value } = e.target;

    if (name === "heightMetric" && height) {
      const convertedHeight = convertHeight(value, formData.height);
      setFormData({
        ...formData,
        [name]: value,
        height: convertedHeight,
      });
    } else if (name === "weightMetric" && weight) {
      const convertedWeight = convertWeight(value, formData.weight);
      setFormData({
        ...formData,
        [name]: value,
        weight: convertedWeight,
      });
    } else if (name === "height" && heightMetric === "") {
      setFormData({
        ...formData,
        [name]: value,
        heightMetric: "in",
      });
    } else if (name === "weight" && weightMetric === "") {
      setFormData({
        ...formData,
        [name]: value,
        weightMetric: "lb",
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleEmailChange = async (event) => {
    setDisable(true);
    setColor("");
    setEmailHelperText("");
    setButtonText("Next");
    setInviteNewAthlete(false);
    const { name, value } = event.target;
    const sanitizedValue = value.replace(/\s/g, "");
    setFormData({ ...formData, [name]: sanitizedValue });
  };

  const handleNextClick = async () => {
    setLoading(true);
    if (buttonText === "Invite") {
      const newEmail = email.toLowerCase();
      const fetchFunction = () => inviteAthleteToOrg(orgId, newEmail);
      const funcName = "registerInvite";
      const params = { org_id: orgId, email: newEmail };

      // const response = await withTimingAndLogging(fetchFunction, 'Athlete invite registered.', false, funcName, params);
      const response = await inviteAthleteToOrg(
        orgId,
        newEmail,
        "Athlete invite registered.",
        params
      );
      const { data, statusCode } = response;

      if (statusCode === 200) {
        if (typeof data.data?.invite_left === "number")
          checkInviteLeft(data.data?.invite_left);
        handleCloseModal();
        handleClick({
          vertical: "bottom",
          horizontal: "center",
        });
      }
      setLoading(false);
    } else {
      const isFormFieldsFilled = isInviteFormValid(formData);
      const isEmailValid = validateEmail(email);
      const trimmedFirstName = first.trim();
      const trimmedLastName = last.trim();

      if (!isFormFieldsFilled) {
        setMessage("Please Fill Out All Forms");
      } else if (!isEmailValid) {
        setMessage("Please Enter Valid Email");
      } else if (isFormFieldsFilled && isEmailValid) {
        setMessage("");
        setFormData((prevState) => ({
          ...prevState,
          first: trimmedFirstName,
          last: trimmedLastName,
        }));
        setShowInitialUI(!showInitialUI);
      }
      setLoading(false);
    }
  };

  const handleReview = async () => {
    const isAnyDateFieldFilled = ["month", "day", "year"].some(
      (field) => formData[field] !== ""
    );

    if (isAnyDateFieldFilled && !(month && day && year)) {
      setDateOfBirthHelperText("Please fill in all date fields.");
      setDateOfBirthError(true);
    }

    if (
      (height && !heightMetric) ||
      (!height && heightMetric) ||
      (weight && !weightMetric) ||
      (!weight && weightMetric)
    ) {
      if ((height && !heightMetric) || (!height && heightMetric)) {
        setWeightHelperText("Please enter both height and height metric.");
        setHeightError(true);
        setWeightError(false);
      }

      if ((weight && !weightMetric) || (!weight && weightMetric)) {
        setWeightHelperText("Please enter both weight and weight metric.");
        setWeightError(true);
        setHeightError(false);
      }

      if (weight && weightMetric) {
        setWeightError(false);
      }

      if (height && heightMetric) {
        setHeightError(false);
      }
      if (
        ((weight && !weightMetric) || (!weight && weightMetric)) &&
        ((height && !heightMetric) || (!height && heightMetric))
      ) {
        setWeightHelperText(
          "Please enter both weight and weight metric and height and height metric."
        );
        setHeightError(true);
        setWeightError(true);
      }
    }

    if (
      ((month && day && year) || (!month && !day && !year)) &&
      ((height && heightMetric) || (!height && !heightMetric)) &&
      ((weight && weightMetric) || (!weight && !weightMetric))
    ) {
      setShowInitialUI(false);
      setShowReviewPage(true);
      setHeightError(false);
      setWeightError(false);
      setWeightHelperText("");
      setDateOfBirthError(false);
      setDateOfBirthHelperText("");
    }
  };

  const handleSubmit = async () => {
    setLoading(true);
    const isoDate = toISOLocal(new Date());
    const newEmail = email.toLowerCase();
    const dateOfBirth = `${year}-${month}-${day}`;
    const newHeight =
      heightMetric === "in" && height !== "0" ? feetConversion(height) : height;
    const newGender = gender === "Prefer Not to Say" || "" ? null : gender;

    const userData = {
      first: first,
      last: last,
      email: newEmail,
      date: isoDate,
      height: newHeight,
      heightMetric: heightMetric,
      weight: weight,
      weightMetric: weightMetric,
      dateOfBirth: dateOfBirth,
      dominantArm: dominantArm,
      dominantLeg: dominantLeg,
      country: country,
      gender: newGender,
      orgId: orgId,
    };

    try {
      const processedUserData = processNewUserObjectForInvite(userData);

      const fetchFunction = () => registerInvite(processedUserData);
      const funcName = "registerInvite";
      const params = { user_data: processedUserData };

      // const response = await withTimingAndLogging(fetchFunction, 'Athlete invite registered.', false, funcName, params);
      const response = await registerInvite(
        processedUserData,
        "Athlete invite registered.",
        params
      );
      const { data, statusCode } = response;

      if (statusCode === 200) {
        checkInviteLeft(data.data?.invite_left);
        handleCloseModal();
        handleClick({
          vertical: "bottom",
          horizontal: "center",
        });
      }
    } catch (error) {
      setLoading(false);
      setMessage(error.message);
    }
  };

  return (
    <>
      {showInitialUI && !showReviewPage && (
        <Stack spacing={3} alignItems="center" mt={1}>
          <Typography align="center" variant="button">
            ATHLETE EMAIL
          </Typography>

          <TextField
            required
            helperText={emailHelperText}
            sx={{ width: "70%" }}
            id="field1"
            color={color}
            type="text"
            value={email}
            variant="outlined"
            label="Email"
            name="email"
            onChange={handleEmailChange}
            disabled={invite_left <= 0}
          />

          <Collapse in={inviteNewAthlete} sx={{ width: "70%" }}>
            <Stack spacing={3} alignItems="center" mt={1}>
              <Typography align="center" variant="button">
                ATHLETE INFO
              </Typography>

              <Grid container spacing={1}>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    type="text"
                    error={false}
                    fullWidth
                    required
                    helperText={firstNameMessage}
                    name="first"
                    value={first}
                    variant="outlined"
                    label="First Name"
                    onChange={(event) => {
                      const { name, value } = event.target;
                      const trimmedValue = value.trim();

                      // Check for minimum length
                      if (trimmedValue.length < 2) {
                        setFirstNameMessage(
                          "Please enter a minimum of two characters."
                        );
                        setFormData({ ...formData, [name]: value });
                      } else if (!isWithinCharacterLimit(value)) {
                        setFirstNameMessage("Character limit reached.");
                      } else {
                        setFirstNameMessage("");
                        setFormData({ ...formData, [name]: value });
                      }

                      // Check both first and last names for minimum length
                      const isFirstNameValid = trimmedValue.length >= 2;
                      const isLastNameValid = formData.last.trim().length >= 2;

                      // Update disable state based on both first and last names
                      setDisable(!(isFirstNameValid && isLastNameValid));
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    type="text"
                    required
                    fullWidth
                    name="last"
                    helperText={secondNameMessage}
                    value={last}
                    variant="outlined"
                    label="Last Name"
                    onChange={(event) => {
                      const { name, value } = event.target;
                      const trimmedValue = value.trim();

                      // Check for minimum length
                      if (trimmedValue.length < 2) {
                        setSecondNameMessage(
                          "Please enter a minimum of two characters."
                        );
                        setFormData({ ...formData, [name]: value });
                      } else if (!isWithinCharacterLimit(value)) {
                        setSecondNameMessage("Character limit reached.");
                      } else {
                        setSecondNameMessage("");
                        setFormData({ ...formData, [name]: value });
                      }
                      // Check both first and second names for minimum length
                      const isFirstNameValid =
                        formData.first.trim().length >= 2;
                      const isSecondNameValid = trimmedValue.length >= 2;

                      // Update disable state based on both first and second names
                      setDisable(!(isFirstNameValid && isSecondNameValid));
                    }}
                  />
                </Grid>
              </Grid>
            </Stack>
          </Collapse>

          <Tooltip
            title={
              invite_left > 0
                ? ""
                : "You have surpassed the available invitation count"
            }
            arrow
            placement="top"
          >
            <span style={invite_left > 0 ? {} : { cursor: "not-allowed" }}>
              <Button
                variant="contained"
                disableRipple
                disabled={invite_left <= 0 || disable}
                sx={{ width: "7rem", boxShadow: "none" }}
                textAlign="center"
                onClick={handleNextClick}
                endIcon={<ArrowForwardIosOutlinedIcon sx={{ fontSize: 10 }} />}
              >
                {buttonText}
              </Button>
            </span>
          </Tooltip>
        </Stack>
      )}

      {!showInitialUI && !showReviewPage && (
        <>
          <Typography variant="button" fontSize={14} pl={2}>
            META DATA (Optional)
          </Typography>

          <Grid
            p={2}
            container
            component="form"
            rowSpacing={3}
            columnSpacing={3}
            sx={{ overflowY: "scroll", maxHeight: "66vh" }}
          >
            <Grid item xs={12} sm={6}>
              <Typography variant="body1" mb={1}>
                Date Of Birth
              </Typography>

              <Stack
                direction={"row"}
                justifyContent="space-evenly"
                spacing={1}
              >
                <FormControl fullWidth>
                  <InputLabel id="month"> MM </InputLabel>
                  <Select
                    labelId="month"
                    value={month}
                    disabled={disable}
                    error={dateOfBirthError}
                    label="MM"
                    name="month"
                    variant="outlined"
                    onChange={(event) => {
                      const { name, value } = event.target;
                      setFormData({ ...formData, [name]: value });
                    }}
                  >
                    {months.map((month, index) => (
                      <MenuItem key={index} value={month}>
                        {month}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl fullWidth required>
                  <InputLabel id="day">DD</InputLabel>
                  <Select
                    value={day}
                    disabled={disable}
                    error={dateOfBirthError}
                    variant="outlined"
                    labelId="day"
                    label="DD"
                    name="day"
                    onChange={(event) => {
                      const { name, value } = event.target;
                      setFormData({ ...formData, [name]: value });
                    }}
                  >
                    {days.map((day, index) => (
                      <MenuItem key={index} value={day}>
                        {day}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl fullWidth required>
                  <InputLabel id="year">YYYY</InputLabel>
                  <Select
                    value={year}
                    disabled={disable}
                    error={dateOfBirthError}
                    labelId="year"
                    label="YYYY"
                    name="year"
                    variant="outlined"
                    onChange={(event) => {
                      const { name, value } = event.target;
                      setFormData({ ...formData, [name]: value });
                    }}
                  >
                    {years.map((year, index) => (
                      <MenuItem
                        key={index}
                        value={year}
                        justifyContent="center"
                      >
                        {year}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>

              <FormHelperText>{dateOfBirthHelperText}</FormHelperText>

              <Typography variant="body1" mb={1} mt={1.5}>
                Country
              </Typography>
              <FormControl fullWidth>
                <InputLabel id="country">Country</InputLabel>
                <Select
                  value={country}
                  disabled={disable}
                  autoComplete="country"
                  variant="outlined"
                  labelId="country"
                  id="country"
                  label="Country"
                  name="country"
                  onChange={(event) => {
                    const { name, value } = event.target;
                    setFormData({ ...formData, [name]: value });
                  }}
                >
                  {countries.map((country, index) => (
                    <MenuItem
                      key={index}
                      value={country}
                      disabled={country === "---------------------------------"}
                    >
                      {country}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <Typography variant="body1" mb={1} mt={1.5}>
                Which leg are they dominant in?
              </Typography>

              <FormControl fullWidth>
                <InputLabel>Dominant Leg</InputLabel>
                <Select
                  value={dominantLeg}
                  name="dominantLeg"
                  label="Dominant Leg"
                  variant="outlined"
                  onChange={handleFormDataChange}
                  fullWidth
                >
                  <MenuItem disableRipple value={"right"} key={1}>
                    Right
                  </MenuItem>
                  <MenuItem disableRipple value={"left"} key={2}>
                    Left
                  </MenuItem>
                </Select>
              </FormControl>

              <Typography variant="body1" mb={1} mt={1.5}>
                Which arm are they dominant in?
              </Typography>

              <FormControl fullWidth>
                <InputLabel>Dominant Arm</InputLabel>
                <Select
                  name="dominantArm"
                  value={dominantArm}
                  variant="outlined"
                  label="Dominant Arm"
                  onChange={handleFormDataChange}
                  fullWidth
                >
                  <MenuItem disableRipple value={"right"}>
                    Right
                  </MenuItem>
                  <MenuItem disableRipple value={"left"}>
                    Left
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Typography variant="body1" mb={1}>
                What's their gender?
              </Typography>

              <FormControl fullWidth>
                <InputLabel>Gender</InputLabel>
                <Select
                  value={gender}
                  fullWidth
                  label="Gender"
                  name="gender"
                  placeholder="Gender"
                  variant="outlined"
                  onChange={handleFormDataChange}
                >
                  <MenuItem disableRipple key={0} value={"male"}>
                    Male
                  </MenuItem>
                  <MenuItem disableRipple key={1} value={"female"}>
                    Female
                  </MenuItem>
                  <MenuItem disableRipple key={2} value={"Prefer Not to Say"}>
                    Prefer Not To Say
                  </MenuItem>
                </Select>
              </FormControl>
              <Grid container columnSpacing={3} rowSpacing={2}>
                <Grid item xs={12} sm={12} md={6}>
                  <Typography variant="body1" mb={1} mt={1.5}>
                    What's your height?
                  </Typography>
                  <FormControl fullWidth>
                    <InputLabel>Height</InputLabel>
                    <Select
                      value={height}
                      name="height"
                      placeholder="Height"
                      label="Height"
                      fullWidth
                      error={heightError}
                      variant="outlined"
                      onChange={handleFormDataChange}
                    >
                      {heightMetric === "cm"
                        ? cms.map((height, index) => {
                            return (
                              <MenuItem
                                disableRipple
                                key={index}
                                value={height}
                              >
                                {" "}
                                {height}{" "}
                              </MenuItem>
                            );
                          })
                        : heights.map((height, index) => {
                            return (
                              <MenuItem
                                disableRipple
                                key={index}
                                value={height}
                              >
                                {" "}
                                {height}{" "}
                              </MenuItem>
                            );
                          })}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={12} md={6}>
                  <Typography variant="body1" mb={1} mt={1.5}>
                    Metric
                  </Typography>

                  <FormControl fullWidth>
                    <InputLabel>Height Unit</InputLabel>
                    <Select
                      value={heightMetric}
                      placeholder="Height Unit"
                      name="heightMetric"
                      error={heightError}
                      variant="outlined"
                      fullWidth
                      label="Height Unit"
                      onChange={handleFormDataChange}
                    >
                      <MenuItem disableRipple key={heightMetric} value={"in"}>
                        ft
                      </MenuItem>
                      <MenuItem disableRipple key={heightMetric} value={"cm"}>
                        cm
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={12} md={6}>
                  <Typography variant="body1" mb={1}>
                    What's your weight?
                  </Typography>

                  <FormControl fullWidth>
                    <InputLabel>Weight</InputLabel>
                    <Select
                      value={weight}
                      error={weightError}
                      name="weight"
                      label="Weight"
                      variant="outlined"
                      onChange={handleFormDataChange}
                      fullWidth
                    >
                      {weightMetric === "kg"
                        ? kgs.map((weight, index) => {
                            return (
                              <MenuItem
                                disableRipple
                                key={index}
                                value={weight}
                              >
                                {" "}
                                {weight}{" "}
                              </MenuItem>
                            );
                          })
                        : weights.map((weight, index) => {
                            return (
                              <MenuItem
                                disableRipple
                                key={index}
                                value={weight}
                              >
                                {" "}
                                {weight}{" "}
                              </MenuItem>
                            );
                          })}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={12} md={6}>
                  <Typography variant="body1" mb={1}>
                    Metric
                  </Typography>
                  <FormControl fullWidth>
                    <InputLabel>Weight Unit</InputLabel>

                    <Select
                      name="weightMetric"
                      label="Weight Unit"
                      error={weightError}
                      value={weightMetric}
                      variant="outlined"
                      fullWidth
                      onChange={handleFormDataChange}
                    >
                      <MenuItem disableRipple key={weightMetric} value={"lb"}>
                        lb
                      </MenuItem>
                      <MenuItem disableRipple key={weightMetric} value={"kg"}>
                        kg
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormHelperText>{weightHelperText}</FormHelperText>
                </Grid>
              </Grid>
              <Stack
                mt={5}
                direction="row"
                spacing={1}
                justifyContent="space-between"
              >
                <Button
                  sx={{ ...whiteButtonStyle, width: "7rem" }}
                  onClick={() => {
                    setShowInitialUI(!showInitialUI);
                  }}
                  disableRipple
                >
                  Back
                </Button>

                <LoadingButton
                  variant="contained"
                  disableRipple
                  loading={loading}
                  sx={{ width: "7rem", boxShadow: "none" }}
                  textAlign="center"
                  onClick={handleReview}
                  endIcon={
                    <ArrowForwardIosOutlinedIcon sx={{ fontSize: 10 }} />
                  }
                >
                  Review
                </LoadingButton>
              </Stack>
            </Grid>
          </Grid>
        </>
      )}

      {showReviewPage && !showInitialUI && (
        <Stack p={1}>
          <Typography variant="button" fontSize={14}>
            Review{" "}
          </Typography>
          <Grid
            container
            rowSpacing={2}
            columnSpacing={4}
            mt={1}
            mb={2}
            sx={{ overflowY: "scroll", maxHeight: "63vh" }}
          >
            <Grid item xs={12} sm={6}>
              <InputLabel id="email" sx={{ fontSize: 10 }}>
                Email
              </InputLabel>
              <TextField
                id="email"
                labelId="email"
                sx={{ ...accountTextFieldStyleTwo, width: "100%" }}
                disabled={true}
                value={email}
                variant="standard"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <InputLabel id="height" sx={{ fontSize: 10 }}>
                Height
              </InputLabel>
              <TextField
                id="height"
                labelId="height"
                sx={{ ...accountTextFieldStyleTwo, width: "100%" }}
                disabled={true}
                value={
                  height === ""
                    ? ""
                    : heightMetric === "in"
                    ? `${height}`
                    : `${height} ${heightMetric}`
                }
                variant="standard"
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <InputLabel id="first" sx={{ fontSize: 10 }}>
                First Name
              </InputLabel>
              <TextField
                id="first"
                labelId="first"
                sx={{ ...accountTextFieldStyleTwo, width: "100%" }}
                disabled={true}
                value={first}
                variant="standard"
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <InputLabel id="last" sx={{ fontSize: 10 }}>
                Last Name
              </InputLabel>
              <TextField
                id="last"
                sx={{ ...accountTextFieldStyleTwo, width: "100%" }}
                disabled={true}
                value={last}
                labelId="last"
                variant="standard"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <InputLabel id="weight" sx={{ fontSize: 10 }}>
                Weight
              </InputLabel>
              <TextField
                id="weight"
                sx={{ ...accountTextFieldStyleTwo, width: "100%" }}
                disabled={true}
                value={weight === "" ? `` : `${weight} ${weightMetric}`}
                labelId="weight"
                variant="standard"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <InputLabel id="dateOfBirth" sx={{ fontSize: 10 }}>
                Date Of Birth
              </InputLabel>
              <TextField
                value={month ? month.concat(`-${day}-${year}`) : ""}
                sx={{ ...accountTextFieldStyleTwo, width: "100%" }}
                disabled={true}
                variant="standard"
                labelId="yy"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <InputLabel id="arm" sx={{ fontSize: 10 }}>
                Arm Dominance
              </InputLabel>
              <TextField
                value={`${dominantArm
                  .charAt(0)
                  .toUpperCase()}${dominantArm.slice(1)}`}
                sx={{ ...accountTextFieldStyleTwo, width: "100%" }}
                disabled={true}
                variant="standard"
                labelId="arm"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <InputLabel id="country" sx={{ fontSize: 10 }}>
                Country
              </InputLabel>
              <TextField
                value={country}
                sx={{ ...accountTextFieldStyleTwo, width: "100%" }}
                disabled={true}
                variant="standard"
                labelId="country"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <InputLabel id="leg" sx={{ fontSize: 10 }}>
                Leg Dominance
              </InputLabel>
              <TextField
                value={`${dominantLeg
                  .charAt(0)
                  .toUpperCase()}${dominantLeg.slice(1)}`}
                sx={{ ...accountTextFieldStyleTwo, width: "100%" }}
                disabled={true}
                variant="standard"
                labelId="leg"
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <InputLabel id="gender" sx={{ fontSize: 10 }}>
                Gender
              </InputLabel>
              <TextField
                value={`${gender.charAt(0).toUpperCase()}${gender.slice(1)}`}
                sx={{ ...accountTextFieldStyleTwo, width: "100%" }}
                disabled={true}
                variant="standard"
                labelId="gender"
              />
            </Grid>

            <Grid item xs={12} sm={6} mt={1}>
              <Stack direction="row" spacing={1} justifyContent="space-between">
                <Button
                  sx={{ ...whiteButtonStyle, width: "7rem" }}
                  onClick={() => {
                    setShowInitialUI(false);
                    setShowReviewPage(false);
                  }}
                  disableRipple
                >
                  Back
                </Button>

                <LoadingButton
                  variant="contained"
                  disableRipple
                  loading={loading}
                  sx={{ width: "7rem", boxShadow: "none" }}
                  textAlign="center"
                  onClick={handleSubmit}
                >
                  Invite
                </LoadingButton>
              </Stack>
            </Grid>
          </Grid>
          <Typography color={"error"}>{message}</Typography>
          <Stack alignItems="center" justifyContent="center" margin={"auto"}>
            <Typography variant="subtitle2" mt={2}>
              NOTE: If certain metadata is not filled out, the invited user will
              be required to fill it in on first login.
            </Typography>
          </Stack>
        </Stack>
      )}
    </>
  );
}
