import React from 'react';
// @mui
import { Card, Stack, Typography } from '@mui/material';
import SkeletonCard from './SkeletonCard';
// ----------------------------------------------------------------------

export default function SessionCardInfo({
  icon, 
  title,
  activityType,
  total,
  suffix,
  ...other
}){
  
  if (total === null) {
    return (
      <SkeletonCard/>
    )
  } 

  return (
    <Card {...other}>
      <Stack direction="row" alignItems="center" justifyContent="space-between" p={2}>

        <Stack sx={{width: "50%"}}>

          <Typography fontWeight={700} mb={.2} whiteSpace="nowrap" variant='body2'> 
            {title}
          </Typography>

          <Typography variant='subtitle1'>
            In {activityType}
          </Typography>

        </Stack>

        <Stack direction="row" alignItems="flex-end" justifyContent="flex-end" sx={{width: "50%"}}>

          <Typography pr={1} pb="3px">
            {icon}
          </Typography>

          <Typography variant="h3" sx={{pb: 0, pr: .5}}>
            {total}
          </Typography>

          <Typography variant="body1" sx={{fontFamily: "Nasalization", pb: .5}} whiteSpace="nowrap">
            {suffix}
          </Typography>

        </Stack>

      </Stack>
  </Card>
  )
}