// ----------------------------------------------------------------------

export default function Tooltip(theme) {
    return {
      MuiTooltip: {
        styleOverrides: {
          arrow: {
            color: "white",
            "&::before": {
              border: ".5px solid rgba(255, 192, 77, 1)",
              backgroundColor: "#fff",
              boxSizing: "border-box",
            },
          },
          tooltip: {
            backgroundColor: "#FFFFFF",
            color: "black",
            fontSize: "15px",
            border: ".5px solid rgba(255, 192, 77, 1)",
          },
        },
      },
    };
  }
  