import React, { useState, useEffect, useCallback } from "react";
import { useLocation } from "react-router-dom";
// @mui
import {
  Container,
  Stack,
  Snackbar,
  Typography,
  Button,
  TablePagination,
  Toolbar,
  TextField,
  Box,
  Tooltip,
  Tabs,
  Tab,
} from "@mui/material/";
import MuiAlert from "@mui/material/Alert";
// @mui-icons
import { PersonAddAltOutlined, Lock } from "@mui/icons-material";
//lodash
import { debounce } from "lodash";
// API
import { fetchAllOrgUsersInOrg } from "../../api/orgRoutes";
// Components
import { InviteAthleteModal } from "../../sections/orgathletes/modal";
import EditAthleteProfile from "../../sections/athlete/EditAthleteProfile";
import GenericTable from "../../components/table/GenericTable";
// Utils
import {
  createOrgAthleteData,
  OrgAthleteColumns,
  orgAthleteOptions,
} from "../../utils/tableFunctions";
// import { withTimingAndLogging } from '../../utils/logFunctions/logs';
import GenericMenu from "../../components/menu/GenericMenu";
// ----------------------------------------------------------------------

export default function OrgAthletePage({ orgId, activities }) {
  const location = useLocation();
  const [tabValue, setTabValue] = useState(
    location.state?.params && location.state.params.status
      ? location.state.params.status
      : 1
  );
  const [landingFirstTime, setLandingFirstTime] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const expiryDetails = JSON.parse(localStorage.getItem("expiryDetails"));
  const [showExpirationMessage, setShowExpirationMessage] = useState({
    severity: "",
    msg: "",
  });
  const [invite_left, setInviteLeft] = useState(expiryDetails.invite_left);
  // const [orgUsers, setOrgUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("");
  const [page, setPage] = useState(
    location.state?.params && location.state.params.page
      ? location.state.params.page
      : 0
  );
  const [rowsPerPage, setRowsPerPage] = useState(
    location.state?.params && location.state.params.offset
      ? location.state.params.offset
      : 10
  );
  const [anchorEl, setAnchorEl] = useState(null);
  const [count, setCount] = useState(0);
  const openMenu = Boolean(anchorEl);
  const [userId, setUserId] = useState(null);
  const [userCards, setUserCards] = useState([]);
  const [userCount, setUserCount] = useState(0);
  const [query, setQuery] = useState("");
  const [openEditAthleteModal, setOpenEditAthleteModal] = useState(false);
  const [updateUserData, setUpdateUserData] = useState(false);
  const [dataFetchedAtQueryLength, setDataFetchedAtQueryLength] = useState(0);
  const [state, setState] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal, open } = state;
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleButtonClick = (event, user) => {
    setAnchorEl(event.currentTarget);
    setUserId(user);
  };

  const fetchData = async (dueToQueryChange) => {
    let _query = query.trim();
    if (dueToQueryChange && page !== 0) {
      setPage(0);
      return;
    }
    setDataFetchedAtQueryLength(_query.length);
    setLoading(true);
    const params = {
      org_id: orgId,
      search_item: _query && _query.length >= 3 ? _query : "",
      page,
      offset: rowsPerPage,
      status: tabValue,
    };
    const response = await fetchAllOrgUsersInOrg(
      orgId,
      "Organization Athlete data has been fetched.",
      params
    );
    let users;
    if (response.data?.data?.data) {
      users = response.data.data.data.item;
      setUserCount(response.data.data.data.total_athletes);
    }

    const newUserCards = createOrgAthleteData(
      users,
      activities,
      orgId,
      handleButtonClick,
      params
    );
    // setOrgUsers(users);
    setUserCards(newUserCards);
    setLoading(false);
  };

  const debouncedFetch = useCallback(
    debounce(() => fetchData(true), 1500),
    [query]
  );

  const handleClick = (newState) => {
    setState({ open: true, ...newState });
  };

  const handleClose = () => {
    setState({ ...state, open: false });
  };

  const handleOpenModal = () => setOpenEditAthleteModal(true);

  const handleCloseModal = () => setOpenEditAthleteModal(false);

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleAddAthlete = () => {
    const expiryDetails = JSON.parse(localStorage.getItem("expiryDetails"));
    const days_left = expiryDetails.days_left;
    if (days_left == 365 || days_left >= 0) {
      // if (expiryDetails.invite_left) setOpenModal(true);
      if (
        expiryDetails.invite_left &&
        (invite_left === "" || invite_left > 0)
      ) {
      } else
        setShowExpirationMessage({
          msg: "You have surpassed the available invitation count",
          severity: "error",
        });
    } else {
      setShowExpirationMessage({
        msg: "You don't have the permission to invite the athlete",
        severity: "error",
      });
    }
  };

  const handleSearch = (event) => {
    const value = event.target.value;
    if (value?.trim()?.length > 35) return;
    setQuery(value);
  };

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  // const emptyRows =
  //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - userCards.length) : 0;

  useEffect(() => {
    handleAddAthlete();
  }, [invite_left]);

  //managing call of fetchdata function-below
  useEffect(() => {
    if (landingFirstTime) {
      setLandingFirstTime(false);
      return;
    }
    if (page !== 0) setPage(0);
    else fetchData();
  }, [tabValue, rowsPerPage]);

  useEffect(() => {
    if (activities && orgId) {
      fetchData();
    }
  }, [orgId, activities, updateUserData, invite_left, page]);

  useEffect(() => {
    if (activities && orgId) {
      let _query = query.trim();
      if (
        (dataFetchedAtQueryLength >= 3 && _query.length <= 2) ||
        _query.length >= 3
      ) {
        debouncedFetch();
      }
    }
    return debouncedFetch.cancel;
  }, [query, debouncedFetch]);
  //////////////////////////////////////

  return (
    <Container maxWidth="xl" ml={0}>
      <Stack direction="row" justifyContent={"space-between"}>
        <Typography variant="h4" mb={6} ml={1} noWrap>
          Athletes
        </Typography>

        <Tooltip title={showExpirationMessage.msg} placement="top" arrow>
          <span
            style={showExpirationMessage.msg ? { cursor: "not-allowed" } : {}}
          >
            <Button
              variant="contained"
              sx={{ width: "10rem", height: "2.3rem" }}
              disableRipple
              onClick={() => setOpenModal(true)}
              startIcon={
                showExpirationMessage.msg ? <Lock /> : <PersonAddAltOutlined />
              }
              disabled={showExpirationMessage.msg}
            >
              Add Athlete
            </Button>
          </span>
        </Tooltip>

        <InviteAthleteModal
          orgId={orgId}
          openModal={openModal}
          setOpenModal={setOpenModal}
          handleClick={handleClick}
          checkInviteLeft={(val) => setInviteLeft(val)}
          invite_left={invite_left}
        />
      </Stack>

      <Stack direction="row" sx={{ width: "100%", mb: 0.8, mt: -6 }}>
        <Typography
          color="#757575"
          variant="subtitle1"
          sx={{ pb: 0.5, pr: 1, pl: 1, fontSize: "1rem" }}
          whiteSpace="nowrap"
        >
          Occupied Seats :{" "}
          <span style={{ color: "#424242" }}>
            {invite_left <= 0
              ? expiryDetails.user_max
              : expiryDetails.user_max - expiryDetails.invite_left}
          </span>
        </Typography>
        <Typography
          color="#757575"
          variant="subtitle1"
          sx={{ pr: 1, pl: 1, fontSize: "1rem" }}
          whiteSpace="nowrap"
        >
          Total Seats :{" "}
          <span style={{ color: "#424242" }}>{expiryDetails.user_max}</span>
        </Typography>
      </Stack>

      <Toolbar disableGutters sx={{ mb: 1 }}>
        <Box
          sx={{
            width: "100%",
            height: "100%",
            // mb: 2.5,
          }}
        >
          <Tabs
            value={tabValue}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab
              value={1}
              label={
                <span
                  style={{
                    fontSize: "0.9rem",
                    color: tabValue === 1 ? "#FFA500" : "black",
                  }}
                >
                  ACTIVE ATHLETES
                </span>
              }
            />
            <Tab
              value={0}
              label={
                <span
                  style={{
                    fontSize: "0.9rem",
                    color: tabValue === 0 ? "#FFA500" : "black",
                  }}
                >
                  EXPIRED ATHLETES
                </span>
              }
            />
          </Tabs>
        </Box>
        <Stack direction="row">
          <TextField
            type="text"
            label="Search Athletes"
            placeholder="Enter at least 3 characters"
            sx={{ width: "15rem", mb: 1 }}
            onChange={handleSearch}
            value={query}
          />
        </Stack>
      </Toolbar>

      <GenericTable
        data={userCards}
        loading={loading}
        order={order}
        orderBy={orderBy}
        columns={OrgAthleteColumns}
        emptyRows={0}
        rowsPerPage={rowsPerPage}
        page={page}
        onRequestSort={handleRequestSort}
        hasCheckbox={false}
      />

      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={userCount}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <EditAthleteProfile
        openModal={openEditAthleteModal}
        // setOrgUsers={setOrgUsers}
        handleCloseModal={handleCloseModal}
        count={count}
        setCount={setCount}
        orgId={orgId}
        userId={userId}
        setUpdateUserData={setUpdateUserData}
      />

      <GenericMenu
        anchorEl={anchorEl}
        open={openMenu}
        options={orgAthleteOptions}
        onClose={handleCloseMenu}
        onClick={handleOpenModal}
      />

      <Snackbar
        open={open}
        anchorOrigin={{ vertical, horizontal }}
        autoHideDuration={6000}
        key={vertical + horizontal}
        onClose={handleClose}
      >
        <Alert onClose={handleClose} severity="success" variant="filled">
          An invitation has been sent to the athlete(s)!
        </Alert>
      </Snackbar>
    </Container>
  );
}
