import React from "react";
// @mui
import { Paper, ToggleButton, ToggleButtonGroup } from "@mui/material";
// Utils

export default function ChartToggleButtonGroup({
  alignment,
  handleAlignment,
  setShowReps,
  control
}) {
  return (
    <Paper
    elevation={0}
    sx={{
      display: "flex",
      borderRadius: "10px",
      backgroundColor: "rgba(118, 118, 128, 0.05)",
      flexWrap: "wrap",
    }}
  >
    <ToggleButtonGroup
      sx={{ height: "40px", width: "180px", borderRadius: '10px' }}
      value={alignment}
      exclusive
      {...control}
      onChange={handleAlignment}
      aria-label="text alignment"
    >
      <ToggleButton
        value="left"
        aria-label="left aligned"
        sx={{
          width: "50%",
          backgroundColor: "transparent",
          "&.Mui-selected": {
            fontWeight: 600,
            color: "black",
            border: "1px solid #FFC04D !important",
            backgroundColor: "white",
          },
        }}
        onClick={() => setShowReps(true)}
      >
        Reps
      </ToggleButton>
      <ToggleButton
        value="center"
        aria-label="right"
        sx={{
          width: "50%",
          backgroundColor: "transparent ",
          "&.Mui-selected": {
            fontWeight: 600,
            color: "black",
            border: "1px solid #FFC04D !important",
            backgroundColor: "white",
          },
        }}
        onClick={() => setShowReps(false)}
      >
        Sessions
      </ToggleButton>
    </ToggleButtonGroup>
  </Paper>
  );
}