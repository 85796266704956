import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
// @mui
import {
  Container,
  Modal,
  Box,
  Typography,
  Breadcrumbs,
  Stack,
  Divider,
  MenuItem,
  Button,
  FormControl,
  InputLabel,
  Grid,
  Select,
} from "@mui/material";
// @mui-icons
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { LoadingButton } from "@mui/lab";
// APIs
import { createUserAccount } from "../../api/authRoutes";
// Utils
import { whiteButtonStyle, authModalStyles } from "../../utils/styles";
import { toISOLocal } from "../../utils/utilityFunctions";
// import { withTimingAndLogging } from '../../utils/logFunctions/logs';
import {
  heights,
  weights,
  cms,
  kgs,
  feetConversion,
} from "../../utils/authData";
// ----------------------------------------------------------------------

const breadcrumbs = [
  <Typography
    variant="body1"
    sx={{ color: "rgba(158, 158, 158, 1)", fontSize: "14px" }}
  >
    {" "}
    Step 1{" "}
  </Typography>,
  <Typography
    variant="body1"
    sx={{ color: "rgba(33, 32, 33, 1)", fontSize: "14px" }}
  >
    {" "}
    Step 2{" "}
  </Typography>,
];

export default function GettingStarted() {
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [token, setToken] = useState("");
  const [formData, setFormData] = useState({
    gender: "male",
    height: "5 feet 5 inches",
    heightMetric: "in",
    weight: 150,
    weightMetric: "lb",
    dominantLeg: "right",
    dominantArm: "right",
  });

  useEffect(() => {
    if (location.state) {
      const combinedState = {
        ...location.state.formData,
        ...formData,
      };
      setToken(location.state.token);
      setFormData(combinedState);
    }
  }, []);

  const {
    first,
    last,
    email,
    year,
    day,
    month,
    password,
    heightMetric,
    height,
    weight,
    weightMetric,
    dominantArm,
    dominantLeg,
    country,
    gender,
  } = formData;

  const handleFormDataChange = (e) => {
    const { name, value } = e.target;

    if (name === "heightMetric") {
      const convertedHeight = convertHeight(value, formData.height);
      setFormData({
        ...formData,
        [name]: value,
        height: convertedHeight,
      });
    } else if (name === "weightMetric") {
      const convertedWeight = convertWeight(value, formData.weight);
      setFormData({
        ...formData,
        [name]: value,
        weight: convertedWeight,
      });
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const convertHeight = (newMetric, currentHeight) => {
    if (newMetric === "cm" && currentHeight !== "0") {
      // Convert feet and inches to centimeters
      const match = currentHeight.match(/(\d+) feet (\d+) inches/);
      if (match) {
        const feet = parseInt(match[1]);
        const inches = parseInt(match[2]);
        const totalInches = feet * 12 + inches;
        const centimeters = totalInches * 2.54; // 1 inch = 2.54 cm
        return Math.round(centimeters); // Round to the nearest whole number
      }
    } else {
      // Convert centimeters to feet and inches
      const inches = currentHeight / 2.54; // 1 cm = 1/2.54 inches
      const feet = Math.floor(inches / 12);
      const remainingInches = inches % 12;
      return `${feet} feet ${remainingInches.toFixed()} inches`;
    }
  };

  const convertWeight = (newMetric, currentWeight) => {
    if (newMetric === "kg" && currentWeight !== 0) {
      // Convert pounds to kilograms (1 lb = 0.453592 kg)
      const kilograms = currentWeight * 0.453592;
      return Math.round(kilograms); // Round to the nearest whole number
    } else if (newMetric === "lb") {
      // Convert kilograms to pounds (1 kg = 2.20462 lbs)
      const pounds = currentWeight * 2.20462;
      return Math.round(pounds); // Round to the nearest whole number
    }
    // Handle other cases as needed
    return currentWeight;
  };

  const submitAccount = async () => {
    setLoading(true);
    const dateOfBirth = `${year}-${month}-${day}`;
    const date = toISOLocal(new Date());
    const lowerCaseEmail = email.toLowerCase();
    const newInches =
      heightMetric === "in" && height !== "0" ? feetConversion(height) : height;

    const firstName = first.trim();
    const lastName = last.trim();
    const newGender = gender === "Prefer Not to Say" ? null : gender;

    const userData = {
      first: firstName,
      last: lastName,
      email: lowerCaseEmail,
      password,
      date: date,
      height: newInches,
      heightMetric,
      weight,
      weightMetric,
      dateOfBirth,
      dominantArm,
      dominantLeg,
      country,
      gender: newGender,
      token: token,
    };

    const fetchFunction = () => createUserAccount(userData);
    const funcName = `createUserAccount`;
    const params = { user_data: userData };
    // const response = await withTimingAndLogging(fetchFunction, 'Created User Account.', false, funcName, params);
    const response = await createUserAccount(
      userData,
      "Created User Account.",
      params
    );
    const data = response.data;
    const statusCode = response.statusCode;

    if (statusCode === 200) {
      setLoading(false);
      setOpen(true);
      setSuccessMessage(data.status.message);
    } else {
      setLoading(false);
      setMessage(data.status.message);
    }
  };

  return (
    <Container maxWidth="xl" ml={0}>
      <Stack height={"100vh"}>
        <Breadcrumbs separator="›" mt={3} ml={4}>
          {breadcrumbs}
        </Breadcrumbs>

        <Stack sx={{ alignItems: "center" }} spacing={2}>
          <Typography variant="h4" mb={1} mt={4}>
            Getting Started
          </Typography>

          <Grid
            container
            component="form"
            justifyContent="center"
            sx={{ width: "85%" }}
            rowSpacing={1}
            columnSpacing={2}
          >
            <Grid item xs={12}>
              <Typography variant="body1" mb={1}>
                What's your gender?
              </Typography>
              <FormControl fullWidth>
                <InputLabel></InputLabel>
                <Select
                  value={gender}
                  fullWidth
                  label=""
                  name="gender"
                  placeholder="Gender"
                  variant="outlined"
                  onChange={handleFormDataChange}
                >
                  <MenuItem disableRipple key={0} value={"male"}>
                    Male
                  </MenuItem>
                  <MenuItem disableRipple key={1} value={"female"}>
                    Female
                  </MenuItem>
                  <MenuItem disableRipple key={2} value={"Prefer Not to Say"}>
                    Prefer Not To Say
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="body1" mb={1} mt={1.5}>
                What's your height?
              </Typography>
              <FormControl fullWidth>
                <InputLabel></InputLabel>
                <Select
                  value={height}
                  name="height"
                  label=""
                  fullWidth
                  variant="outlined"
                  onChange={handleFormDataChange}
                >
                  {heightMetric === "in"
                    ? heights.map((height, index) => {
                        return (
                          <MenuItem disableRipple key={index} value={height}>
                            {" "}
                            {height}{" "}
                          </MenuItem>
                        );
                      })
                    : cms.map((height, index) => {
                        return (
                          <MenuItem disableRipple key={index} value={height}>
                            {" "}
                            {height}{" "}
                          </MenuItem>
                        );
                      })}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="body1" mb={1} mt={1.5}>
                Metric
              </Typography>

              <FormControl fullWidth>
                <InputLabel></InputLabel>
                <Select
                  value={heightMetric}
                  name="heightMetric"
                  variant="outlined"
                  fullWidth
                  label=""
                  onChange={handleFormDataChange}
                >
                  <MenuItem disableRipple key={heightMetric} value={"in"}>
                    ft
                  </MenuItem>
                  <MenuItem disableRipple key={heightMetric} value={"cm"}>
                    cm
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="body1" mb={1} mt={1.5}>
                What's your weight?
              </Typography>

              <FormControl fullWidth>
                <InputLabel></InputLabel>
                <Select
                  value={weight}
                  name="weight"
                  label=""
                  variant="outlined"
                  onChange={handleFormDataChange}
                  fullWidth
                >
                  {weightMetric === "lb"
                    ? weights.map((weight, index) => {
                        return (
                          <MenuItem disableRipple key={index} value={weight}>
                            {" "}
                            {weight}{" "}
                          </MenuItem>
                        );
                      })
                    : kgs.map((weight, index) => {
                        return (
                          <MenuItem disableRipple key={index} value={weight}>
                            {" "}
                            {weight}{" "}
                          </MenuItem>
                        );
                      })}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="body1" mb={1} mt={1.5}>
                Metric
              </Typography>

              <FormControl fullWidth>
                <InputLabel></InputLabel>

                <Select
                  name="weightMetric"
                  label=""
                  value={weightMetric}
                  variant="outlined"
                  fullWidth
                  onChange={handleFormDataChange}
                >
                  <MenuItem disableRipple key={weightMetric} value={"lb"}>
                    lbs
                  </MenuItem>
                  <MenuItem disableRipple key={weightMetric} value={"kg"}>
                    kgs
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="body1" mb={1} mt={1.5}>
                Which leg are you dominant in?
              </Typography>

              <FormControl fullWidth>
                <InputLabel></InputLabel>
                <Select
                  value={dominantLeg}
                  name="dominantLeg"
                  label=""
                  variant="outlined"
                  onChange={handleFormDataChange}
                  fullWidth
                >
                  <MenuItem disableRipple value={"right"} key={1}>
                    Right
                  </MenuItem>
                  <MenuItem disableRipple value={"left"} key={2}>
                    Left
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <Typography variant="body1" mb={1} mt={1.5}>
                Which arm are you dominant in?
              </Typography>

              <FormControl fullWidth>
                <InputLabel></InputLabel>
                <Select
                  name="dominantArm"
                  value={dominantArm}
                  variant="outlined"
                  label=""
                  onChange={handleFormDataChange}
                  fullWidth
                >
                  <MenuItem disableRipple value={"right"}>
                    Right
                  </MenuItem>
                  <MenuItem disableRipple value={"left"}>
                    Left
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Typography
                variant="body1"
                mt={2}
                mb={2}
                color={"error"}
                fontSize={16}
              >
                {message}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Stack direction={"row"} justifyContent="space-between">
                <Button
                  variant="contained"
                  disableRipple
                  onClick={() =>
                    navigate("/createaccount", {
                      state: {
                        formData,
                        token,
                      },
                    })
                  }
                  size="md"
                  sx={{ ...whiteButtonStyle }}
                  startIcon={<ArrowBackIcon sx={{ color: "#FFC04D", pr: 1 }} />}
                >
                  Back
                </Button>

                <LoadingButton
                  onClick={submitAccount}
                  size="md"
                  type="submit"
                  disableRipple
                  variant="contained"
                  loading={loading}
                >
                  Register
                </LoadingButton>
              </Stack>
            </Grid>
          </Grid>
        </Stack>
      </Stack>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box sx={authModalStyles}>
          <Stack spacing={2} m={1} alignItems="center">
            <Typography variant="h6">Success!</Typography>
            <Divider />
            <Typography fontSize={16}>{successMessage}</Typography>
          </Stack>
        </Box>
      </Modal>
    </Container>
  );
}
