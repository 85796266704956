import React, { useEffect, useState } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
// @mui
import {
  Container,
  Typography,
  Breadcrumbs,
  Stack,
  Avatar,
  Box,
  Grid,
  LinearProgress,
  Button,
  TablePagination,
  Skeleton,
  Tooltip,
  Snackbar,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
// @mui-icons
import {
  PeopleAltOutlined,
  ArrowForwardIos,
  InfoOutlined,
  CachedOutlined,
} from "@mui/icons-material";
import { FormatListBulletedOutlined } from "@mui/icons-material";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import { linearProgressClasses } from "@mui/material/LinearProgress";
// API
import { fetchUserOrgMetadata } from "../../api/userRoutes";
import {
  fetchPaginatedUserSessions,
  fetchUserDashboardSummary,
} from "../../api/sessionRoutes";
// Components
import FlagUnflagModal from "../../sections/sessions/FlagUnflagModal";
import GenericMenu from "../../components/menu/GenericMenu";
import { TimeFrameSelect, ActivitySelect } from "../../components/selects";
import EditAthleteProfile from "../../sections/athlete/EditAthleteProfile";
import GenericTable from "../../components/table/GenericTable";
import GenericToolBar from "../../components/table/GenericToolBar";
import { SessionCardInfo, StackedBarChartCard } from "../../sections/dashboard";
import { SessionGridDropDown } from "../../sections/sessions/SessionGridDropDown";
// Utils
import { whiteButtonStyle } from "../../utils/styles";
import {
  getWeekDates,
  getLinearProgressStyles,
  getMonthDates,
  getYearDates,
  generateChartLabels,
  fancyTimeFormat,
  getFormattedDateWithTimezone,
  getActivityId,
} from "../../utils/utilityFunctions";
import {
  athleteSessionViewColumns,
  createAthleteData,
} from "../../utils/tableFunctions";
//images
import flagImage from "../../assets/img/Flag.png";
import unflagImage from "../../assets/img/unflag.jpg";
// import { withTimingAndLogging } from '../../utils/logFunctions/logs';
// ----------------------------------------------------------------------

export default function AthletePage({ orgId }) {
  const location = useLocation();
  const navigate = useNavigate();
  const [level, setLevel] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [openModal, setOpenModal] = React.useState(false);
  const [tabValue, setTabValue] = useState(1);
  const [testSessionClicked, setTestSessionClicked] = useState(false);
  const [notes, setNotes] = useState(false);
  const [count, setCount] = useState(0);
  const [active, setActive] = useState(false);
  const [weight, setWeight] = useState("");
  const [weightMetric, setWeightMetric] = useState("");
  const [height, setHeight] = useState("");
  const [heightMetric, setHeightMetric] = useState("");
  const [timeframe, setTimeframe] = useState(getWeekDates());
  const [userSessions, setUserSessions] = useState(null);
  const [totalSessions, setTotalSessions] = useState("");
  const [activityType, setActivityType] = useState("All Activities");
  const [selectValue, setSelectValue] = useState("week");
  const [sessionCards, setSessionCards] = useState([]);
  const [updateUserData, setUpdateUserData] = useState(false);
  const [selected, setSelected] = React.useState([]);
  const [session, setSession] = useState([]);
  const [loading, setLoading] = useState(true);
  const [arm, setArm] = useState("");
  const [leg, setLeg] = useState("");
  const [chartLabel, setChartLabel] = useState([]);
  let userId, athleteName, organizationId, activities, params;
  if (location?.state) {
    userId = location.state.userId;
    athleteName = location.state.name;
    organizationId = location.state.orgId;
    activities = location.state.activities;
    params = location.state.params;
  }
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [flaggedSessionData, setFlaggedSessionData] = useState({
    state: "close",
    sessionId: "",
    user: "",
    flagged: false,
    orgId,
  });
  const [state, setState] = useState({
    openSnack: false,
    vertical: "top",
    horizontal: "center",
  });
  const [lastKey, setLastKey] = useState("start");
  const [dashboardSummary, setDashboardSummary] = useState(null);
  const [athleteSessionNumber, setAthleteSessionNumber] = useState(null);
  const [athleteSessionDuration, setAthleteSessionDuration] = useState(null);
  const open = Boolean(anchorEl);
  const openMenu = Boolean(anchorEl2);

  const { vertical, horizontal, openSnack } = state;
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleCloseSnackBar = () => {
    setState({ ...state, openSnack: false });
  };

  const handleOpenSnackBar = () => {
    setState({ ...state, openSnack: true });
  };

  const handleCloseMenu = () => {
    setAnchorEl2(null);
  };

  const handleButtonClick = (event, sessionId, user, activity) => {
    // SelectedUserId = user;
    setTestSessionClicked(activity.includes("A-TEST"));
    setAnchorEl(event.currentTarget);
    setSession(sessionId);
  };

  const handleFlagClick = (event, sessionId, user, flagged) => {
    setAnchorEl2(event.currentTarget);
    setFlaggedSessionData({
      ...flaggedSessionData,
      sessionId,
      user,
      flagged,
      orgId,
    });
  };

  // Fetch User MetaData and Re-Mount on Update
  useEffect(() => {
    const fetchUserMetadata = async (userId, orgId) => {
      const fetchFunction = () => fetchUserOrgMetadata(userId, orgId);
      const params = { user_id: userId, org_id: orgId };
      const funcName = "fetchUserOrgMetadata";

      // const response = await withTimingAndLogging(fetchFunction, 'User org metadata has been fetched.', false, funcName, params);

      const response = await fetchUserOrgMetadata(
        userId,
        orgId,
        "User org metadata has been fetched."
      );
      const data = response.data;
      const metaData = data.userMetaData.user_metadata;

      setNotes(data.userOrgMetaData.admin_settings.notes);
      setLevel(data.userOrgMetaData.admin_settings.level);

      if (data.userMetaData.user_metadata.height) {
        const { unit, value } = data.userMetaData.user_metadata.height;
        const heightMetric = unit || "";
        const heightValue =
          unit === "in"
            ? `${Math.trunc(value / 12)} feet ${value % 12}`
            : value || "";

        setHeightMetric(heightMetric);
        setHeight(heightValue);
      } else {
        setHeight("N/A");
        setHeightMetric("");
      }

      if (data.userMetaData.user_metadata.weight) {
        setWeight(data.userMetaData.user_metadata.weight.value || "");
        setWeightMetric(data.userMetaData.user_metadata.weight.unit || "");
      } else {
        setWeight("N/A");
        setWeightMetric("");
      }
      const { dominant_arm: arm, dominant_leg: leg } = metaData;
      const capitalArm = arm
        ? arm.charAt(0).toUpperCase() + arm.slice(1)
        : "N/A";
      const capitalLeg = leg
        ? leg.charAt(0).toUpperCase() + leg.slice(1)
        : "N/A";

      setArm(capitalArm);
      setLeg(capitalLeg);
    };

    if (userId && organizationId) {
      fetchUserMetadata(userId, organizationId);
    }
  }, [updateUserData]);

  // Paginated Sessions
  useEffect(() => {
    setLoading(true);
    setUserSessions(null);
    setPage(0);
    let lastKey = "start";
    let page = 0;

    const fetchData = async () => {
      const activity_id = getActivityId(activityType, activities);
      const fetchFunction = () =>
        fetchPaginatedUserSessions(
          userId,
          orgId,
          rowsPerPage,
          lastKey,
          activity_id
        );
      const params = {
        user_id: userId,
        org_id: orgId,
        limit: rowsPerPage,
        last_key: lastKey,
        activity_id: activity_id,
        tabValue,
        page: page + 1,
      };
      const funcName = "fetchPaginatedUserSessions";

      // const response = await withTimingAndLogging(fetchFunction, 'Paginated Sessions have been fetched.', false, funcName, params);

      const response = await fetchPaginatedUserSessions(
        userId,
        orgId,
        rowsPerPage,
        lastKey,
        activity_id,
        tabValue,
        page + 1,
        "Paginated Sessions have been fetched.",
        params
      );
      const { data: sessionsData } = response.data;
      const {
        total_sessions: totalSessionsDone,
        items: sessions,
        last_key: newLastKey,
      } = sessionsData;

      const newSessions = sessions
        ? sessions.map((session, index) =>
            createAthleteData(
              session,
              activities,
              index,
              handleButtonClick,
              handleFlagClick
            )
          )
        : [];

      setUserSessions(sessions);
      setTotalSessions(totalSessionsDone);
      setLastKey(newLastKey);
      setSessionCards(newSessions);
      setActive(false);
      setLoading(false);
    };

    if (activities && orgId && userId) {
      fetchData();
    }
  }, [count, activityType, rowsPerPage, tabValue]);

  // Paginated Dashboard Data
  useEffect(() => {
    setAthleteSessionDuration(null);
    setAthleteSessionNumber(null);

    const fetchDashboardSummary = async () => {
      const activity_id = getActivityId(activityType, activities);
      const { stime, etime } = getFormattedDateWithTimezone(selectValue);
      const chartLabels = generateChartLabels(selectValue);
      setChartLabel(chartLabels);

      const fetchFunction = () =>
        fetchUserDashboardSummary(userId, orgId, stime, etime, activity_id);
      const params = {
        user_id: userId,
        org_id: orgId,
        start_time: stime,
        end_time: etime,
        activity_id: activity_id,
      };
      const funcName = "fetchUserDashboardSummary";

      // const paginatedResponse = await withTimingAndLogging(fetchFunction, 'Fetching user dashboard summary.', false, funcName, params);

      const paginatedResponse = await fetchUserDashboardSummary(
        userId,
        orgId,
        stime,
        etime,
        activity_id,
        "Fetching user dashboard summary.",
        params
      );
      const paginatedData = paginatedResponse.data.data;

      const athleteSessionDurationFormatted = fancyTimeFormat(
        paginatedData.total_duration
      );
      const newDashboardSummary = paginatedData.sessions_summary || {};

      setDashboardSummary(newDashboardSummary);
      setAthleteSessionNumber(paginatedData.total_sessions);
      setAthleteSessionDuration(athleteSessionDurationFormatted);
    };

    if (activities && orgId && userId) {
      fetchDashboardSummary();
    }
  }, [selectValue, activityType]);

  useEffect(() => {
    if (!location.state) {
      return navigate("/dashboard/athletes");
    }
  }, []);

  const breadcrumbs = [
    <Link
      to={"/dashboard/athletes"}
      style={{ textDecoration: "none", color: "inherit", cursor: "pointer" }}
      state={{
        params,
      }}
    >
      <Stack direction="row" alignItems="center" spacing={1}>
        <PeopleAltOutlined color="rgba(158, 158, 158, 1)" />
        <Typography color="rgba(158, 158, 158, 1)">Athletes</Typography>
      </Stack>
    </Link>,
    <Typography key="3" color="text.primary">
      {" "}
      {athleteName}{" "}
    </Typography>,
  ];

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - sessionCards.length) : 0;

  const handleClose = () => {
    setSession([]);
    setAnchorEl(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectChange = (e) => {
    const newSelectValue = e.target.value;
    setSelectValue(newSelectValue);

    let newTimeframe = [];
    if (newSelectValue === "month") {
      newTimeframe = getMonthDates();
    } else if (newSelectValue === "year") {
      newTimeframe = getYearDates();
    } else {
      newTimeframe = getWeekDates();
    }

    newTimeframe = [...newTimeframe];
    setTimeframe(newTimeframe);
  };

  const handleOpenModal = () => setOpenModal(true);

  const handleCloseModal = () => setOpenModal(false);

  const handleActivityChange = (event, newValue) => {
    const selectedActivity = event.target.value;
    setActivityType(selectedActivity);
  };

  const handleChangePage = async (event, newPage) => {
    if (sessionCards.length > (page + 1) * rowsPerPage) {
      setPage(newPage);
    } else if (newPage > page && newPage > 0) {
      setLoading(true);
      const activity_id = getActivityId(activityType, activities);
      const fetchFunction = () =>
        fetchPaginatedUserSessions(
          userId,
          orgId,
          rowsPerPage,
          lastKey,
          activity_id,
          tabValue,
          newPage + 1
        );
      const params = {
        user_id: userId,
        org_id: orgId,
        limit: rowsPerPage,
        last_key: lastKey,
        activity_id: activity_id,
        tabValue,
        page: newPage + 1,
      };
      const funcName = "fetchPaginatedUserSessions";

      // const response = await withTimingAndLogging(fetchFunction, 'Paginated Sessions have been fetched.', false, funcName, params);

      const response = await fetchPaginatedUserSessions(
        userId,
        orgId,
        rowsPerPage,
        lastKey,
        activity_id,
        tabValue,
        page + 1,
        "Paginated Sessions have been fetched.",
        params
      );
      const { data: sessionsData } = response.data;
      const {
        total_sessions: totalSessionsDone,
        items: sessions,
        last_key: newLastKey,
      } = sessionsData;

      const newSessions = sessions.map((session, index) =>
        createAthleteData(
          session,
          activities,
          index,
          handleButtonClick,
          handleFlagClick
        )
      );
      setUserSessions((prevSessions) => [...prevSessions, ...sessions]);
      setSessionCards((prevSessions) => [...prevSessions, ...newSessions]);
      setPage(newPage);
      setLastKey(newLastKey);
      setLoading(false);
    } else {
      setPage(newPage);
    }

    if (lastKey === null) {
      return;
    }

    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const updateSessions = () => {
    setCount((prevState) => prevState + 1);
    setActive(true);
    setLastKey("start");
  };

  return (
    <Container maxWidth="xl" ml={0}>
      <Box ml={4} mb={3} mr={1}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={1}
        >
          <Breadcrumbs
            separator={<ArrowForwardIos sx={{ height: 12, width: 12 }} />}
            aria-label="breadcrumb"
            mb={2}
          >
            {breadcrumbs}
          </Breadcrumbs>

          <Button
            width="12rem"
            disableRipple
            onClick={handleOpenModal}
            sx={{ ...whiteButtonStyle }}
          >
            Edit Profile
          </Button>
        </Stack>

        <Stack direction="row" alignItems="center" spacing={2}>
          <Avatar sx={{ width: 140, height: 140 }} alt={`${athleteName}`} />

          <Stack gap={2}>
            <Stack direction="row" alignItems="center" flexWrap="wrap">
              <Typography variant="h4">
                {athleteName ? athleteName : <Skeleton variant="text" />}
              </Typography>

              {notes && (
                <Tooltip title={`${notes}`} placement="right">
                  <InfoOutlined sx={{ color: "#666666", pl: 1 }} />
                </Tooltip>
              )}
            </Stack>

            <Stack direction="row" alignItems="center">
              <Stack direction="row" sx={{ width: "50%" }} spacing={1}>
                <Typography variant="body1" color="rgba(158, 158, 158, 1)">
                  Height
                </Typography>
                <Typography variant="body1">
                  {height} {heightMetric}
                </Typography>
              </Stack>

              <Stack direction="row" sx={{ width: "50%" }} spacing={1}>
                <Typography
                  variant="body1"
                  color="rgba(158, 158, 158, 1)"
                  sx={{ whiteSpace: "nowrap" }}
                >
                  Dominant Leg
                </Typography>
                <Typography variant="body1"> {leg}</Typography>
              </Stack>
            </Stack>

            <Stack direction="row" alignItems="center">
              <Stack direction="row" sx={{ width: "50%" }} spacing={1}>
                <Typography variant="body1" color="rgba(158, 158, 158, 1)">
                  Weight
                </Typography>
                <Typography variant="body1">
                  {weight} {weightMetric}
                </Typography>
              </Stack>

              <Stack direction="row" sx={{ width: "50%" }} spacing={1}>
                <Typography
                  variant="body1"
                  color="rgba(158, 158, 158, 1)"
                  sx={{ whiteSpace: "nowrap" }}
                >
                  Dominant Arm{" "}
                </Typography>
                <Typography variant="body1">{arm}</Typography>
              </Stack>
            </Stack>

            {/* Refactor */}
            <EditAthleteProfile
              openModal={openModal}
              setUpdateUserData={setUpdateUserData}
              handleCloseModal={handleCloseModal}
              userId={userId}
              orgId={orgId}
            />

            <Stack>
              <LinearProgress
                variant="determinate"
                mt={1}
                value={level}
                sx={getLinearProgressStyles(level, linearProgressClasses)}
              />
            </Stack>
          </Stack>
        </Stack>
      </Box>

      <Stack pb={2} direction="row">
        <ActivitySelect
          activityType={activityType}
          handleActivityChange={handleActivityChange}
          activities={activities}
        />

        <TimeFrameSelect
          selectValue={selectValue}
          handleSelectChange={handleSelectChange}
        />
      </Stack>

      <Grid container spacing={2} columns={18}>
        <Grid item xs={18} sm={18} md={18}>
          <StackedBarChartCard
            activities={activities}
            chartLabels={chartLabel}
            timeframe={timeframe}
            sessionSummary={dashboardSummary}
            activityType={activityType}
          />
        </Grid>

        <Grid item xs={18} sm={9} md={9}>
          <SessionCardInfo
            title="Total Sessions Completed"
            total={athleteSessionNumber}
            icon={
              <FormatListBulletedOutlined sx={{ color: "success.light" }} />
            }
            activityType={activityType}
            suffix={"sessions"}
          />
        </Grid>

        <Grid item xs={18} sm={9} md={9}>
          <SessionCardInfo
            title="Total Time Spent"
            total={athleteSessionDuration}
            icon={<AccessTimeOutlinedIcon sx={{ color: "success.light" }} />}
            suffix={"hh-mm-ss"}
            activityType={activityType}
          />
        </Grid>
      </Grid>

      <Typography variant="h5" mt={9} mb={3} ml={2}>
        Sessions Historical
        <Button
          variant="contained"
          startIcon={
            <CachedOutlined
              sx={{
                animation: active ? "spin 1s linear infinite" : "none",
              }}
            />
          }
          sx={{ minWidth: 100, ml: 1, mb: 0.5 }}
          disableRipple
          onClick={updateSessions}
        >
          Refresh
        </Button>
      </Typography>

      <GenericToolBar
        numSelected={selected.length}
        setLastKey={setLastKey}
        selected={selected}
        userId={userId}
        setTotalSessions={setTotalSessions}
        session={session}
        setCount={setCount}
        activities={activities}
        handleButtonClick={handleButtonClick}
        sessions={userSessions}
        orgId={orgId}
        sessionCards={sessionCards}
        active={active}
        setActive={setActive}
        setSessionCards={setSessionCards}
        tabValue={tabValue}
        setTabValue={(val) => setTabValue(val)}
        allValidSession={selected.every((item) => item.isValidSession)}
        allTestSession={selected.every((item) => item.containsTestSession)}
        someTestSession={selected.some((item) => item.containsTestSession)}
        page={page}
        setPage={setPage}
        loading={loading}
      />

      <GenericTable
        data={sessionCards}
        loading={loading}
        order={order}
        orderBy={orderBy}
        columns={
          tabValue === 1
            ? athleteSessionViewColumns
            : athleteSessionViewColumns.filter(
                (item) => item.label !== "Total Reps"
              )
        }
        emptyRows={emptyRows}
        rowsPerPage={rowsPerPage}
        page={page}
        onRequestSort={handleRequestSort}
        rowCount={totalSessions}
        numSelected={selected.length}
        hasCheckbox={true}
        selected={selected}
        setSelected={setSelected}
        setSession={setSession}
        session={session}
      />

      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={totalSessions}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <SessionGridDropDown
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        userId={userId}
        session={session}
        orgId={orgId}
        testSessionClicked={testSessionClicked}
      />

      <FlagUnflagModal
        openModal={flaggedSessionData.state === "open"}
        handleCloseModal={() => {
          setFlaggedSessionData({ ...flaggedSessionData, state: "close" });
          setAnchorEl2(null);
        }}
        data={flaggedSessionData}
        setCount={setCount}
        handleOpenSnackBar={handleOpenSnackBar}
      />

      <GenericMenu
        anchorEl={anchorEl2}
        open={openMenu}
        options={[
          <Stack direction="row">
            <Typography pr={1}>
              <Box
                component="img"
                src={flaggedSessionData.flagged ? unflagImage : flagImage}
                sx={{ height: 18, width: 18, cursor: "pointer" }}
              />
            </Typography>
            <Typography variant="body1">
              {flaggedSessionData.flagged ? "Mark Unflagged" : "Mark Flagged"}
            </Typography>
          </Stack>,
        ]}
        onClose={handleCloseMenu}
        onClick={() =>
          setFlaggedSessionData({ ...flaggedSessionData, state: "open" })
        }
      />

      <Snackbar
        open={openSnack}
        anchorOrigin={{ vertical, horizontal }}
        autoHideDuration={4000}
        key={vertical + horizontal}
        onClose={handleCloseSnackBar}
      >
        <Alert
          onClose={handleCloseSnackBar}
          severity="success"
          variant="filled"
        >
          Flag is updated successfully.
        </Alert>
      </Snackbar>
    </Container>
  );
}
