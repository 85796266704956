import React from "react";
import { useTheme } from "@emotion/react";
// @mui
import { MenuItem, Select, FormControl } from "@mui/material";
// ----------------------------------------------------------------------

export default function ActivitySelect({ activityType, handleActivityChange, activities }) {
  const theme = useTheme()
    return (
      <FormControl 
        variant="standard" 
        color="primary"
        sx={{ 
            m: 1,
            textTransform: 'uppercase',
            minWidth: "fit-content",
            fontWeight: 700,
            fontSize: 14,
            borderBottom: `3px solid ${theme.palette.primary.main}`
        }}
      >
        <Select
            value={activityType}
            onChange={handleActivityChange}
            displayEmpty
            autoWidth
            disableUnderline
        >
        <MenuItem value={"All Activities"}>All Activities</MenuItem>
            {activities &&
                activities.map((activity, index) => (
                <MenuItem key={index} value={activity.display_name} id={index}>
                    {activity.display_name}
                </MenuItem>
            ))}
        </Select>
      </FormControl>
    );
}