import React from "react";
import { Navigate } from "react-router-dom";

import ErrorBoundary from "../components/ErrorBoundary";

const ProtectedRoute = (props) => {
  const userToken = localStorage.getItem("myState");

  if (!userToken) {
    return <Navigate to="/login" />;
  }

  return (
    <React.Fragment>
      <ErrorBoundary>{props.children}</ErrorBoundary>
    </React.Fragment>
  );
};

export default ProtectedRoute;
