
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Typography, Container, Button } from '@mui/material';
// ----------------------------------------------------------------------

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Page404() {
  return (
    <>
      <Container>
        <StyledContent sx={{ textAlign: 'center', alignItems: 'center' }}>
          <Typography variant="h3" paragraph>
            Sorry, page not found!
          </Typography>

          <Typography sx={{ color: 'text.secondary' }} m={1} mb={2}>
            We can't find the page you are looking for or there has been an error.
          </Typography>

          <Button 
            to="/" 
            variant='contained'
            disableRipple
            component={RouterLink}
          >
            Go back to Login
          </Button>

        </StyledContent>
      </Container>
    </>
  );
}
