import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom';
import validator from 'validator';
// @mui
import { 
  Typography, Container, Stack, Breadcrumbs, Button, Grid,
  TextField, Collapse, Select, FormControl, InputLabel, MenuItem
} from '@mui/material';
// APIS
import { accountCheck } from '../../api/authRoutes';
// Utils
import { years, days, months } from '../../utils/authData';
import { registerAccountBreadcrumbs } from '../../utils/styles';
// ----------------------------------------------------------------------

export default function InviteUser() {
  const navigate = useNavigate()

  const location = useLocation()

  const [message, setMessage] = useState("");

  const [disabled, setDisabled] = useState(true)

  const [color, setColor] = useState("")

  const [days, setDays] = useState([])

  const [formData, setFormData] = useState({
    email: "",
    firstName: "",
    lastName: "",
    month: "",
    day: "",
    year: "",
    gender: "",
    height: "",
    country: "",
    heightMetric: "",
    weight: "",
    weightMetric: "",
    dominantLeg: "",
    dominantArm: "",
  })

  const [inviteNewAthlete, setInviteNewAthlete] = useState(false);

  const {
    firstName, lastName, email,
    year, day, month,
  } = formData;

  useEffect(() => {
    const delayDebounceFn = setTimeout(async () => {
      const isValidEmail = validator.isEmail(email.toLowerCase());
      
      if (isValidEmail) {
        const response = await accountCheck("O-NextilesInc-0101", email.toLowerCase());
        const { data, statusCode } = response
  
        if (statusCode === 200) {
          const existingUser = data.data.existing_user;
    
          if (!existingUser) {
            setInviteNewAthlete(true);
            setMessage("");
            setColor("");
          } else {
            setInviteNewAthlete(false);
            setMessage("You already have an account! Feel free to login or reset your password.");
            setColor("error");
          } 
        }
      } else {
        setInviteNewAthlete(false)
      }
    }, 600)
  
    return () => clearTimeout(delayDebounceFn)
  }, [email])

  useEffect(()=> {
    if (location.state) {
      setFormData(location.state.formData)
      const requiredKeys = ["email", "firstName", "lastName", "day", "year", "month"];
      const isValid = requiredKeys.every(key => location.state.formData[key] !== "");
      isValid ? setDisabled(false) : setDisabled(true)
      setInviteNewAthlete(true)
    }
  },[])

  useEffect(() => {
    const daysInMonth = new Date(year, month, 0).getDate();
    const newDays = Array.from({ length: daysInMonth }, (_, index) => {
        const dayNumber = index + 1;
        return dayNumber < 10 ? `0${dayNumber}` : dayNumber.toString();
    });
    setDays(newDays);

    if(location.state) {
      if (!newDays.includes(location.state.formData.day)) {
        setFormData(prevState => ({
            ...prevState,
            day: "",
        }));
        const requiredKeys = ["email", "firstName", "lastName", "day", "year", "month"];
        const isValid = requiredKeys.every(key => location.state.formData[key] !== "");
        isValid ? setDisabled(false) : setDisabled(true)
      } 
    } else if (!newDays.includes(day)) {
      setFormData(prevState => ({
          ...prevState,
          day: "",
      }));
    } 
}, [month, year, day]);

  const handleFormDataChange = (e) => {
    const { name, value } = e.target

    const updatedObj = { ...formData, [name]: value }
    setFormData({ ...formData, [name]: value });

    const requiredKeys = ["email", "firstName", "lastName", "day", "year", "month"];
    const isValid = requiredKeys.every(key => updatedObj[key] !== "");
    isValid ? setDisabled(false) : setDisabled(true)
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    navigate('/completeaccount', {
        state: {
          formData
        }
    })
  };
  
  return (
    <Container maxWidth="xl" ml={0}>
      <Stack height={"100vh"}>
        <Breadcrumbs separator="›" mt={3} ml={4}>
          {registerAccountBreadcrumbs}
        </Breadcrumbs>
        <Stack 
            mt={3} 
            sx={{ alignItems: "center"}} 
        >
          <Typography variant="h4" mb={1} mt={2}>Create User Account</Typography>

          <Grid 
            container 
            component="form"
            justifyContent="center" 
            sx={{ width: "85%"}} 
            rowSpacing={2}
            columnSpacing={2}
          >
            <Grid item xs={12}>
              <TextField
                fullWidth
                color={color}
                type='text'
                name='email'
                required
                value={email}
                variant="outlined"
                label="Email"
                autoComplete="email"
                onChange={handleFormDataChange}
              />
            </Grid>

            <Grid item xs={12}>
              <Collapse in={inviteNewAthlete}>
                <Stack direction={"row"} spacing={1}>
                  <Grid item xs={6}>
                    <TextField
                      type='text'
                      fullWidth
                      required
                      name="firstName"
                      value={firstName}
                      variant="outlined"
                      label="First Name"
                      onChange={handleFormDataChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      type='text'
                      fullWidth
                      required
                      value={lastName}
                      name="lastName"
                      variant="outlined"
                      label="Last Name"
                      onChange={handleFormDataChange}
                    /> 
                  </Grid>
                </Stack>

                <Stack direction={"row"} spacing={1} mt={2}>
                  <Grid item xs={4}>
                    <FormControl fullWidth required>
                      <InputLabel id="month"> MM </InputLabel>
                      <Select
                        labelId="month"
                        value={month}
                        label="MM"
                        name="month"
                        variant="outlined" 
                        onChange={handleFormDataChange}
                      >
                        {months.map((month, index) => (
                          <MenuItem key={index} value={month}>
                              {month}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={4}>
                    <FormControl fullWidth required>
                      <InputLabel id="day">DD</InputLabel>
                        <Select                
                          value={day}
                          variant="outlined" 
                          labelId="day"
                          label="DD"
                          name="day"
                          onChange={handleFormDataChange}
                          >
                          {days.map((day, index) => (
                              <MenuItem key={index} value={day}>
                                  {day}
                              </MenuItem>
                          ))}
                        </Select>                
                    </FormControl>
                  </Grid>

                  <Grid item xs={4}>
                    <FormControl fullWidth required>
                      <InputLabel id="year">YYYY</InputLabel>
                      <Select              
                        value={year}
                        labelId="year"
                        label="YYYY"
                        name="year"
                        variant="outlined" 
                        onChange={handleFormDataChange}
                        >
                        {years.map((year, index) => (
                            <MenuItem key={index} value={year} justifyContent="center">
                                {year}
                            </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Stack>
              </Collapse>
            </Grid>

            <Grid item xs={12}>
                <Typography color={"error"}>{message}</Typography>
            </Grid>

            <Grid item xs={12}>
                <Button 
                    fullWidth
                    disabled={disabled}  
                    onClick={handleSubmit}
                    variant='contained'
                    disableRipple
                >
                    Next
                </Button>
            </Grid>
          </Grid>

          <Typography mt={1}> 
            Have an account already? 
            &nbsp;
            <Link 
              to={'/login'} 
              style={{
                color: "black", 
                fontWeight: 600, 
                cursor: "pointer"
              }}
            >
              Login
            </Link>
          </Typography>
        </Stack>
      </Stack>
    </Container>
  );
}