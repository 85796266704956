// Specific Session Graph Options
// ***********************************
export const calculateSpecificSessionData = (analyzedSession, switchChecked, metric_id) => {
  return switchChecked
    ? analyzedSession.map((session) => ({
        x: ((new Date(session.timestamp) - new Date(analyzedSession[0].timestamp)) / 1000)/60,
        y: session[metric_id],
      })).sort((a, b) => a.x - b.x)
    : analyzedSession.map((analyzedEvent, index) => ({
        x: index + 1,
        y: analyzedEvent[metric_id] === null ? 0 : analyzedEvent[metric_id],
      }));
};

export const returnData = (yaxis, name) => {
  return [
    {
        name: name,
        data: yaxis
    }
  ];
};

export function getColorForYValue(y) {
  const colors = ["#316CD2", "#448EF7", "#7EBEFA", "#C3E6FD", "#F4DCB0", "#F0D1B5", "#F7CDC7", "#F2A6A0", "#ED817A", "#EC5B56"];
  
  if (y < 1) {
      return colors[0]; // Blue
  } else if (y < 2) {
      return colors[1];
  } else if (y < 3) {
      return colors[2]; 
  }  else if (y < 4) {
      return colors[3];
  } else if (y < 5) {
      return colors[4]; 
  } else if (y < 6) {
      return colors[5];
  } else if (y < 7) {
      return colors[6]; 
  } else if (y < 8) {
      return colors[7]; 
  } else if (y < 9) {
      return colors[8]; 
  } else {
      return colors[9]; // Red
  }
}

export const getChartColor = (ctx, type) => {
  const color = getColorForYValue(ctx.p1.parsed.y);
  if (color) {
    return type === "background" ? `${color}2A` : color;
  }
};

export const generateLegendLabels = () => {
  const labels = [];
  for (let i = 0; i <= 10; i++) {
    labels.push({
      text: '',
      fillStyle: getColorForYValue(i),
      hidden: false,
      index: i,
      strokeStyle: getColorForYValue(i),
      borderRadius: 2,
    });
  }
  return labels;
};

export const lollipop = {
  afterDatasetsDraw(chart, args, options) {
    const { ctx } = chart
    ctx.save()

    for(let i = 0; i < chart.getDatasetMeta(0).data.length; i++){
      const x = chart.getDatasetMeta(0).data[i].x
      const y = chart.getDatasetMeta(0).data[i].y
      const color = getColorForYValue(chart.getDatasetMeta(0).data[i]['$context'].parsed.y)
      const stringXValue = String(chart.getDatasetMeta(0).data[i]['$context'].parsed.x)
      if(stringXValue.includes(".") || stringXValue === "0"){
        circle(x, y, color)
      }
    }

    function circle (xPosition, yPosition, dataColor) {
      const angle = Math.PI / 180
      ctx.beginPath();
      ctx.fillStyle = dataColor
      ctx.arc(xPosition, yPosition, 4, angle * 0, angle * 360, false)
      ctx.fill()
      ctx.closePath()
      ctx.restore()
    }
  }
}

export const barChartOptions = (color, xaxis, tickCount, title,  xAxisTooltipTitle, max, units)=> {
  return {
    chart: {
      toolbar: {
        show: false
      }
    },
    tooltip: {
      style: {
        fontSize: "10px",
        fontFamily: undefined
      },
      marker: {
        show: false,
      },
      x: {
        formatter: function(value) {
          if(xAxisTooltipTitle.includes("ep")){

            return `${xAxisTooltipTitle} ${value.toFixed()}`
          } else {
            return `${xAxisTooltipTitle} ${value.toFixed(1)}`
          }
        },
      },
      y: {
        formatter: function(value) {
          if(units) {
            return value.toFixed(1).concat(` ${units}`)
          } else {
            return value.toFixed(1)
          }
        },
        title: {
            formatter: (seriesName) => seriesName,
          },
      },
      onDatasetHover: {
        style: {
          fontSize: "10px",
          fontFamily: undefined
        }
      },
      theme: "dark"
    },
    xaxis: {
      categories: xaxis,
      stepSize: 10,
      show: true,
      title: {
        text: title,
        style: {
          color: "#A3AED0",
          fontSize: "12px",
          fontWeight: "bold",
        },
      },
      labels: {
        show: true,
        style: {
          colors: "rgba(148, 163, 184, 1)",
          fontSize: "13px",
        }
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
    },
    yaxis: {
      show: true,
      color: "black",
      max: max ? max : undefined, 
      tickAmount: tickCount,
      labels: {
        show: true,
        formatter: function(value) {
          return value.toFixed(1);
        },
        style: {
          colors: "rgba(148, 163, 184, 1)",
          fontSize: "13px"
        }
      }
    },
    grid: {
      show: true,
      // strokeDashArray: 5,
      yaxis: {
        lines: {
          show: true
        }
      },
      xaxis: {
        lines: {
          show: false
        }
      }
    },
    fill: {
      type: "gradient",
      gradient: {
        type: "vertical",
        shadeIntensity: .3,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        colorStops: [
          [
            {
              offset: 0,
              color:  `${color}`,
              opacity: .6
            },
            {
              offset: 100,
              color:  `${color}`,
              opacity: .6
            }
          ]
        ]
      }
    },
    dataLabels: {
      enabled: false
    },
    color: [color],
    plotOptions: {
      bar: {
        columnWidth: "13%",
        // borderRadius: 3,
        barHeight: '70%',
      }
    }
  }
};

export const lineGraphOptions = (color, xaxis, tickCount, showToolbar, title, xAxisTooltipTitle, max, unit) => {

  return {
    chart: {
      animations: {
        enabled: true,
        easing: 'linear',
        speed: 1000,
        animateGradually: {
            enabled: true,
            delay: 300
        },
        dynamicAnimation: {
            enabled: true,
            speed: 350
        }
      },
        toolbar: {
          show: showToolbar,
          offsetX: 0,
          offsetY: 0,
          tools: {
            download: false,
            selection: false,
            zoom: false,
            zoomin: true,
            zoomout: true,
            pan: true,
            reset: true | '<img src="/static/icons/reset.png" width="20">',
            customIcons: []
        },
        dropShadow: {
          enabled: true,
          top: 13,
          left: 0,
          blur: 10,
          opacity: 0.1,
          color: color
        }
      },
    },
      colors: [color],
      markers: {
        size: xaxis.length > 100 ? 0 : 1.7,
        colors: "white",
        strokeColors: color,
        strokeWidth: 3,
        strokeOpacity: 1,
        strokeDashArray: 0,
        fillOpacity: .5,
        discrete: [],
        shape: "circle",
        radius: 2,
        offsetX: 0,
        offsetY: 0,
        showNullDataPoints: true
      },
      tooltip: {
        theme: "dark",
        x: {
          formatter: function(value) {
            if(xAxisTooltipTitle.includes("ep")) {
              return `${xAxisTooltipTitle} ${value.toFixed()}`
            } else {
              return `${xAxisTooltipTitle} ${value.toFixed(1)}`
            }
          },
        },
        y: {
          formatter: function(value) {
            if(unit) {
              return value.toFixed(1).concat(` ${unit}`)
            } else {
              return value.toFixed(1)
            }
          },
          title: {
              formatter: (seriesName) => seriesName,
            },
        },
        marker: {
          show: false,
        },
        style: {
          fontSize: "10px",
          fontFamily: undefined
        },
      },
      dataLabels: {
        enabled: false
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.2,
          opacityTo: 0.9,
        },
      },
      stroke: {
        curve: "smooth",
        type: "line",
        width: 3,
      },
      xaxis: {
        // type: "numeric",
        tickAmount: tickCount+3,
        show: true,
        title: {
          text: title,
          style: {
            color: "#A3AED0",
            fontSize: "12px",
            fontWeight: "bold",
          },
        },
        labels: {
          formatter: function(value) {
            if(xAxisTooltipTitle.includes("ep")){
              return  Number(value).toFixed()
            } else {
              return Number(value).toFixed(2)
            }
          },

          style: {
            colors: "#A3AED0",
            fontSize: "10px",
            fontWeight: "500"
          }
        },
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: true
        },
      },
      yaxis: {
        show: true,
        color: "black",
        tickAmount: tickCount,
        min: 0,
        max: max ? max : undefined, 
        labels: {
          show: true,
          formatter: function(value) {
            if(value === 0) {
              return 0
            } else {
              return value.toFixed(1)
            }
          },
          style: {
            colors: "rgba(148, 163, 184, 1)",
            fontSize: "13px"
          }
        }
      },
      legend: {
        show: false,
        showForSingleSeries: true,
        showForNullSeries: true,
        showForZeroSeries: true,
        position: 'top',
        horizontalAlign: 'end', 
        floating: false,
        fontSize: '12px',
        fontWeight: 400,
        formatter: undefined,
        inverseOrder: false,
        width: undefined,
        height: undefined,
        tooltipHoverFormatter: undefined,
        customLegendItems: [],
        offsetX: 0,
        offsetY: 0,
        labels: {
            colors: undefined,
            useSeriesColors: false
        },
        markers: {
            width: 10,
            height: 10,
            strokeWidth: 0,
            fillColors: undefined,
            radius: 10,
            customHTML: undefined,
            onClick: undefined,
            offsetX: 0,
            offsetY: 0
        },
        itemMargin: {
            horizontal: 5,
            vertical: 0
        },
        onItemClick: {
            toggleDataSeries: true
        },
        onItemHover: {
            highlightDataSeries: true
        },
    },
    
      grid: {
        show: true,
      },
      color: [color]
    }
};

// Dashboard Data 
// ***********************************
export const barPattern = {
  id: "barPattern",
  beforeDatasetsDraw(chart, args, pluginOptions) {
    const {
      ctx,
      chartArea: { top, bottom, height },
      scales: { x, y },
    } = chart;
    ctx.save();
    const width = chart.getDatasetMeta(0).data[0].width;
    const gradient = ctx.createLinearGradient(
      0,
      top,
      0,
      top + height / 2
    );
    gradient.addColorStop(0, "rgba(229, 229, 229, 0)");
    gradient.addColorStop(1, "rgba(229, 229, 229, 1)");

    chart.getDatasetMeta(0).data.forEach((dataPoint, index) => {
      // Use gradient as fill style
      ctx.fillStyle = gradient;
      ctx.fillRect(
        x.getPixelForValue(index) - width / 2,
        top,
        width,
        height - 0.5
      );
    });
  },
};

export const adjustLegend = {
  beforeInit: function (chart) {
    // Get reference to the original fit function
    const originalFit = chart.legend.fit;

    // Override the fit function
    chart.legend.fit = function fit() {
      // Bind scope in order to use `this` correctly inside it
      originalFit.bind(chart.legend)();
      this.height += 20; // Change the height
    };
  },
};

export const stackedBarChartOptions = {
  plugins: {
    tooltip: {
      callbacks: {
        title: function (tooltipItems, data) {
          if(tooltipItems.length > 0){
            return tooltipItems[0].label;
          } 
          return '';
        },
        label: function (tooltipItem, data) {
          // Check if dataset and its label property are defined
          if (tooltipItem.dataset && tooltipItem.dataset.label) {
            return tooltipItem.dataset.label + ': ' + tooltipItem.formattedValue;
          }
          // Return an empty string if label is undefined or null
          return '';
        },
      },
      filter: function (tooltipItem, data) {
        // Disable tooltip if the dataset's data is 0 or undefined
        return tooltipItem.dataset.data[tooltipItem.dataIndex] !== 0;
      },
      titleFont: {
        size: 10,
      },
      bodyFont: {
        size: 10,
      },
      mode: "index",
      intersect: false,
      backgroundColor: "rgba(0, 0, 0, 0.8)",
    },
    legend: {
      position: "top",
      align: "start",
      labels: {
        padding: 10,
        color: "rgba(24, 24, 24, 0.4)",
        boxWidth: 18,
        boxHeight: 3.5,
      },
    },
  },     
  options: {
    indexAxis: "x",
    plugins: {
      zoom: {
        zoom: {
          wheel: {
            enabled: true,
          },
          pinch: {
            enabled: true
          },
          mode: 'xy',
        }
      }
    }
  },
  scales: {
    x: {
      stacked: true,
      ticks: {
        display: true,
        padding: 20,
        color: "rgba(24, 24, 24, 0.4)",
        font: {
          size: 12,
          weight: "400",
        },
      },
      grid: {
        display: false,
        drawTicks: false,
      },
    },
    y: {
      stacked: true,
      border: { display: false },
      ticks: {
        precision: 0,
        padding: 20,
        color: "rgba(24, 24, 24, 0.4)",
        font: {
          size: 12,
          weight: "400",
        },
      },
      grid: {
        display: true,
        drawTicks: false,
      },
    },
  },
  elements: {
    bar: {
      borderWidth: 0,
      borderRadius: 0,
    },
  },
  responsive: true,
  maintainAspectRatio: false,
};

export const lineChartOptions = {
  responsive: true, 
  maintainAspectRatio: false,
  plugins: {
    tooltip: {
      callbacks: {
        title: function (tooltipItems, data) {
          // Customize your tooltip title as needed
          if(tooltipItems.length > 0){
            return tooltipItems[0].label;
          } 
          return '';
        },
        label: function (tooltipItem, data) {
          // Check if dataset and its label property are defined
          if (tooltipItem.dataset && tooltipItem.dataset.label) {
            return tooltipItem.dataset.label + ': ' + tooltipItem.formattedValue;
          }
          // Return an empty string if label is undefined or null
          return '';
        },
      },
      filter: function (tooltipItem, data) {
        // Disable tooltip if the dataset's data is 0 or undefined
        return tooltipItem.dataset.data[tooltipItem.dataIndex] !== 0;
      },
      titleFont: {
        size: 10,
      },
      bodyFont: {
        size: 10,
      },
      mode: "index",
      intersect: false,
      backgroundColor: "rgba(0, 0, 0, 0.8)",
    },
    legend: {
      position: "top",
      align: "start",
      labels: {
        padding: 10,
        boxWidth: 16,
        boxHeight: 1,
      },
    },
  },
  scales: {
    x: {
      type: 'category',
      position: 'bottom',
      ticks: {
        display: true,
        padding: 20,
        color: "rgba(24, 24, 24, 0.4)",
        font: {
          size: 12,
          weight: "400",
        },
      },
      grid: {
        display: false,
        drawTicks: false,
      },
    },
    y: {
      beginAtZero: true,
      ticks: {
        precision: 0,
        padding: 20,
        color: "rgba(24, 24, 24, 0.4)",
        font: {
          size: 12,
          weight: "400",
        },
      }, 
      border: { display: false },
    },
  },
};

export function getRepData (sessionSummary, activities, timeframe, chartLabels) {
  let repData
  if (sessionSummary && activities) {

    repData = {
      labels: chartLabels,
      datasets: activities.map((activity) => ({
        label: activity.display_name,
        data: timeframe.map((date) => {
          const activityType = activity.activity_id;
          if (sessionSummary[date] && sessionSummary[date][activityType]) {
              const activityData = sessionSummary[date][activityType];
              return activityData.total_reps || 0;
          } else {
              return 0;
          }
      }),
        backgroundColor: timeframe.map((date) => {
          if (sessionSummary[date]) {
            const activityType = activity.activity_id;
            const activityData = sessionSummary[date][activityType];
            if (activityData) {
              return activity.display_color;
            }
          }
        }),
        barThickness: 25,
        pointBackgroundColor: activity.display_color,
        maxBarThickness: 25,
      })),
    };
  }
  return repData
}

export function getSessionCountData(sessionSummary, timeframe, chartLabels) {
  let sessionsData;
  if (sessionSummary) {
    sessionsData = {
      labels: chartLabels,
      datasets: [
        {
          label: "Total Sessions",
          data: timeframe.map((date) => {
            const totalSessions = Object.values(sessionSummary[date] || {}).reduce((acc, activity) => acc + activity.total_sessions, 0);
            return totalSessions || 0;
          }),
          backgroundColor: "rgba(255, 192, 77, 1)",
          barThickness: 25,
          maxBarThickness: 25,
        },
      ],
    };
  }
  return sessionsData;
}

export function getMonthlyChartData(sessions, activities, timeframe) {
  let sessionsToDate;

  if (sessions && activities) {

    sessionsToDate = {};
  
    timeframe.forEach((date) => {
      const formattedDate = date;
      let totalSessions = 0;
      let totalReps = 0
      sessionsToDate[formattedDate] = {}; 
  
      if (sessions[formattedDate]) {
        const activitiesOnDate = sessions[formattedDate];
        Object.keys(activitiesOnDate).forEach((activityKey) => {
          const activityData = activitiesOnDate[activityKey];
          totalSessions += activityData.total_sessions;
          totalReps += activityData.total_reps;
  
          sessionsToDate[formattedDate][activityKey] = {
            total_reps: activityData.total_reps,
            total_sessions: activityData.total_sessions
          };
        });
      }
    })
  }

  return sessionsToDate
}

export function getYearlyChartData(sessions, activities, timeframe) {
  let lineData, sessionData, sessionsToDate;

  if (sessions && activities) {
    sessionData = [];
    sessionsToDate = {};

    timeframe.forEach((yearMonth) => {
      let totalSessions = 0;
      let totalReps = 0;

      if (!sessionsToDate[yearMonth]) {
        sessionsToDate[yearMonth] = {};
      }

      const matchingDates = Object.keys(sessions).filter((sessionDate) =>
        sessionDate.startsWith(yearMonth)
      );

      matchingDates.forEach((date) => {
        const activitiesOnDate = sessions[date];
        Object.keys(activitiesOnDate).forEach((activityKey) => {
          const activityData = activitiesOnDate[activityKey];
          totalSessions += activityData.total_sessions || 0;
          totalReps += activityData.total_reps || 0;

          if (!sessionsToDate[yearMonth][activityKey]) {
            sessionsToDate[yearMonth][activityKey] = {
              total_reps: 0,
              total_sessions: 0,
            };
          }

          sessionsToDate[yearMonth][activityKey].total_reps += activityData.total_reps || 0;
          sessionsToDate[yearMonth][activityKey].total_sessions +=
            activityData.total_sessions || 0;
        });
      });

      sessionData.push(totalSessions);
    });

    // lineData = {
    //   labels: chartLabels,
    //   datasets: showReps
    //     ? activities.map((activity) => {
    //         const activityType = activity.activity_id;
    //         const display_name = activity.display_name;
    //         return {
    //           label: `${display_name}`,
    //           data: timeframe.map((date) => {
    //             if (sessionsToDate[date] && sessionsToDate[date][activityType]) {
    //               const activityData = sessionsToDate[date][activityType];
    //               return activityData.total_reps || 0;
    //             } else {
    //               return 0;
    //             }
    //           }),
    //           backgroundColor: (context) => {
    //             const chart = context.chart;
    //             const ctx = chart.ctx;
    //             const gradient = ctx.createLinearGradient(0, 0, 0, chart.height);
    //             gradient.addColorStop(0, `${activity.display_color}33`);
    //             gradient.addColorStop(1, `${activity.display_color}1A`);
    //             return gradient;
    //           },
    //           borderColor: activity.display_color,
    //           borderWidth: 2.5,
    //           fill: true,
    //           tension: 0.4,
    //           pointRadius: 0,
    //         };
    //       })
    //     : [
    //         {
    //           label: "Total Sessions",
    //           data: timeframe.map((date) => {
    //             let totalSessions = 0;
    //             Object.values(sessionsToDate[date] || {}).forEach((activity) => {
    //               totalSessions += activity.total_sessions || 0;
    //             });
    //             return totalSessions;
    //           }),
    //           backgroundColor: "rgba(255, 192, 77, 0.2)",
    //           borderColor: "rgba(255, 192, 77, 1)",
    //           borderWidth: 2.5,
    //           fill: true,
    //           tension: 0.4,
    //           pointRadius: 0,
    //         },
    //       ],
    // };
  }

  return sessionsToDate;
}

export function generateLineData(sessionsToDate, chartLabels, showReps, activities, timeframe) {
  if(activities && sessionsToDate){

    const lineData = {
      labels: chartLabels,
      datasets: showReps
        ? activities.map((activity) => {
            const activityType = activity.activity_id;
            const display_name = activity.display_name;
  
            return {
              label: `${display_name}`,
              data: timeframe.map((date) => sessionsToDate[date][activityType]?.total_reps || 0),
              backgroundColor: (context) => {
                const chart = context.chart;
                const ctx = chart.ctx;
                const gradient = ctx.createLinearGradient(0, 0, 0, chart.height);
                gradient.addColorStop(0, `${activity.display_color}33`);
                gradient.addColorStop(1, `${activity.display_color}1A`);
                return gradient;
              },
              borderColor: activity.display_color,
              borderWidth: 2.5,
              fill: true,
              tension: 0.4,
              pointRadius: 0,
            };
          })
        : [
            {
              label: "Total Sessions",
              data: timeframe.map((date) =>
                Object.values(sessionsToDate[date] || {}).reduce(
                  (totalSessions, activity) => totalSessions + (activity.total_sessions || 0),
                  0
                )
              ),
              backgroundColor: "rgba(255, 192, 77, 0.2)",
              borderColor: "rgba(255, 192, 77, 1)",
              borderWidth: 2.5,
              fill: true,
              tension: 0.4,
              pointRadius: 0,
            },
          ],
    };
  
    return lineData;
  }
}