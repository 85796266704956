import React, { useEffect, useState } from "react";
// @mui
import {
  Container,
  Typography,
  Box,
  TablePagination,
  Stack,
  Button,
  Snackbar,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
// @mui-icons
import { CachedOutlined } from "@mui/icons-material";
// Components
import GenericTable from "../../components/table/GenericTable";
import GenericToolBar from "../../components/table/GenericToolBar";
import { ActivitySelect } from "../../components/selects";
// Sections
import { SessionGridDropDown } from "../../sections/sessions/SessionGridDropDown";
import FlagUnflagModal from "../../sections/sessions/FlagUnflagModal";
// Utils
import { fetchSessionHistoryData } from "../../utils/SessionsFunctions";
import {
  adminSessionViewColumns,
  athleteSessionViewColumns,
} from "../../utils/tableFunctions";
//images
import flagImage from "../../assets/img/Flag.png";
import unflagImage from "../../assets/img/unflag.jpg";
//utils
import GenericMenu from "../../components/menu/GenericMenu";
// ----------------------------------------------------------------------

export default function SessionsPage({ admin, orgId, activities, userId }) {
  const [active, setActive] = useState(false);
  const [sessions, setSessions] = useState([]);
  const [count, setCount] = useState(1);
  const [activityType, setActivityType] = useState("All Activities");
  const [lastKey, setLastKey] = useState("start");
  const [totalSessions, setTotalSessions] = useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [sessionCards, setSessionCards] = useState([]);
  const [session, setSession] = useState([]);
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [tabValue, setTabValue] = useState(1);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [testSessionClicked, setTestSessionClicked] = useState(false);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [flaggedSessionData, setFlaggedSessionData] = useState({
    state: "close",
    sessionId: "",
    user: "",
    flagged: false,
    orgId,
  });
  const [state, setState] = useState({
    openSnack: false,
    vertical: "top",
    horizontal: "center",
  });
  const { vertical, horizontal, openSnack } = state;
  const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });

  const handleCloseSnackBar = () => {
    setState({ ...state, openSnack: false });
  };

  const handleOpenSnackBar = () => {
    setState({ ...state, openSnack: true });
  };

  const open = Boolean(anchorEl);
  const openMenu = Boolean(anchorEl2);

  const handleClose = () => {
    setSession([]);
    setAnchorEl(null);
  };

  const handleButtonClick = (event, sessionId, user, activity) => {
    // SelectedUserId = user;
    setTestSessionClicked(activity.includes("A-TEST"));
    setAnchorEl(event.currentTarget);
    setSession(sessionId);
  };

  const handleFlagClick = (event, sessionId, user, flagged) => {
    setAnchorEl2(event.currentTarget);
    setFlaggedSessionData({
      ...flaggedSessionData,
      sessionId,
      user,
      flagged,
      orgId,
    });
  };

  const resetSessionPageState = () => {
    setSessions([]);
    setLoading(true);
    setSessionCards([]);
    setPage(0);
    setLastKey("start");
  };

  const fetchData = async () => {
    let lastKey = "start";
    let page = 0;
    if (activities && orgId && admin !== null) {
      const data = await fetchSessionHistoryData(
        activities,
        activityType,
        rowsPerPage,
        orgId,
        userId,
        lastKey,
        admin,
        tabValue,
        page + 1,
        handleButtonClick,
        handleFlagClick
      );
      setSessions(data.sessions);
      setTotalSessions(data.totalSessionsDone);
      setLastKey(data.newLastKey);
      setSessionCards(data.newSessions);
      setActive(false);
      setLoading(false);
    }
  };

  useEffect(() => {
    resetSessionPageState();
    fetchData();
  }, [count, orgId, rowsPerPage, activityType, admin, activities, tabValue]);

  const handleActivityChange = async (event, newValue) => {
    resetSessionPageState();
    const selectedActivity = event.target.value;
    setActivityType(selectedActivity);
  };

  const handleCloseMenu = () => {
    setAnchorEl2(null);
  };

  const handleChangePage = async (event, newPage) => {
    if (sessionCards.length > (page + 1) * rowsPerPage) {
      setPage(newPage);
    } else if (newPage > page && newPage > 0) {
      setLoading(true);
      const data = await fetchSessionHistoryData(
        activities,
        activityType,
        rowsPerPage,
        orgId,
        userId,
        lastKey,
        admin,
        tabValue,
        newPage + 1,
        handleButtonClick,
        handleFlagClick
      );

      setLastKey(data.newLastKey);
      setSessionCards((prevSessions) => [...prevSessions, ...data.newSessions]);
      setPage(newPage);
      setLoading(false);
    } else {
      setPage(newPage);
    }

    if (lastKey === null) {
      return;
    }

    setPage(newPage);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    resetSessionPageState();
  };

  const updateSessions = () => {
    setCount((prevState) => prevState + 1);
    setActive(true);
    setLastKey("start");
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - totalSessions) : 0;

  return (
    <Container maxWidth="xl" ml={0}>
      <Stack direction="row" justifyContent="space-between">
        <Typography variant="h4" mb={5} ml={1}>
          Sessions
          <Button
            variant="contained"
            startIcon={
              <CachedOutlined
                sx={{
                  animation: active ? "spin 1s linear infinite" : "none",
                }}
              />
            }
            sx={{ minWidth: 100, ml: 1, mb: 0.5 }}
            disableRipple
            onClick={updateSessions}
          >
            Refresh
          </Button>
        </Typography>

        <Box>
          <ActivitySelect
            activityType={activityType}
            handleActivityChange={handleActivityChange}
            activities={activities}
          />
        </Box>
      </Stack>

      <GenericToolBar
        numSelected={selected.length}
        setLastKey={setLastKey}
        userId={userId}
        setTotalSessions={setTotalSessions}
        selected={selected}
        setCount={setCount}
        activities={activities}
        handleButtonClick={handleButtonClick}
        sessions={sessions}
        orgId={orgId}
        admin={admin}
        sessionCards={sessionCards}
        active={active}
        setActive={setActive}
        setSessionCards={setSessionCards}
        allTestSession={selected.every((item) => item.containsTestSession)}
        someTestSession={selected.some((item) => item.containsTestSession)}
        allValidSession={selected.every((item) => item.isValidSession)}
        tabValue={tabValue}
        setTabValue={(val) => setTabValue(val)}
        page={page}
        setPage={setPage}
        loading={loading}
      />

      <GenericTable
        data={sessionCards}
        loading={loading}
        order={order}
        orderBy={orderBy}
        columns={
          admin
            ? tabValue === 1
              ? adminSessionViewColumns
              : adminSessionViewColumns.filter(
                  (item) => item.label !== "Total Reps"
                )
            : tabValue === 1
            ? athleteSessionViewColumns
            : athleteSessionViewColumns.filter(
                (item) => item.label !== "Total Reps"
              )
        }
        emptyRows={emptyRows}
        rowsPerPage={rowsPerPage}
        page={page}
        onRequestSort={handleRequestSort}
        rowCount={totalSessions}
        numSelected={selected.length}
        hasCheckbox={true}
        selected={selected}
        setSelected={setSelected}
        setSession={setSession}
        session={session}
      />
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={totalSessions}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <SessionGridDropDown
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        userId={userId}
        session={session}
        testSessionClicked={testSessionClicked}
        orgId={orgId}
      />

      <FlagUnflagModal
        openModal={flaggedSessionData.state === "open"}
        handleCloseModal={() => {
          setFlaggedSessionData({ ...flaggedSessionData, state: "close" });
          setAnchorEl2(null);
        }}
        data={flaggedSessionData}
        setCount={setCount}
        handleOpenSnackBar={handleOpenSnackBar}
      />

      <GenericMenu
        anchorEl={anchorEl2}
        open={openMenu}
        options={[
          <Stack direction="row">
            <Typography pr={1}>
              <Box
                component="img"
                src={flaggedSessionData.flagged ? unflagImage : flagImage}
                sx={{ height: 18, width: 18, cursor: "pointer" }}
              />
            </Typography>
            <Typography variant="body1">
              {flaggedSessionData.flagged ? "Mark Unflagged" : "Mark Flagged"}
            </Typography>
          </Stack>,
        ]}
        onClose={handleCloseMenu}
        onClick={() =>
          setFlaggedSessionData({ ...flaggedSessionData, state: "open" })
        }
      />

      <Snackbar
        open={openSnack}
        anchorOrigin={{ vertical, horizontal }}
        autoHideDuration={4000}
        key={vertical + horizontal}
        onClose={handleCloseSnackBar}
      >
        <Alert
          onClose={handleCloseSnackBar}
          severity="success"
          variant="filled"
        >
          Flag is updated successfully.
        </Alert>
      </Snackbar>
    </Container>
  );
}
